import React from "react";
import { Tag, Tooltip, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import {
  AntIconTag,
  VerifiedSuccessWrapper,
  RobotWrapper,
  CheckmarkWrapper,
} from "./styledComponents";
import { NOT_VERIFIED_CODES } from "../../../../utils/categories";
import { ValueMeta } from "../../../../types";
import RobotIcon from "../../../../icons/robot.png";
import { VERIFIED_BY_ML } from "./constants";

type TransactionProps = {
  categoryCode?: string | null;
  categoryCodeMeta?: ValueMeta;
  vatCategoryCode?: string | null;
  vatCategoryCodeMeta?: ValueMeta;
  isSplitCategorized?: boolean;
  isDatevTransaction?: boolean;
  verifiedBy?: string;
};

type Props = {
  updateTransaction: Function;
  value: boolean;
  id: string;
  hasAssets: boolean;
} & TransactionProps;

export const isTransactionComplete = ({
  isSplitCategorized,
  categoryCode,
  categoryCodeMeta,
  vatCategoryCode,
  vatCategoryCodeMeta,
}: TransactionProps) =>
  !!(
    isSplitCategorized ||
    (categoryCode &&
      !NOT_VERIFIED_CODES.includes(categoryCode) &&
      !!categoryCodeMeta?.categorizationType &&
      vatCategoryCode &&
      !!vatCategoryCodeMeta?.categorizationType)
  );

export const isDatevTransactionComplete = ({
  categoryCode,
  vatCategoryCode,
}: TransactionProps) =>
  !!(
    categoryCode &&
    !NOT_VERIFIED_CODES.includes(categoryCode) &&
    vatCategoryCode
  );

export const canToggleValue = ({
  isSplitCategorized,
  categoryCode,
  categoryCodeMeta,
  vatCategoryCode,
  vatCategoryCodeMeta,
  value,
  isDatevTransaction,
}: TransactionProps & { value: boolean }): boolean => {
  if (value) {
    return true;
  }

  if (isDatevTransaction) {
    return isDatevTransactionComplete({
      categoryCode,
      vatCategoryCode,
    });
  }

  return isTransactionComplete({
    isSplitCategorized,
    categoryCode,
    categoryCodeMeta,
    vatCategoryCode,
    vatCategoryCodeMeta,
  });
};

const VerificationField = (props: Props) => {
  const canToggleVerified = canToggleValue(props);
  const handleOnClick = (e: any) => {
    e.stopPropagation();
    canToggleVerified &&
      props.updateTransaction({
        verified: !props.value,
        kontistTransactionId: props.id,
      });
  };

  const showPaymentReminderModal = (e: React.MouseEvent<HTMLDivElement>) => {
    // Don't show reminder if
    // - verify button is disabled
    // - transaction has assets
    if (!canToggleVerified || props.hasAssets) {
      return handleOnClick(e);
    }

    Modal.info({
      content: "Erstelle eine wiederkehrenden Zahlung",
      onOk: () => handleOnClick(e),
    });
  };

  const { verifiedBy } = props;

  const isAutomaticallyVerified =
    props.value &&
    verifiedBy &&
    !verifiedBy.includes("@") &&
    verifiedBy !== "USER";

  const tooltipTitle = verifiedBy === VERIFIED_BY_ML ? "ML" : verifiedBy;

  return (
    <>
      {props.value ? (
        <Tooltip title={tooltipTitle}>
          <VerifiedSuccessWrapper>
            <AntIconTag
              data-test="verifiedButton"
              data-testid="verifiedButton"
              onClick={handleOnClick}
            >
              <CheckmarkWrapper>
                <Tag color="success" icon={<CheckCircleOutlined />}>
                  Verified
                </Tag>
              </CheckmarkWrapper>
            </AntIconTag>
            {isAutomaticallyVerified && (
              <RobotWrapper>
                <img src={RobotIcon} alt="robot-icon" width={14} />
              </RobotWrapper>
            )}
          </VerifiedSuccessWrapper>
        </Tooltip>
      ) : (
        <AntIconTag
          data-test="notVerifiedButton"
          data-testid="notVerifiedButton"
          onClick={showPaymentReminderModal}
          inactive={!canToggleVerified}
        >
          <Tag color="default">Not verified</Tag>
        </AntIconTag>
      )}
    </>
  );
};

export default VerificationField;
