import isEqual from "lodash/isEqual";
import moment, { Moment } from "moment";
import isNumber from "lodash/isNumber";

import { BookkeepingTool } from "../../../../api/graphql";
import {
  BusinessAssetMetaData,
  TransactionSplit,
  DepreciableCategory,
  ITransaction,
  ValueSuggestionSource,
  ValueCategorizationType,
  BusinessAssetType,
  BusinessAssetForm,
} from "../../../../types";
import { BOOKKEEPING_TOOL_LABELS } from "../../Mandanten/ActivationView/UserDetails/DatevImport/constants";
import { TransactionRow } from "./types";
import {
  AssetType,
  BusinessAsset,
} from "../../../../api/graphql/schema.generated";
import {
  isIntangibleAsset,
  isCustomAssetClass,
  isImmovableAsset,
} from "../../../BusinessAsset/utils";
import immovableAssets, {
  LAND_ASSET_CLASS,
} from "../../../BusinessAsset/immovableAssets";
import movableAssets from "../../../BusinessAsset/movableAssets.json";

type FindBusinessAssetPayload = {
  oldSplits?: TransactionSplit[];
  newSplits: Partial<TransactionSplit>[];
  businessAssets?: BusinessAssetMetaData[];
};

const BUSINESS_ASSET_CATEGORIES = Object.values(DepreciableCategory);

/**
 * In case split amount is changed, its associated business asset amount should be updated accordingly.
 * This function is to filter out those `business assets whose amounts need to be updated`.
 */
export const findBusinessAssetsToUpdateAmount = ({
  oldSplits,
  newSplits,
  businessAssets,
}: FindBusinessAssetPayload) => {
  if (!businessAssets?.length) {
    return [];
  }

  const splitsCategorizedAsBusinessAssetsIds = newSplits
    .filter((split) =>
      BUSINESS_ASSET_CATEGORIES.includes(
        split.categoryCode as DepreciableCategory
      )
    )
    .map(({ uuid }) => uuid);

  if (!splitsCategorizedAsBusinessAssetsIds?.length) {
    return [];
  }

  const oldSplitAmounts = oldSplits?.map((split) => split.amount);
  const newSplitAmounts = newSplits.map((split) => split.amount);

  if (!isEqual(oldSplitAmounts, newSplitAmounts)) {
    return businessAssets.filter(({ businessAssetableId }) =>
      splitsCategorizedAsBusinessAssetsIds.includes(businessAssetableId)
    );
  }
};

export const getTransactionRowClassName = (record: TransactionRow) => {
  const {
    categoryCode,
    vatCategoryCode,
    categoryCodeMeta,
    vatCategoryCodeMeta,
    isSplitCategorized,
  } = record;
  const incompleteTransaction =
    !(categoryCode && !!categoryCodeMeta?.categorizationType) ||
    !(vatCategoryCode && !!vatCategoryCodeMeta?.categorizationType);

  return incompleteTransaction && !isSplitCategorized ? "table-warning" : "";
};

export const getDatevSource = (record: TransactionRow): string | null => {
  const source = record.source as unknown as BookkeepingTool;
  return BOOKKEEPING_TOOL_LABELS[source] || null;
};

const ValueCategorizationTypes = Object.values(ValueCategorizationType);

export const prepareTransactionsData = (
  transactions: ITransaction[] | null
): TransactionRow[] | [] => {
  return (transactions || []).map((transaction) => {
    let transactionRow = {
      ...transaction,
      paidBy: transaction.name,
      paymentDate: transaction.valutaDate,
      key: transaction.id,
    };

    // Issue #ML-138
    // The transaction view and verification view are served with the same
    // backend endpoint but we need to __hide__ ML predictions at the
    // verification view while showing them at the transaction view.
    // The below condition unsets the predictions coming from the BE values if they
    // are provisioned by the ML-Engine.
    if (
      transaction.categoryCodeMeta?.suggestionSource ===
      ValueSuggestionSource.KONTAX_ML
    ) {
      if (
        ValueCategorizationTypes.includes(
          transaction.categoryCodeMeta?.categorizationType as any
        )
      ) {
        transactionRow.categoryCodeMeta = {
          ...transactionRow.categoryCodeMeta,
          label: undefined,
          suggestionSource: undefined,
        };
      } else {
        transactionRow.categoryCode = null;
        transactionRow.categoryCodeMeta = undefined;
      }
    }

    if (
      transaction.vatCategoryCodeMeta?.suggestionSource ===
      ValueSuggestionSource.KONTAX_ML
    ) {
      if (
        ValueCategorizationTypes.includes(
          transaction.vatCategoryCodeMeta?.categorizationType as any
        )
      ) {
        transactionRow.vatCategoryCodeMeta = {
          ...transactionRow.vatCategoryCodeMeta,
          label: undefined,
          suggestionSource: undefined,
        };
      } else {
        transactionRow.vatCategoryCode = null;
        transactionRow.vatCategoryCodeMeta = undefined;
      }
    }

    return transactionRow;
  });
};

export const hasVerifiedOnly = (selectedTransactions: ITransaction[]) => {
  return selectedTransactions.length
    ? selectedTransactions.every((t) => t.verified)
    : false;
};

// Convert BusinessAssetType into AssetType
export const getAssetType = (assetType: BusinessAssetType): AssetType => {
  const index = Object.values(BusinessAssetType).indexOf(assetType);
  const key = Object.keys(BusinessAssetType)[index] as any as AssetType;
  return AssetType[key];
};

export const businessAssetValidator = (
  _: any,
  value: Record<string, string>
) => {
  if (!value) {
    return Promise.resolve();
  }
  if (!value.assetType) {
    return Promise.reject(new Error("Please select AfA-Klasse"));
  }
  if (!value.assetClass && !value.assetClassCustom) {
    return Promise.reject(new Error("Please select Anlage-Typ"));
  }
  if (!value.purchaseDate) {
    return Promise.reject(new Error("Please input Anschaffungsdatum"));
  }
  // Land does not depreciate
  if (!value.depreciationYears && value.assetClass !== LAND_ASSET_CLASS) {
    return Promise.reject(new Error("Please input Abschreibungsdauer"));
  }
  return Promise.resolve();
};

export const getBusinessAssetForm = (
  businessAssets?: BusinessAsset[]
): BusinessAssetForm | null => {
  if (!businessAssets?.length) {
    return null;
  }

  // we only need the first business asset
  const firstItem = businessAssets[0];
  const assetType = firstItem.assetType as any as BusinessAssetType;
  const isAssetClassCustom =
    isIntangibleAsset(assetType) || isCustomAssetClass(firstItem.assetClass);

  return {
    assetType,
    assetClass: isAssetClassCustom ? "" : firstItem.assetClass,
    assetClassCustom: isAssetClassCustom ? firstItem.assetClass : "",
    depreciationYears: `${firstItem.depreciationPeriodYears}`,
    purchaseDate: firstItem.purchaseDate,
  };
};

export const getBusinessAssetFormForUpdate = (
  businessAssetForm?: BusinessAssetForm
) => {
  if (!businessAssetForm) {
    return undefined;
  }

  const assetClass =
    businessAssetForm.assetClassCustom || businessAssetForm.assetClass || "";

  return {
    assetType: getAssetType(businessAssetForm.assetType!),
    assetClass,
    purchaseDate: businessAssetForm.purchaseDate!,
    depreciationPeriodYears: Number(businessAssetForm.depreciationYears),
  };
};

export const getDepreciationYears = ({
  assetClass,
  categoryCode,
  assetType,
}: {
  assetClass: string;
  categoryCode?: string;
  assetType?: BusinessAssetType;
}) => {
  // if category is OVER_250, asset depreciates immediately, hence 0
  if (categoryCode === DepreciableCategory.OVER_250) {
    return "0";
  }

  if (!assetType) {
    return "";
  }

  const assets = isImmovableAsset(assetType) ? immovableAssets : movableAssets;

  const selectedAssetClass = assets.find(
    (asset) => assetClass === asset.assetClass
  );

  return isNumber(selectedAssetClass?.depreciationYears)
    ? selectedAssetClass!.depreciationYears.toString()
    : "";
};

export const disabledDateAfterToday = (current: Moment): boolean => {
  // Should not select days after today
  return current > moment().endOf("day");
};
