import React, { useCallback, useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import { message, Table, Tooltip, notification } from "antd";
import moment from "moment";

import {
  Asset,
  ITransaction,
  KontaxTransactionMeta,
  ValueCategorizationType,
  UpdateTransactionState,
  IMessageType,
  DepreciableCategory,
} from "../../../../types";
import { ReactComponent as CommentIcon } from "../../../../svgs/comment.svg";
import { TransactionSplitState } from "../../../common/types";
import {
  MessageWrapper,
  CheckboxCell,
  TableWrapper,
  InvoiceIcon,
} from "./styledComponents";
import {
  formatAmountInCents,
  showGraphQlErrorNotification,
  showLoadingMessage,
} from "../../../../utils";
import api from "../../../../api";
import { getAsset } from "../../../common/helpers";
import {
  findBusinessAssetsToUpdateAmount,
  getTransactionRowClassName,
  getDatevSource,
  prepareTransactionsData,
} from "./utils";
import VerificationField from "./VerificationField";
import EmptyWrapper from "../../../common/EmptyWrapper";
import { TransactionsTablePopups } from "./TransactionsTablePopups";
import { TransactionsTableButtons } from "./TransactionsTableButtons";
import { destroyMessage, showMessage } from "../../../../utils";
import { TransactionsTableCategory } from "./TransactionsTableCategory";
import { TransactionsVatCategory } from "./TransactionsTableVatCategory";
import { TransactionsTableExpandedRow } from "./TransactionsTableExpandRow";
import { TransactionRow, UpdateSplitsState } from "./types";
import {
  ExternalTransactionInitialValues,
  ExternalTransactionPopup,
} from "./ExternalTransactionPopup";
import { CategorizeBatchPopup } from "./CategorizeBatchPopup";
import Copyable from "../../../common/Copyable";
import { useDeleteBusinessAssetConfirmation } from "../../../BusinessAsset/useDeleteBusinessAssetConfirmation";
import { getToBeDeletedBusinessAssets } from "../../../BusinessAsset/utils";
import { HoverableCommentIcon } from "../../../common/styledComponents";
import { VERIFIED_BY_ML } from "./constants";
import {
  onClickStopPropagation,
  shouldCellUpdate,
} from "../../../../utils/table";
import TransactionSubmissionStatus, {
  getSubmissionStatus,
  TransactionSubmissionState,
} from "./TransactionSubmissionStatus";

interface TransactionsProps {
  email: string | undefined;
  updateTransactionsMeta: (
    payload: Array<KontaxTransactionMeta>
  ) => Promise<boolean>;
  transactions: Array<ITransaction> | null;
  onSplitSubmit: (transactionId: string, state: TransactionSplitState) => void;
  onSplitDelete: (transactionId: string, state: TransactionSplitState) => void;
  onAssetsUpdate: (transactionId: string, assets: Array<Asset>) => void;
  updateTransactionsState: Function;
  selectedTransactions: Array<ITransaction>;
  hasSelectedVerifiedTransactionsOnly?: boolean;
  verifySelectedTransactions: () => void;
  setSelection: (selectedTransactionIds: string[]) => void;
  isNested?: boolean;
}
interface IntegrationDocState {
  downloading: boolean;
  id: string | undefined;
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  message.success("IBAN copied");
};

const TransactionsTable = ({
  email,
  updateTransactionsMeta,
  transactions,
  onSplitSubmit,
  onSplitDelete,
  onAssetsUpdate,
  updateTransactionsState,
  selectedTransactions,
  hasSelectedVerifiedTransactionsOnly,
  verifySelectedTransactions,
  setSelection,
  isNested,
}: TransactionsProps) => {
  const [updatingSplits, setUpdatingSplits] =
    useState<UpdateSplitsState | null>(null);

  const [showExternalTransactionPopup, setShowExternalTransactionPopup] =
    useState(false);

  const [isCategorizeBatchPopupVisible, setIsCategorizeBatchPopupVisible] =
    useState(false);

  const [downloadingIntegrationDocument, setDownloadingIntegrationDocument] =
    useState<IntegrationDocState>({ downloading: false, id: undefined });

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [activeExternalTransaction, setActiveExternalTransaction] =
    useState<ITransaction>();

  const firstTransactionId = transactions?.length && transactions[0].id;
  useEffect(() => {
    setExpandedRowKeys([]);
  }, [firstTransactionId]);

  const [deleteBusinessAssetConfirmationPopup, confirmDeleteBusinessAsset] =
    useDeleteBusinessAssetConfirmation();

  /**
   * Update transaction meta.
   * If transaction's category has changed, associated business asset will be removed.
   * In that case, a delete confirmation modal will be displayed.
   * Updated data will be saved into the component's state, and will be processed when user clicks the confirm button.
   * Otherwise, data will be cleared from the state.
   */
  const handleUpdateTransaction = useCallback(
    async ({
      businessAssets,
      newCategoryCode,
      kontistTransactionId,
      verified,
    }: UpdateTransactionState) => {
      if (verified) {
        setExpandedRowKeys(
          expandedRowKeys.filter((key) => key !== kontistTransactionId)
        );
      }
      const loadingKey = "updating-transaction";

      try {
        showLoadingMessage(loadingKey);
        const confirmedDeleteBusinessAsset = await confirmDeleteBusinessAsset(
          getToBeDeletedBusinessAssets({
            id: kontistTransactionId,
            businessAssets,
            categoryCode: newCategoryCode,
          })
        );

        if (!confirmedDeleteBusinessAsset) {
          return;
        }

        if (verified !== undefined) {
          await updateTransactionsMeta([
            {
              kontistTransactionId,
              verified,
            },
          ]);
        } else {
          await updateTransactionsMeta([
            {
              kontistTransactionId,
              categoryCode: newCategoryCode,
              categoryCodeMeta: {
                label: undefined,
                suggestionSource: undefined,
                categorizationType: ValueCategorizationType.TAX_OPS,
              },
            },
          ]);
        }
      } catch (error) {
        showGraphQlErrorNotification("Error while updating transaction", error);
      } finally {
        destroyMessage(loadingKey);
      }
    },
    [updateTransactionsMeta, expandedRowKeys, confirmDeleteBusinessAsset]
  );

  /**
   * Update split.
   * If split's category has changed, associated business asset will be removed.
   * In that case, a delete confirmation modal will be displayed.
   * Updated data will be saved into the component's state, and will be processed when user clicks the confirm button.
   * Otherwise, data will be cleared from the state.
   */
  const handleUpdateSplits = useCallback(
    async ({
      transaction,
      splitState,
      skipConfirmationPopup = false,
    }: UpdateSplitsState) => {
      const confirmedDeleteBusinessAsset = await confirmDeleteBusinessAsset(
        getToBeDeletedBusinessAssets({
          id: transaction.id,
          businessAssets: transaction.businessAssets,
          splits: splitState.splits,
        })
      );
      if (!confirmedDeleteBusinessAsset) {
        return;
      }

      if (!skipConfirmationPopup) {
        // Split amount has changed
        const assetsToUpdateAmount = findBusinessAssetsToUpdateAmount({
          oldSplits: transaction.splits,
          newSplits: splitState.splits,
          businessAssets: transaction.businessAssets,
        });

        if (assetsToUpdateAmount?.length) {
          return setUpdatingSplits({
            transaction,
            splitState,
            assetsToUpdateAmount,
          });
        }
      }

      // Default submit behaviour
      // If the modal was shown, hide it after changing category
      setUpdatingSplits(null);

      await onSplitSubmit(transaction.id, splitState);
    },
    [onSplitSubmit, confirmDeleteBusinessAsset]
  );

  const handleDeleteSplit = useCallback(
    async (transaction: TransactionRow, state: TransactionSplitState) => {
      const confirmedDeleteBusinessAsset = await confirmDeleteBusinessAsset(
        getToBeDeletedBusinessAssets({
          id: transaction.id,
          businessAssets: transaction.businessAssets,
        })
      );
      if (!confirmedDeleteBusinessAsset) {
        return false;
      }

      onSplitDelete(transaction.id, state);
      return true;
    },
    [confirmDeleteBusinessAsset, onSplitDelete]
  );

  const onTransactionUpdated = useCallback(
    (upsertedTransaction: ITransaction) => {
      const updatedTransactions = (transactions || [])
        .filter((transaction) => transaction.id !== upsertedTransaction.id)
        .concat([upsertedTransaction])
        .sort((a: ITransaction, b: ITransaction) =>
          moment(b.valutaDate).isAfter(a.valutaDate) ? 1 : -1
        );

      updateTransactionsState({
        transactions: updatedTransactions,
      });

      notification.success({
        message: "Created or updated transaction successfully.",
      });
    },
    [transactions, updateTransactionsState]
  );

  const columns = [
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      width: 100,
      render: (amount: number) => (
        <div data-test="amountCell">{formatAmountInCents(amount, true)}</div>
      ),
      sorter: (a: TransactionRow, b: TransactionRow) => a.amount - b.amount,
      align: "right" as const,
      showSorterTooltip: false,
      shouldCellUpdate,
    },
    {
      title: <FileTextOutlined className="centerIcon" />,
      key: "invoice",
      width: 60,
      render: (record: TransactionRow) => {
        return record.hasIntegrationDocument ||
          (record.assets || []).length > 0 ? (
          <InvoiceIcon data-test="fileIcon" />
        ) : null;
      },
      align: "center" as const,
      shouldCellUpdate,
      filters: [
        {
          text: "Has Receipt",
          value: 1,
        },
        {
          text: "No Receipt",
          value: 0,
        },
      ],
      onFilter: (value: string | number | boolean, record: TransactionRow) => {
        const hasAnyDocument =
          !!record.assets?.length || !!record?.hasIntegrationDocument;
        if (value === 1) {
          return hasAnyDocument;
        } else {
          return !hasAnyDocument;
        }
      },
      filterMultiple: false,
    },
    {
      title: () => {
        return <CommentIcon />;
      },
      key: "note",
      width: 60,
      render: (record: TransactionRow) => {
        return record.internalNote || record.personalNote ? (
          <HoverableCommentIcon />
        ) : null;
      },
      filters: [
        {
          text: "Internal Note",
          value: 0,
        },
        {
          text: "User's Note",
          value: 1,
        },
        {
          text: "All",
          value: 2,
        },
      ],
      onFilter: (value: string | number | boolean, record: TransactionRow) => {
        if (value === 0) {
          return !!record.internalNote;
        } else if (value === 1) {
          return !!record.personalNote;
        } else {
          return !!(record.internalNote || record.personalNote);
        }
      },
      filterMultiple: false,
      align: "center" as const,
      shouldCellUpdate,
    },
    {
      title: "Paid by",
      key: "paidBy",
      dataIndex: "paidBy",
      width: 140,
      render: (paidBy: string) => <div data-test="paid-by">{paidBy}</div>,
      sorter: (a: TransactionRow, b: TransactionRow) =>
        (a.paidBy || "").localeCompare(b.paidBy || ""),
      showSorterTooltip: false,
      shouldCellUpdate,
    },
    {
      title: "IBAN",
      key: "iban",
      width: 90,
      render: (record: TransactionRow) => (
        <Tooltip placement="topLeft" title={record.iban}>
          <Copyable withFixedIcon ellipsis>
            {record.iban}
          </Copyable>
        </Tooltip>
      ),
      sorter: (a: TransactionRow, b: TransactionRow) =>
        (a.iban || "").localeCompare(b.iban || ""),
      showSorterTooltip: false,
      ellipsis: {
        showTitle: false,
      },
      onCell: (record: TransactionRow) => {
        return {
          onClick: (event: any) => {
            event.stopPropagation();
            if (record.iban) copyToClipboard(record.iban);
          },
        };
      },
      shouldCellUpdate,
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      width: 150,
      sorter: (a: TransactionRow, b: TransactionRow) =>
        (a.description || "").localeCompare(b.description || ""),
      showSorterTooltip: false,
      shouldCellUpdate,
    },
    {
      title: "Payment Date",
      key: "paymentDate",
      dataIndex: "paymentDate",
      width: 120,
      sorter: (a: TransactionRow, b: TransactionRow) =>
        moment(a.paymentDate).isAfter(b.paymentDate) ? 1 : -1,
      showSorterTooltip: false,
      align: "center" as const,
      shouldCellUpdate,
    },
    {
      title: "Verified",
      key: "verified",
      width: 130,
      render: (record: TransactionRow) => {
        return (
          <div data-test="verificationCell">
            <VerificationField
              categoryCode={record.categoryCode}
              vatCategoryCode={record.vatCategoryCode}
              categoryCodeMeta={record.categoryCodeMeta}
              vatCategoryCodeMeta={record.vatCategoryCodeMeta}
              id={record.id}
              updateTransaction={handleUpdateTransaction}
              value={record.verified || false}
              isSplitCategorized={record.isSplitCategorized}
              isDatevTransaction={!!getDatevSource(record)}
              verifiedBy={record.verifiedBy}
              hasAssets={
                !!record.assets?.length || !!record?.hasIntegrationDocument
              }
            />
          </div>
        );
      },
      filters: [
        {
          text: "Verified",
          value: true,
        },
        {
          text: "Not Verified",
          value: false,
        },
        {
          text: "Verified by ML",
          value: "ml",
        },
      ],
      filterMultiple: false,
      onFilter: (
        value: string | number | boolean,
        record: TransactionRow
      ): boolean => {
        if (value === "ml") {
          return !!record.verified && record.verifiedBy === VERIFIED_BY_ML;
        }

        return record.verified === value;
      },
      sorter: (a: TransactionRow, b: TransactionRow) => {
        const isVerified = (t: TransactionRow) => (t.verified ? 1 : -1);
        return isVerified(a) - isVerified(b);
      },
      showSorterTooltip: false,
      onCell: onClickStopPropagation,
      shouldCellUpdate,
    },
    {
      title: "Category",
      key: "categoryCode",
      width: 348,
      render: (record: TransactionRow) => {
        return (
          <TransactionsTableCategory
            record={record}
            handleUpdateTransaction={handleUpdateTransaction}
          />
        );
      },
      sorter: (a: TransactionRow, b: TransactionRow) => {
        if (!a.categoryCode && b.categoryCode) {
          return -1;
        }

        if (a.categoryCode && !b.categoryCode) {
          return 1;
        }

        if (!a.categoryCode && !b.categoryCode) {
          return a.isSplitCategorized ? 1 : -1;
        }

        return parseInt(a.categoryCode!) - parseInt(b.categoryCode!);
      },
      showSorterTooltip: false,
      onCell: onClickStopPropagation,
      shouldCellUpdate,
    },
    {
      title: "VAT Category",
      key: "vatCategoryCode",
      width: 348,
      render: (record: TransactionRow) => {
        return (
          <TransactionsVatCategory
            record={record}
            updateTransactionsMeta={updateTransactionsMeta}
          />
        );
      },
      sorter: (a: TransactionRow, b: TransactionRow) => {
        if (!a.vatCategoryCode && b.vatCategoryCode) {
          return -1;
        }

        if (a.vatCategoryCode && !b.vatCategoryCode) {
          return 1;
        }

        if (!a.vatCategoryCode && !b.vatCategoryCode) {
          return a.isSplitCategorized ? 1 : -1;
        }

        return parseInt(a.vatCategoryCode!) - parseInt(b.vatCategoryCode!);
      },
      showSorterTooltip: false,
      onCell: onClickStopPropagation,
      shouldCellUpdate,
    },
    {
      title: "Submitted",
      key: "submitted",
      width: 150,
      render: (record: TransactionRow) => (
        <TransactionSubmissionStatus record={record} />
      ),
      filters: [
        {
          text: "Submitted",
          value: TransactionSubmissionState.SUBMITTED,
        },
        {
          text: "Not submitted",
          value: TransactionSubmissionState.NOT_SUBMITTED,
        },
        {
          text: "Fibu changed",
          value: TransactionSubmissionState.FIBU_CHANGED,
        },
        {
          text: "VCC changed",
          value: TransactionSubmissionState.VCC_CHANGED,
        },
        {
          text: "Fibu & VCC changed",
          value: TransactionSubmissionState.MULTIPLE_CHANGES,
        },
      ],
      onFilter: (value: string | number | boolean, record: TransactionRow) =>
        getSubmissionStatus(record) === value,
      sorter: (a: TransactionRow, b: TransactionRow) =>
        getSubmissionStatus(a).localeCompare(getSubmissionStatus(b)),
      filterMultiple: false,
      showSorterTooltip: false,
      onCell: onClickStopPropagation,
      shouldCellUpdate,
    },
  ];

  const data = prepareTransactionsData(transactions);

  const renderExpandedRow = (transaction: TransactionRow) => {
    return (
      <TransactionsTableExpandedRow
        transaction={transaction}
        onAssetsUpdate={onAssetsUpdate}
        handleDeleteSplits={handleDeleteSplit}
        handleUpdateSplits={handleUpdateSplits}
        downloadingIntegrationDocument={downloadingIntegrationDocument}
        updateTransactionsMeta={updateTransactionsMeta}
        editExternalTransactionButtonOnClick={() => {
          setShowExternalTransactionPopup(true);
          setActiveExternalTransaction(transaction);
        }}
        onTransactionUpdated={onTransactionUpdated}
      />
    );
  };

  if (!transactions) {
    return (
      <MessageWrapper>
        <p>Search for transactions by email</p>
      </MessageWrapper>
    );
  }

  const downloadIntegrationDocument = async (transaction: TransactionRow) => {
    try {
      setDownloadingIntegrationDocument({
        downloading: true,
        id: transaction.id,
      });
      const response = await api.kontax.downloadIntegrationDocument(
        transaction.id
      );
      if (response?.data) {
        onAssetsUpdate(transaction.id, [getAsset(response?.data)]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error while downloading");
    } finally {
      setDownloadingIntegrationDocument({
        downloading: false,
        id: transaction.id,
      });
    }
  };

  const onExpand = async (expanded: boolean, record: TransactionRow) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys);

    if (expanded && record.hasIntegrationDocument) {
      await downloadIntegrationDocument(record);
    }
  };

  const toggleShowCategorizeBatch = () => {
    if (
      selectedTransactions.some(
        (t) =>
          t.categoryCode &&
          Object.values<string>(DepreciableCategory).includes(t.categoryCode)
      )
    ) {
      showMessage({
        type: IMessageType.ERROR,
        content:
          "It is not possible to recategorize transactions with category 1320 or 1370",
      });
      return;
    }

    if (selectedTransactions.some((t) => t.verified)) {
      showMessage({
        type: IMessageType.ERROR,
        content: "It is not possible to recategorize verified transactions",
      });
      return;
    }
    if (selectedTransactions.some((t) => t.splits?.length)) {
      showMessage({
        type: IMessageType.ERROR,
        content: "It is not possible to batch-categorize splits",
      });
      return;
    }
    setIsCategorizeBatchPopupVisible(!isCategorizeBatchPopupVisible);
  };

  return (
    <>
      <ExternalTransactionPopup
        isPopupShown={showExternalTransactionPopup}
        onSuccess={onTransactionUpdated}
        email={email as string}
        closePopup={() => {
          setShowExternalTransactionPopup(false);
          setActiveExternalTransaction(undefined);
        }}
        initialValues={
          activeExternalTransaction as ExternalTransactionInitialValues
        }
      />

      <CategorizeBatchPopup
        isPopupVisible={isCategorizeBatchPopupVisible}
        closePopup={() => {
          toggleShowCategorizeBatch();
        }}
        selectedTransactions={selectedTransactions}
        updateTransactionsMeta={updateTransactionsMeta}
      />

      <TransactionsTableButtons
        addExternalTransactionClick={() => {
          setShowExternalTransactionPopup(true);
        }}
        transactions={transactions}
        updateTransactionsMeta={updateTransactionsMeta}
        updateTransactionsState={updateTransactionsState}
        hasSelectedTransactions={!!selectedTransactions.length}
        hasSelectedVerifiedTransactionsOnly={
          hasSelectedVerifiedTransactionsOnly
        }
        verifySelectedTransactions={verifySelectedTransactions}
        toggleShowCategorizeBatch={toggleShowCategorizeBatch}
        isNested={isNested}
      />

      {transactions.length ? (
        <TableWrapper>
          <Table
            data-test="verificationTable"
            rowSelection={{
              onChange: (selectedTransactionIds: React.Key[]) => {
                setSelection(selectedTransactionIds as string[]);
              },
              selectedRowKeys: selectedTransactions.map((t) => t.id),
              renderCell: (_checked, _record, _i, node) => {
                // We use this logic to be able to select the checkbox
                // also when clicking somewhere else on the cell
                // but still persist ant'shift + click logic
                return (
                  <CheckboxCell
                    data-test={`checkbox-row-${_i}`}
                    onClick={(event) => {
                      event.stopPropagation();

                      const checkbox =
                        event.currentTarget.querySelector<HTMLInputElement>(
                          'input[type="checkbox"]'
                        );

                      checkbox?.dispatchEvent(
                        new globalThis.MouseEvent(
                          event.nativeEvent.type,
                          event.nativeEvent
                        )
                      );
                    }}
                  >
                    {node}
                  </CheckboxCell>
                );
              },
            }}
            columns={columns}
            dataSource={data}
            bordered
            expandable={{
              expandedRowRender: renderExpandedRow,
              expandedRowClassName: () => "expandable-row",
              onExpand,
              expandedRowKeys,
              expandIconColumnIndex: -1,
            }}
            pagination={
              !isNested && {
                position: ["topRight", "bottomRight"],
                defaultPageSize: 100,
                showSizeChanger: true,
                pageSizeOptions: ["50", "100", "200", "300"],
              }
            }
            scroll={{ x: "100%" }}
            rowClassName={getTransactionRowClassName}
            expandRowByClick
            size="middle"
            sticky
          />

          <TransactionsTablePopups
            handleUpdateSplits={handleUpdateSplits}
            updatingSplits={updatingSplits}
          />
          {deleteBusinessAssetConfirmationPopup}
        </TableWrapper>
      ) : (
        <EmptyWrapper description="No transactions found 🔎 " />
      )}
    </>
  );
};

export default TransactionsTable;
