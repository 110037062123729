import { ConfigProvider } from "antd";
import enUSIntl from "antd/lib/locale/en_US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ApolloProvider } from "@apollo/client";

import Login from "./components/Login";
import ActivationView from "./components/pages/Mandanten/ActivationView";
import TransactionView from "./components/pages/TransactionView";
import BelegeView from "./components/pages/BelegeView";
import VerificationView from "./components/pages/VerificationView";
import RulesRouter from "./components/pages/RulesView";
import VatView from "./components/pages/VatView";
import ElsterView from "./components/pages/VatView/ElsterView";
import BWAView from "./components/pages/BWAView";
import { setupAuth } from "./gapi";
import TaxDeclaration from "./components/pages/TaxDeclaration";
import client from "./api/graphql/client";
import TaxOpsView from "./components/pages/TaxOpsView";
import PageWrapperWithNavigation from "./components/common/PageWrapperWithNavigation";
import OnboardingView from "./components/pages/Mandanten/OnboardingView";
import TaxAccountView from "./components/pages/TaxAccountView";
import AnschreibenTemplate from "./components/pages/Anschreiben/AnschreibenTemplate";
import DebugBar from "./components/common/DebugBar";
import DebugIndicator from "./components/common/DebugIndicator";
import FeedbackView from "./components/pages/FeedbackView";
import { PermissionsScope } from "./types";
import AnnotationTagView from "./components/pages/AnnotationTagView";
import TaxServiceView from "./components/pages/TaxServiceView";
import ActivityTrackingView from "./components/pages/ActivityTrackingView";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "./constants";

function App() {
  const [permissionScope, setPermissionScope] =
    useState<PermissionsScope | null>(null);

  useEffect(() => setupAuth(setPermissionScope), []);

  const authCheck = (
    Component: React.ComponentType<{}>,
    requiredScope?: PermissionsScope[]
  ) => {
    if (!permissionScope || permissionScope === PermissionsScope.INVALID) {
      return <Redirect to="/login" />;
    }

    if (requiredScope && !requiredScope.includes(permissionScope)) {
      return <Redirect to="/" />;
    }

    return <Component />;
  };

  return (
    <>
      <DebugBar isSignedIn={Boolean(permissionScope)} />
      <DebugIndicator />
      {permissionScope === null ? (
        <div>Authenticating user...</div>
      ) : (
        <ApolloProvider client={client}>
          <Router>
            <ConfigProvider locale={enUSIntl}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <PageWrapperWithNavigation>
                  <Switch>
                    <Route
                      path="/"
                      exact
                      render={() =>
                        authCheck(ActivationView, [
                          ...TAX_ADVISORY_PERMISSION_SCOPES,
                          PermissionsScope.DIY,
                        ])
                      }
                    />
                    <Route
                      path="/onboarding"
                      exact
                      render={() =>
                        authCheck(
                          OnboardingView,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                    <Route
                      path="/tax-account-view"
                      exact
                      render={() =>
                        authCheck(
                          TaxAccountView,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                    <Route
                      path="/tax-service-view"
                      exact
                      render={() =>
                        authCheck(
                          TaxServiceView,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                    <Route
                      path="/activity-tracking"
                      exact
                      render={() =>
                        authCheck(
                          ActivityTrackingView,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                    <Route path="/login" exact component={Login} />
                    <Route
                      path="/transaction-view"
                      exact
                      render={() =>
                        authCheck(
                          TransactionView,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                    <Route
                      path="/belege-view"
                      exact
                      render={() =>
                        authCheck(BelegeView, TAX_ADVISORY_PERMISSION_SCOPES)
                      }
                    />
                    <Route
                      path="/verification-view"
                      exact
                      render={() =>
                        authCheck(
                          VerificationView,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                    <Route
                      path="/feedback-view"
                      exact
                      render={() =>
                        authCheck(FeedbackView, [
                          PermissionsScope.TAX_SUPERVISOR,
                        ])
                      }
                    />
                    <Route
                      path="/annotation-tag-view"
                      exact
                      render={() =>
                        authCheck(AnnotationTagView, [
                          PermissionsScope.TAX_SUPERVISOR,
                        ])
                      }
                    />
                    <Route
                      path="/rules-view"
                      render={() =>
                        authCheck(RulesRouter, TAX_ADVISORY_PERMISSION_SCOPES)
                      }
                    />
                    <Route
                      path="/elster-view"
                      exact
                      render={() =>
                        authCheck(ElsterView, TAX_ADVISORY_PERMISSION_SCOPES)
                      }
                    />
                    <Route
                      path="/vat-view"
                      exact
                      render={() =>
                        authCheck(VatView, TAX_ADVISORY_PERMISSION_SCOPES)
                      }
                    />
                    <Route
                      path="/bwa-view"
                      exact
                      render={() =>
                        authCheck(BWAView, TAX_ADVISORY_PERMISSION_SCOPES)
                      }
                    />
                    <Route
                      path="/tax-declaration"
                      render={() =>
                        authCheck(
                          TaxDeclaration,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                    <Route
                      path="/tax-ops-permission-view"
                      render={() =>
                        authCheck(TaxOpsView, TAX_ADVISORY_PERMISSION_SCOPES)
                      }
                    />
                    <Route
                      path="/anschreiben-template"
                      render={() =>
                        authCheck(
                          AnschreibenTemplate,
                          TAX_ADVISORY_PERMISSION_SCOPES
                        )
                      }
                    />
                  </Switch>
                </PageWrapperWithNavigation>
              </QueryParamProvider>
            </ConfigProvider>
          </Router>
        </ApolloProvider>
      )}
    </>
  );
}

export default App;
