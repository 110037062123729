export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  JSON: any;
  JSONObject: Record<string, unknown>;
};

export type AbfrageSteuerart = {
  __typename?: "AbfrageSteuerart";
  steuerartErlaeuterung?: Maybe<Array<Scalars["String"]>>;
  steuerartGesamtbetrag?: Maybe<Array<Scalars["String"]>>;
  steuerartKlartext?: Maybe<Array<Scalars["String"]>>;
  steuerartTeilbetrag?: Maybe<Array<SteuerartTeilbetrag>>;
};

export type AccountStats = {
  __typename?: "AccountStats";
  /** The amount that is currently available on the bank account */
  accountBalance: Scalars["Int"];
  /** The amount that can be spent plus the amount from uknown */
  main: Scalars["Int"];
  /** The amount of tax that is owed in the current year */
  taxCurrentYearAmount: Scalars["Int"];
  /** The difference between taxTotal and accountBalance, if taxTotal > accountbalance */
  taxMissing: Scalars["Int"];
  /** The amount of tax that was owed for all past years combined */
  taxPastYearsAmount?: Maybe<Scalars["Int"]>;
  /** The amount of tax that is owed (current + last years) */
  taxTotal: Scalars["Int"];
  /** The amount that is not categorized */
  unknown: Scalars["Int"];
  /** The amount of VAT that is owed in the current year */
  vatAmount: Scalars["Int"];
  /** The difference between vatTotal and accountBalance, if vatTotal > accountBalance */
  vatMissing: Scalars["Int"];
  /** The amount of VAT that is owed (current + last years) */
  vatTotal: Scalars["Int"];
  /** The amount that can be spent after VAT and taxes calculation */
  yours: Scalars["Int"];
};

export enum ActionReason {
  CAR_USAGE = "CAR_USAGE",
  ELECTRONIC_SERVICES = "ELECTRONIC_SERVICES",
  EXTERNAL_BANK_ACCOUNT = "EXTERNAL_BANK_ACCOUNT",
  INCOMING_AMOUNT_WRONG = "INCOMING_AMOUNT_WRONG",
  INVALID_RECEIPT = "INVALID_RECEIPT",
  INVOICE_ABOVE_250 = "INVOICE_ABOVE_250",
  INVOICE_BELOW_250 = "INVOICE_BELOW_250",
  INVOICE_REQUIRED = "INVOICE_REQUIRED",
  MISSING_TAX_EXEMPT_SALES = "MISSING_TAX_EXEMPT_SALES",
  NO_HOSPITALITY_RECEIPT = "NO_HOSPITALITY_RECEIPT",
  NO_REDUCED_TAX = "NO_REDUCED_TAX",
  OBLIGED_TAXES = "OBLIGED_TAXES",
  OUTGOING_AMOUNT_WRONG = "OUTGOING_AMOUNT_WRONG",
  REVERSE_CHARGE_INFORMATION = "REVERSE_CHARGE_INFORMATION",
  REVERSE_CHARGE_MISSING = "REVERSE_CHARGE_MISSING",
  SMALL_BUSINESS_MISSING = "SMALL_BUSINESS_MISSING",
  SMALL_BUSINESS_VAT = "SMALL_BUSINESS_VAT",
  UNCLEAR_EXPENSE = "UNCLEAR_EXPENSE",
  VAT_ID_MISSING = "VAT_ID_MISSING",
  WRONG_OUTGOING_INVOICE = "WRONG_OUTGOING_INVOICE",
  WRONG_TAXRATE_ANCILLARY_SERVICE = "WRONG_TAXRATE_ANCILLARY_SERVICE",
}

export type Annotation = {
  __typename?: "Annotation";
  color: Scalars["String"];
  end: Scalars["Float"];
  id: Scalars["ID"];
  start: Scalars["Float"];
  tag: Scalars["String"];
  text: Scalars["String"];
};

export type AnnotationOutput = {
  __typename?: "AnnotationOutput";
  documentId: Scalars["ID"];
  text: Scalars["String"];
  value: Array<Annotation>;
};

export type AnnotationTag = {
  __typename?: "AnnotationTag";
  color: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  tag: Scalars["String"];
};

export type AnnotationTagInput = {
  color: Scalars["String"];
  description: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  tag: Scalars["String"];
};

export type AnnotationValueInput = {
  annotationTagId: Scalars["ID"];
  documentId: Scalars["ID"];
  end: Scalars["Int"];
  start: Scalars["Int"];
  text: Scalars["String"];
};

export type Anschreiben = {
  __typename?: "Anschreiben";
  anschreibenPdfUrl?: Maybe<Scalars["String"]>;
  approvedAt?: Maybe<Scalars["DateTime"]>;
  content: Scalars["String"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  exportedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  note?: Maybe<Scalars["String"]>;
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  sentAt?: Maybe<Scalars["DateTime"]>;
  year: Scalars["Float"];
};

export type AnschreibenTemplate = {
  __typename?: "AnschreibenTemplate";
  content: Scalars["String"];
  id: Scalars["ID"];
  language: Scalars["String"];
  note: Scalars["String"];
  type: Scalars["String"];
  year: Scalars["Float"];
};

export enum AnschreibenType {
  FREELANCER = "FREELANCER",
  TRADER = "TRADER",
}

export type Asset = {
  __typename?: "Asset";
  assetableId: Scalars["ID"];
  filetype: Scalars["String"];
  fullsize: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  path: Scalars["String"];
  thumbnail: Scalars["String"];
};

export type AssetData = {
  __typename?: "AssetData";
  filetype: Scalars["String"];
  fullsize: Scalars["String"];
  id: Scalars["String"];
};

export enum AssetType {
  IMMOVABLE = "IMMOVABLE",
  INTANGIBLE = "INTANGIBLE",
  MOVABLE_MOTOR_VEHICLES = "MOVABLE_MOTOR_VEHICLES",
  MOVABLE_OFFICE_EQUIPMENT = "MOVABLE_OFFICE_EQUIPMENT",
  MOVABLE_OTHERS = "MOVABLE_OTHERS",
}

export type AutocategorizationRule = {
  __typename?: "AutocategorizationRule";
  accuracy?: Maybe<Scalars["Float"]>;
  appliedTimesCount?: Maybe<Scalars["Int"]>;
  categoryCode?: Maybe<CategoryCode>;
  conditions: AutocategorizationRuleConditions;
  createdAt: Scalars["DateTime"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  id: Scalars["Int"];
  isSplit: Scalars["Boolean"];
  priority: Scalars["Int"];
  transactionId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  vatCategoryCode?: Maybe<VatCategoryCode>;
  verify: Scalars["Boolean"];
};

export type AutocategorizationRuleConditions = {
  __typename?: "AutocategorizationRuleConditions";
  accountIds?: Maybe<Array<Scalars["Int"]>>;
  amount?: Maybe<RuleConditionNumber>;
  description?: Maybe<RuleConditionString>;
  hasEmployees?: Maybe<Scalars["Boolean"]>;
  hasInvoices?: Maybe<Scalars["Boolean"]>;
  hasVatNumber?: Maybe<Scalars["Boolean"]>;
  ibans?: Maybe<Array<Scalars["String"]>>;
  name?: Maybe<RuleConditionString>;
  vatPaymentFrequencies?: Maybe<Array<PaymentFrequency>>;
};

export type AutocategorizationRuleConditionsInput = {
  accountIds?: InputMaybe<Array<Scalars["Int"]>>;
  amount?: InputMaybe<RuleConditionNumberInput>;
  description?: InputMaybe<RuleConditionStringInput>;
  hasEmployees?: InputMaybe<Scalars["Boolean"]>;
  hasInvoices?: InputMaybe<Scalars["Boolean"]>;
  hasVatNumber?: InputMaybe<Scalars["Boolean"]>;
  ibans?: InputMaybe<Array<Scalars["String"]>>;
  name?: InputMaybe<RuleConditionStringInput>;
  vatPaymentFrequencies?: InputMaybe<Array<PaymentFrequency>>;
};

export type BwaStatsCategory = {
  __typename?: "BWAStatsCategory";
  category: Scalars["String"];
  data: Array<BwaStatsData>;
};

export type BwaStatsData = {
  __typename?: "BWAStatsData";
  month: Scalars["String"];
  sum: Scalars["Int"];
  year: Scalars["String"];
};

export enum BaseOperator {
  AND = "AND",
  OR = "OR",
}

export enum BookingMode {
  ACCOUNTANT = "ACCOUNTANT",
  ACCOUNT_PAYABLE = "ACCOUNT_PAYABLE",
}

export enum BookkeepingTool {
  DATEV = "DATEV",
  FREE_TEXT = "FREE_TEXT",
  LEXOFFICE = "LEXOFFICE",
  SEVDESK = "SEVDESK",
}

/** Business Address of a Kontax User */
export type BusinessAddress = {
  __typename?: "BusinessAddress";
  changeLogs: Array<ChangeLog>;
  city: Scalars["String"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  isSameAsPrivateAddress: Scalars["Boolean"];
  movingDate: Scalars["DateTime"];
  postCode: Scalars["String"];
  street: Scalars["String"];
};

export type BusinessAddressMovement = {
  __typename?: "BusinessAddressMovement";
  movingDate: Scalars["DateTime"];
  previousBusinessAddress: BusinessAddress;
};

export type BusinessAsset = {
  __typename?: "BusinessAsset";
  amount: Scalars["Float"];
  assetClass: Scalars["String"];
  assetType: AssetType;
  businessAssetableId: Scalars["String"];
  businessAssetableType: Scalars["String"];
  depreciationPeriodYears: Scalars["Int"];
  purchaseDate: Scalars["DateTime"];
};

export type BusinessAssetForm = {
  assetClass: Scalars["String"];
  assetType: AssetType;
  depreciationPeriodYears: Scalars["Int"];
  purchaseDate: Scalars["String"];
};

export type BusinessAssetMetaData = {
  __typename?: "BusinessAssetMetaData";
  amount: Scalars["Float"];
  transactionDescription?: Maybe<Scalars["String"]>;
  transactionName?: Maybe<Scalars["String"]>;
  transactionValutaDate?: Maybe<Scalars["DateTime"]>;
};

export type BusinessAssetReceipt = {
  __typename?: "BusinessAssetReceipt";
  filetype: Scalars["String"];
  fullsize: Scalars["String"];
  id: Scalars["String"];
};

export type BusinessAssetResponse = {
  __typename?: "BusinessAssetResponse";
  amount: Scalars["Float"];
  assetClass: Scalars["String"];
  assetType: AssetType;
  bookValueOnExit?: Maybe<Scalars["Int"]>;
  categoryCode: Scalars["String"];
  depreciationPeriodYears: Scalars["Float"];
  depreciations: Array<Depreciation>;
  endAmount?: Maybe<Scalars["Int"]>;
  exitAmount?: Maybe<Scalars["Int"]>;
  exitDate?: Maybe<Scalars["DateTime"]>;
  exitReason?: Maybe<ExitReason>;
  id: Scalars["String"];
  isExitedWithVat?: Maybe<Scalars["Boolean"]>;
  metaData?: Maybe<BusinessAssetMetaData>;
  naturallyDepreciated: Scalars["Boolean"];
  note?: Maybe<Scalars["String"]>;
  purchaseDate: Scalars["DateTime"];
  receipts: Array<BusinessAssetReceipt>;
};

export enum CategorizationType {
  AUTOMATIC_KONTIST_ML = "AUTOMATIC_KONTIST_ML",
  BOOKKEEPING_PARTNER = "BOOKKEEPING_PARTNER",
  INVOICING = "INVOICING",
  KONTAX = "KONTAX",
  SCRIPT = "SCRIPT",
  SUGGESTED_BY_ML = "SUGGESTED_BY_ML",
  USER = "USER",
  USER_OVERWRITE = "USER_OVERWRITE",
}

export enum CategoryCode {
  ACCOMMODATION = "ACCOMMODATION",
  ADVERTISING = "ADVERTISING",
  ASSETS_GREATER_THAN_EUR_250 = "ASSETS_GREATER_THAN_EUR_250",
  ASSETS_GREATER_THAN_EUR_800 = "ASSETS_GREATER_THAN_EUR_800",
  ASSETS_LESS_THAN_EUR_250 = "ASSETS_LESS_THAN_EUR_250",
  BANK_FEES = "BANK_FEES",
  BOOKS = "BOOKS",
  CAR_COSTS = "CAR_COSTS",
  CAR_FEES = "CAR_FEES",
  CONSTRUCTION_REVENUE = "CONSTRUCTION_REVENUE",
  CORONA_HELP = "CORONA_HELP",
  DAILY_ALLOWANCE = "DAILY_ALLOWANCE",
  DEPOSIT = "DEPOSIT",
  DOWN_PAYMENT = "DOWN_PAYMENT",
  EDUCATION = "EDUCATION",
  ELECTRONIC_SERVICE_EU_B2C = "ELECTRONIC_SERVICE_EU_B2C",
  ELECTRONIC_SERVICE_EU_B2C_KU = "ELECTRONIC_SERVICE_EU_B2C_KU",
  ENTERTAINMENT = "ENTERTAINMENT",
  EXTERNAL_FREELANCER = "EXTERNAL_FREELANCER",
  FEES = "FEES",
  FREE_VALUE_DELIVERY = "FREE_VALUE_DELIVERY",
  FREE_VALUE_DELIVERY_PV_19 = "FREE_VALUE_DELIVERY_PV_19",
  FREE_VALUE_SERVICE = "FREE_VALUE_SERVICE",
  GIFTS = "GIFTS",
  GOODS = "GOODS",
  IMPORT_VAT = "IMPORT_VAT",
  INCOME_EU = "INCOME_EU",
  INCOME_GERMANY = "INCOME_GERMANY",
  INCOME_INTL = "INCOME_INTL",
  INCOME_ONLY_VAT = "INCOME_ONLY_VAT",
  INSURANCES = "INSURANCES",
  INTERESTS_ASSETS = "INTERESTS_ASSETS",
  INTERESTS_CAR_ASSETS = "INTERESTS_CAR_ASSETS",
  INTERESTS_OTHER = "INTERESTS_OTHER",
  IT_COSTS = "IT_COSTS",
  LEASING_CAR = "LEASING_CAR",
  LEASING_MOVABLES = "LEASING_MOVABLES",
  LEGAL_TAX_CONSULTING = "LEGAL_TAX_CONSULTING",
  LIMITED_DEDUCTIBLE_EXPENSES = "LIMITED_DEDUCTIBLE_EXPENSES",
  LIMITED_NOT_DEDUCTIBLE_EXPENSES = "LIMITED_NOT_DEDUCTIBLE_EXPENSES",
  MAINTENANCE_COSTS = "MAINTENANCE_COSTS",
  OFFICE_COSTS = "OFFICE_COSTS",
  OTHER_EXPENSES = "OTHER_EXPENSES",
  OTHER_USAGE_AND_SERVICE_WITHDRAWALS = "OTHER_USAGE_AND_SERVICE_WITHDRAWALS",
  PAYROLL = "PAYROLL",
  PRIVATE_IN = "PRIVATE_IN",
  PRIVATE_OUT = "PRIVATE_OUT",
  PRIVATE_WITHDRAWAL = "PRIVATE_WITHDRAWAL",
  PUBLIC_TRANSPORT = "PUBLIC_TRANSPORT",
  RENT = "RENT",
  REVENUE_SB = "REVENUE_SB",
  SHIPPING_COSTS = "SHIPPING_COSTS",
  SOFTWARE_AND_LICENSES = "SOFTWARE_AND_LICENSES",
  TAX_PAYMENT = "TAX_PAYMENT",
  TAX_REFUND = "TAX_REFUND",
  TELECOMMUNICATION = "TELECOMMUNICATION",
  TRADE_TAX_PAYMENT = "TRADE_TAX_PAYMENT",
  TRADE_TAX_REFUND = "TRADE_TAX_REFUND",
  TRAVEL_COSTS = "TRAVEL_COSTS",
  VAT = "VAT",
  VAT_ON_UNPAID_ITEMS = "VAT_ON_UNPAID_ITEMS",
  VAT_PAYMENT = "VAT_PAYMENT",
  VAT_REFUND = "VAT_REFUND",
  WASTE_DISPOSALS = "WASTE_DISPOSALS",
}

export type ChangeLog = {
  __typename?: "ChangeLog";
  changedAt: Scalars["DateTime"];
  changedBy?: Maybe<Scalars["String"]>;
  changes: Scalars["JSONObject"];
  modelName: Scalars["String"];
  recordId: Scalars["String"];
};

export type CreateAnschreibenInput = {
  esteTelenumber: Scalars["String"];
  eurTelenumber?: InputMaybe<Scalars["String"]>;
  gewsteTelenumber?: InputMaybe<Scalars["String"]>;
  language: Scalars["String"];
  otherThings: Array<Scalars["String"]>;
  profitAndLossActivities: Array<Scalars["String"]>;
  type: AnschreibenType;
  usteTelenumber?: InputMaybe<Scalars["String"]>;
  year: Scalars["Float"];
};

export type CreateAssetResponse = {
  __typename?: "CreateAssetResponse";
  assetId: Scalars["ID"];
  formData: Array<FormDataPair>;
  name?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type CreateAutocategorizationRuleInput = {
  categoryCode: CategoryCode;
  conditions: AutocategorizationRuleConditionsInput;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  priority?: InputMaybe<Scalars["Int"]>;
  vatCategoryCode: VatCategoryCode;
  verify?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateAutocategorizationRulePayload = {
  __typename?: "CreateAutocategorizationRulePayload";
  autocategorizationRule: AutocategorizationRule;
};

export type CreateBusinessAddressInput = {
  city: Scalars["String"];
  movingDate: Scalars["DateTime"];
  postCode: Scalars["String"];
  street: Scalars["String"];
};

export type CreateRetroactiveKontaxFeeInput = {
  description: Scalars["String"];
  dueDate: Scalars["DateTime"];
  endDate: Scalars["DateTime"];
  quantity: Scalars["Int"];
  startDate: Scalars["DateTime"];
  unitAmount: Scalars["Float"];
};

export type CreateTaxNumberInput = {
  description: Scalars["String"];
  isMainBusinessTaxNumber: Scalars["Boolean"];
  taxNumber: Scalars["String"];
  type: TaxNumberType;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type CreateUsageStatInput = {
  activity: Scalars["String"];
  comment?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  finishedAt: Scalars["DateTime"];
  startedAt: Scalars["DateTime"];
};

export type DatevImport = {
  __typename?: "DatevImport";
  account: Scalars["String"];
  amount: Scalars["Float"];
  /** List of uploaded Asset files for this DatevImport */
  assets?: Maybe<Array<Asset>>;
  bookingMode: BookingMode;
  bookkeepingTool: BookkeepingTool;
  buKey: Scalars["String"];
  description: Scalars["String"];
  direction: Direction;
  id: Scalars["String"];
  kkr?: Maybe<Scalars["String"]>;
  meta1: Scalars["String"];
  offsetAccount: Scalars["String"];
  paymentDate: Scalars["DateTime"];
  receiptName?: Maybe<Scalars["String"]>;
  skrMode: SkrMode;
  vatCategoryCode?: Maybe<Scalars["String"]>;
  verifiedAt?: Maybe<Scalars["DateTime"]>;
};

export type DatevRawData = {
  account: Scalars["String"];
  amount: Scalars["String"];
  buKey: Scalars["String"];
  date: Scalars["String"];
  description: Scalars["String"];
  direction: Direction;
  meta1: Scalars["String"];
  offsetAccount: Scalars["String"];
  receiptName?: InputMaybe<Scalars["String"]>;
};

export type DeclarationApproval = {
  __typename?: "DeclarationApproval";
  createdAt: Scalars["DateTime"];
  delaySubmission?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  jointDeclaration?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["DateTime"];
};

export type DeclarationDecline = {
  __typename?: "DeclarationDecline";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  reason: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type DeclarationDocument = {
  __typename?: "DeclarationDocument";
  assets: Array<Asset>;
  createdAt: Scalars["DateTime"];
  documentType: DeclarationDocumentType;
  id: Scalars["ID"];
  updatedAt: Scalars["DateTime"];
};

export enum DeclarationDocumentType {
  PREVIEW_FORM = "PREVIEW_FORM",
  SUBMITTED_FORM = "SUBMITTED_FORM",
  TAX_BILL = "TAX_BILL",
}

export type DeleteDatevDataResult = {
  __typename?: "DeleteDatevDataResult";
  deletedIds?: Maybe<Array<Scalars["ID"]>>;
  success: Scalars["Boolean"];
};

export type Depreciation = {
  __typename?: "Depreciation";
  depreciationAmount: Scalars["Float"];
  depreciationMonths: Scalars["Float"];
  startAmount: Scalars["Float"];
  year: Scalars["Float"];
};

export type DirectDebitFee = {
  __typename?: "DirectDebitFee";
  amount: Scalars["Int"];
  id: Scalars["Int"];
  invoiceStatus: InvoiceStatus;
  name?: Maybe<Scalars["String"]>;
  type: TransactionFeeType;
  usedAt?: Maybe<Scalars["DateTime"]>;
};

export enum Direction {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

/** DocuWare document */
export type DocuWareDocument = {
  __typename?: "DocuWareDocument";
  category: Scalars["String"];
  createdAt: Scalars["String"];
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
};

export type DocuWareDocumentResponse = {
  __typename?: "DocuWareDocumentResponse";
  documents: Array<DocuWareDocument>;
  totalCount: Scalars["Int"];
};

export type Document = {
  __typename?: "Document";
  createdAt: Scalars["DateTime"];
  downloadUrl: Scalars["String"];
  id: Scalars["ID"];
  metadata?: Maybe<DocumentMetadata>;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  url: Scalars["String"];
};

export type DocumentCategory = {
  __typename?: "DocumentCategory";
  categoryName: Scalars["String"];
  folderName: Scalars["String"];
  id: Scalars["ID"];
};

export type DocumentCountResponse = {
  __typename?: "DocumentCountResponse";
  count: Scalars["Int"];
};

export enum DocumentMatchStatus {
  ALREADY_HAS_ASSET = "ALREADY_HAS_ASSET",
  LATER_MATCH = "LATER_MATCH",
  MANUAL_MATCH = "MANUAL_MATCH",
  MANUAL_MATCH_USER = "MANUAL_MATCH_USER",
  NO_MATCHES = "NO_MATCHES",
  OTHER_PROVIDER_MATCH = "OTHER_PROVIDER_MATCH",
  TOO_MANY_MATCHES = "TOO_MANY_MATCHES",
  WRONG_MATCH = "WRONG_MATCH",
}

export type DocumentMetadata = {
  __typename?: "DocumentMetadata";
  category: DocumentCategory;
};

export enum DocumentType {
  EXPENSE = "EXPENSE",
  INVOICE = "INVOICE",
  VOUCHER = "VOUCHER",
}

export enum DocumentUploadSource {
  BACKOFFICE = "BACKOFFICE",
  EMAIL = "EMAIL",
  EMAIL_FETCH = "EMAIL_FETCH",
  MOBILE = "MOBILE",
  WEB = "WEB",
}

export type EmailDocument = {
  __typename?: "EmailDocument";
  amount?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currency?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["DateTime"]>;
  documentNumber?: Maybe<Scalars["String"]>;
  filename: Scalars["String"];
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  matchStatus?: Maybe<DocumentMatchStatus>;
  /** Returns an array of transactions which potential match with an email document. Note that just a subset of transaction fields gets returned */
  matches?: Maybe<Array<Transaction>>;
  name?: Maybe<Scalars["String"]>;
  transactionId?: Maybe<Scalars["ID"]>;
  transactionMatches: Array<KontaxTransaction>;
  url: Scalars["String"];
};

export type EuerDeclaration = {
  __typename?: "EuerDeclaration";
  autoCalculatedValues: EuerDeclarationAutoCalculatedValues;
  changeLogs: Array<ChangeLog>;
  declarationApproval?: Maybe<DeclarationApproval>;
  declarationDeclines: Array<DeclarationDecline>;
  declarationDocuments: Array<DeclarationDocument>;
  fields: Scalars["JSONObject"];
  id: Scalars["ID"];
  lastStatusChange?: Maybe<EuerDeclarationStatusChange>;
  notes: Array<KontaxNote>;
  savedDraftInfo?: Maybe<TaxDeclarationSavedDraftInfo>;
  status: EuerDeclarationStatus;
  statusUpdatedAt?: Maybe<Scalars["DateTime"]>;
  submissionInfo?: Maybe<TaxDeclarationSubmissionInfo>;
  submissions: Array<TaxDeclarationSubmission>;
  submittedAt?: Maybe<Scalars["DateTime"]>;
  year: Scalars["Int"];
};

export type EuerDeclarationAutoCalculatedValues = {
  __typename?: "EuerDeclarationAutoCalculatedValues";
  fields: Scalars["JSONObject"];
  transactionsCount: Scalars["Int"];
  uncategorizedCount: Scalars["Int"];
  verifiedTransactionsCount: Scalars["Int"];
};

export enum EuerDeclarationStatus {
  APPEAL_PROCESS_COMPLETED = "APPEAL_PROCESS_COMPLETED",
  APPEAL_PROCESS_STARTED = "APPEAL_PROCESS_STARTED",
  APPROVED_BY_TAX_CONSULTANT = "APPROVED_BY_TAX_CONSULTANT",
  APPROVED_BY_USER = "APPROVED_BY_USER",
  CLOSED = "CLOSED",
  COMPLETED_BY_DATA = "COMPLETED_BY_DATA",
  COMPLETED_BY_OPS = "COMPLETED_BY_OPS",
  CONSULTATION_DATA = "CONSULTATION_DATA",
  IN_PROGRESS_DATA = "IN_PROGRESS_DATA",
  IN_PROGRESS_OPS = "IN_PROGRESS_OPS",
  IN_PROGRESS_TAX_CONSULTANT = "IN_PROGRESS_TAX_CONSULTANT",
  OBJECTED_BY_FINANZAMT = "OBJECTED_BY_FINANZAMT",
  OBJECTED_BY_TAX_CONSULTANT = "OBJECTED_BY_TAX_CONSULTANT",
  OBJECTED_BY_USER = "OBJECTED_BY_USER",
  OPEN = "OPEN",
  RECEIVED_TAX_BILL = "RECEIVED_TAX_BILL",
  SUBMITTED = "SUBMITTED",
  WAITING_FOR_USER_APPROVAL = "WAITING_FOR_USER_APPROVAL",
}

export type EuerDeclarationStatusChange = {
  __typename?: "EuerDeclarationStatusChange";
  changedAt: Scalars["DateTime"];
  changedBy?: Maybe<Scalars["String"]>;
  status: EuerDeclarationStatus;
};

export type EuerDeclarationSubform = {
  __typename?: "EuerDeclarationSubform";
  assets: Array<Asset>;
  autoCalculatedValues?: Maybe<Scalars["JSONObject"]>;
  calculationMethod: EuerDeclarationSubformCalculationMethod;
  calculationResults: Scalars["JSONObject"];
  changeLogs: Array<ChangeLog>;
  correspondingSubformMinDeductibleTravelExpenses?: Maybe<Scalars["Int"]>;
  homeOfficeDocuments: Array<HomeOfficeDocument>;
  homeOfficeExpenses: Array<HomeOfficeExpense>;
  id: Scalars["ID"];
  inputs: Scalars["JSONObject"];
  lastStatusChange?: Maybe<EuerDeclarationSubformStatusChange>;
  notes: Array<KontaxNote>;
  questionnaireCalculationMethod?: Maybe<EuerDeclarationSubformCalculationMethod>;
  questionnaireValues: Scalars["JSONObject"];
  status: EuerDeclarationSubformStatus;
  statusUpdatedAt?: Maybe<Scalars["DateTime"]>;
  syncedFromQuestionnaireAt?: Maybe<Scalars["DateTime"]>;
  totalNetAmount: Scalars["Int"];
  totalVatAmount: Scalars["Int"];
  type: EuerDeclarationSubformType;
  year: Scalars["Int"];
};

export enum EuerDeclarationSubformCalculationMethod {
  BUSINESS_ASSETS_DEFAULT = "BUSINESS_ASSETS_DEFAULT",
  CAR_USAGE_1_PCT_METHOD = "CAR_USAGE_1_PCT_METHOD",
  CAR_USAGE_DRIVERS_LOG = "CAR_USAGE_DRIVERS_LOG",
  OFFICE_USAGE_FLAT_RATE_5_EUR = "OFFICE_USAGE_FLAT_RATE_5_EUR",
  OFFICE_USAGE_FULL_COST = "OFFICE_USAGE_FULL_COST",
  OFFICE_USAGE_UP_TO_1250_EUR = "OFFICE_USAGE_UP_TO_1250_EUR",
  TRAVEL_EXPENSES_DEFAULT = "TRAVEL_EXPENSES_DEFAULT",
}

export enum EuerDeclarationSubformStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_RELEVANT = "NOT_RELEVANT",
  OPEN = "OPEN",
  VERIFIED = "VERIFIED",
}

export type EuerDeclarationSubformStatusChange = {
  __typename?: "EuerDeclarationSubformStatusChange";
  changedAt: Scalars["DateTime"];
  changedBy?: Maybe<Scalars["String"]>;
  status: EuerDeclarationSubformStatus;
};

export enum EuerDeclarationSubformType {
  BUSINESS_ASSETS = "BUSINESS_ASSETS",
  CAR_USAGE = "CAR_USAGE",
  OFFICE_USAGE = "OFFICE_USAGE",
  TRAVEL_EXPENSES = "TRAVEL_EXPENSES",
}

export type EuerProcessingRequest = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type EuerProcessingResponse = {
  __typename?: "EuerProcessingResponse";
  message: Scalars["String"];
  pdf?: Maybe<Scalars["String"]>;
  processResult: Scalars["String"];
};

export enum ExitReason {
  DEPRECIATED = "DEPRECIATED",
  LOST = "LOST",
  PRIVATE_USE = "PRIVATE_USE",
  SOLD = "SOLD",
}

export type ExternalTransactionInput = {
  amount: Scalars["Float"];
  businessAssetForm?: InputMaybe<BusinessAssetForm>;
  categoryCode?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentDate: Scalars["DateTime"];
  splits?: InputMaybe<Array<TransactionSplitInput>>;
  vatCategoryCode?: InputMaybe<Scalars["String"]>;
};

/** Kontax user's final fibu declaration check status */
export type FibuFinalCheck = {
  __typename?: "FibuFinalCheck";
  status: FibuFinalCheckStatus;
};

export enum FibuFinalCheckStatus {
  COMPLETED_BY_USER = "COMPLETED_BY_USER",
  DONE = "DONE",
  IN_PROGRESS_OPS = "IN_PROGRESS_OPS",
  IN_PROGRESS_USER = "IN_PROGRESS_USER",
  NOT_NEEDED = "NOT_NEEDED",
  QUALIFIED_FOR_OPS = "QUALIFIED_FOR_OPS",
  WAITING_FOR_PRECONSULTANT = "WAITING_FOR_PRECONSULTANT",
  WAITING_FOR_USER = "WAITING_FOR_USER",
}

export type FibuFinalCheckTask = {
  __typename?: "FibuFinalCheckTask";
  status: FibuFinalCheckTaskStatus;
  type: FibuFinalCheckTaskType;
};

export enum FibuFinalCheckTaskStatus {
  COMPLETED = "COMPLETED",
  TODO = "TODO",
}

export enum FibuFinalCheckTaskType {
  SUBMIT_ASSETS = "SUBMIT_ASSETS",
  SUBMIT_EXTERNAL_TRANSACTIONS = "SUBMIT_EXTERNAL_TRANSACTIONS",
  TAX_RECEIPTS = "TAX_RECEIPTS",
  UPLOAD_ADVISOR = "UPLOAD_ADVISOR",
  UPLOAD_MANUAL = "UPLOAD_MANUAL",
  UPLOAD_TOOL = "UPLOAD_TOOL",
}

export type FormDataPair = {
  __typename?: "FormDataPair";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type HomeOfficeDocument = {
  __typename?: "HomeOfficeDocument";
  assets: Array<Asset>;
  id: Scalars["ID"];
  note: Scalars["String"];
  syncedFromQuestionnaireAt?: Maybe<Scalars["DateTime"]>;
  type: HomeOfficeDocumentType;
};

export type HomeOfficeDocumentInput = {
  id?: InputMaybe<Scalars["ID"]>;
  note: Scalars["String"];
  type: HomeOfficeDocumentType;
};

export enum HomeOfficeDocumentType {
  FLOOR_PLAN = "FLOOR_PLAN",
  OTHER = "OTHER",
}

export type HomeOfficeExpense = {
  __typename?: "HomeOfficeExpense";
  adjustByOfficeAreaShare: Scalars["Boolean"];
  amount: Scalars["Int"];
  assets: Array<Asset>;
  grossAmount: Scalars["Int"];
  id: Scalars["ID"];
  monthsUsed?: Maybe<Scalars["Int"]>;
  netAmount: Scalars["Int"];
  note: Scalars["String"];
  period: HomeOfficeExpensePeriod;
  syncedFromQuestionnaireAt?: Maybe<Scalars["DateTime"]>;
  type: HomeOfficeExpenseType;
  vatAmount: Scalars["Int"];
  vatRate: Scalars["String"];
};

export type HomeOfficeExpenseInput = {
  adjustByOfficeAreaShare: Scalars["Boolean"];
  amount: Scalars["Int"];
  id?: InputMaybe<Scalars["ID"]>;
  monthsUsed?: InputMaybe<Scalars["Int"]>;
  note: Scalars["String"];
  period: HomeOfficeExpensePeriod;
  type: HomeOfficeExpenseType;
  vatRate: Scalars["String"];
};

export enum HomeOfficeExpensePeriod {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
  ONE_TIME_PAYMENT = "ONE_TIME_PAYMENT",
}

export enum HomeOfficeExpenseType {
  ELECTRICITY = "ELECTRICITY",
  HEATING = "HEATING",
  OTHER = "OTHER",
  PHONE_OR_INTERNET = "PHONE_OR_INTERNET",
  RENT_OR_INTEREST = "RENT_OR_INTEREST",
  UTILITY = "UTILITY",
  UTILITY_AFTER_PAYMENT = "UTILITY_AFTER_PAYMENT",
}

export type ImportDatevDataInput = {
  bookingMode: BookingMode;
  bookkeepingTool: BookkeepingTool;
  email: Scalars["String"];
  rawDataList: Array<DatevRawData>;
  skrMode: SkrMode;
  year: Scalars["String"];
};

export type IncomeTaxDeclaration = {
  __typename?: "IncomeTaxDeclaration";
  changeLogs: Array<ChangeLog>;
  declarationApproval?: Maybe<DeclarationApproval>;
  declarationDeclines: Array<DeclarationDecline>;
  declarationDocuments: Array<DeclarationDocument>;
  id: Scalars["String"];
  lastStatusChange?: Maybe<IncomeTaxDeclarationStatusChange>;
  notes: Array<KontaxNote>;
  savedDraftInfo?: Maybe<TaxDeclarationSavedDraftInfo>;
  status: IncomeTaxDeclarationStatus;
  statusUpdatedAt?: Maybe<Scalars["DateTime"]>;
  submissionInfo?: Maybe<TaxDeclarationSubmissionInfo>;
  year: Scalars["Int"];
};

export enum IncomeTaxDeclarationStatus {
  APPEAL_PROCESS_COMPLETED = "APPEAL_PROCESS_COMPLETED",
  APPEAL_PROCESS_STARTED = "APPEAL_PROCESS_STARTED",
  APPROVED_BY_TAX_CONSULTANT = "APPROVED_BY_TAX_CONSULTANT",
  APPROVED_BY_USER = "APPROVED_BY_USER",
  CLOSED = "CLOSED",
  COMPLETED_BY_DATA = "COMPLETED_BY_DATA",
  COMPLETED_BY_OPS = "COMPLETED_BY_OPS",
  CONSULTATION_DATA = "CONSULTATION_DATA",
  IN_PROGRESS_DATA = "IN_PROGRESS_DATA",
  IN_PROGRESS_OPS = "IN_PROGRESS_OPS",
  IN_PROGRESS_TAX_CONSULTANT = "IN_PROGRESS_TAX_CONSULTANT",
  IN_SUBMISSION_FOR_USER_APPROVAL = "IN_SUBMISSION_FOR_USER_APPROVAL",
  NOT_RELEVANT = "NOT_RELEVANT",
  OBJECTED_BY_FINANZAMT = "OBJECTED_BY_FINANZAMT",
  OBJECTED_BY_TAX_CONSULTANT = "OBJECTED_BY_TAX_CONSULTANT",
  OBJECTED_BY_USER = "OBJECTED_BY_USER",
  OPEN = "OPEN",
  RECEIVED_TAX_BILL = "RECEIVED_TAX_BILL",
  SUBMITTED = "SUBMITTED",
  WAITING_FOR_USER_APPROVAL = "WAITING_FOR_USER_APPROVAL",
}

export type IncomeTaxDeclarationStatusChange = {
  __typename?: "IncomeTaxDeclarationStatusChange";
  changedAt: Scalars["DateTime"];
  changedBy?: Maybe<Scalars["String"]>;
  status: IncomeTaxDeclarationStatus;
};

export enum InvoiceStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/** Kontax user's accounting source for a specific year */
export type KontaxAccountingSource = {
  __typename?: "KontaxAccountingSource";
  id: Scalars["ID"];
  type: KontaxAccountingSourceType;
  year: Scalars["Int"];
};

export enum KontaxAccountingSourceType {
  COMPLETELY_IN_KONTIST = "COMPLETELY_IN_KONTIST",
  EXTERNAL_MANUAL = "EXTERNAL_MANUAL",
  EXTERNAL_VIA_LEXOFFICE_DEBITOR_SEVDESK = "EXTERNAL_VIA_LEXOFFICE_DEBITOR_SEVDESK",
  EXTERNAL_VIA_VORBERATER = "EXTERNAL_VIA_VORBERATER",
  PARTIALLY_IN_KONTIST_AND_MANUAL = "PARTIALLY_IN_KONTIST_AND_MANUAL",
  PARTIALLY_IN_KONTIST_AND_VIA_LEXOFFICE_DEBITOR_SEVDESK = "PARTIALLY_IN_KONTIST_AND_VIA_LEXOFFICE_DEBITOR_SEVDESK",
  PARTIALLY_IN_KONTIST_AND_VIA_VORBERATER = "PARTIALLY_IN_KONTIST_AND_VIA_VORBERATER",
}

/** Kontax user's notes */
export type KontaxNote = {
  __typename?: "KontaxNote";
  createdAt: Scalars["DateTime"];
  creator: Scalars["String"];
  id: Scalars["Int"];
  message: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

/** The available fields to create KontaxNote */
export type KontaxNoteInput = {
  id?: InputMaybe<Scalars["Int"]>;
  message: Scalars["String"];
  recordId?: InputMaybe<Scalars["ID"]>;
  type?: InputMaybe<KontaxNoteType>;
};

export enum KontaxNoteType {
  EUER_DECLARATION = "EUER_DECLARATION",
  EUER_DECLARATION_SUBFORM = "EUER_DECLARATION_SUBFORM",
  INCOME_TAX_DECLARATION = "INCOME_TAX_DECLARATION",
  TAX_CASE = "TAX_CASE",
  TRADE_TAX_DECLARATION = "TRADE_TAX_DECLARATION",
  VAT = "VAT",
  VAT_ANNUAL_DECLARATION = "VAT_ANNUAL_DECLARATION",
}

export type KontaxTransaction = {
  __typename?: "KontaxTransaction";
  actionReason?: Maybe<Scalars["String"]>;
  amount: Scalars["Float"];
  assets: Array<AssetData>;
  businessAssets: Array<BusinessAsset>;
  businessTypeComment?: Maybe<Scalars["String"]>;
  categoryCode?: Maybe<Scalars["String"]>;
  categoryCodeMeta?: Maybe<ValueMeta>;
  description?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  escalated?: Maybe<Scalars["Boolean"]>;
  escalationNote?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  foreignCurrency?: Maybe<Scalars["String"]>;
  hasIntegrationDocument?: Maybe<Scalars["Boolean"]>;
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  internalNote?: Maybe<Scalars["String"]>;
  invoiceRequestedAt?: Maybe<Scalars["String"]>;
  isSplitCategorized?: Maybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
  merchantCategoryCode?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  personalNote?: Maybe<Scalars["String"]>;
  purpose?: Maybe<Scalars["String"]>;
  recurringPaymentRuleId?: Maybe<Scalars["Float"]>;
  source: Scalars["String"];
  splits: Array<Split>;
  submittedCategoryCode?: Maybe<Scalars["String"]>;
  submittedVatCategoryCode?: Maybe<VatCategoryCode>;
  type: Scalars["String"];
  valutaDate: Scalars["String"];
  vatCategoryCode?: Maybe<Scalars["String"]>;
  vatCategoryCodeMeta?: Maybe<ValueMeta>;
  vatYearPaymentFrequency?: Maybe<PaymentFrequency>;
  verified: Scalars["Boolean"];
  verifiedAt?: Maybe<Scalars["DateTime"]>;
  verifiedBy?: Maybe<Scalars["String"]>;
};

export type KontaxTransactionForAdmin = {
  __typename?: "KontaxTransactionForAdmin";
  actionReason?: Maybe<Scalars["String"]>;
  amount: Scalars["Float"];
  assets: Array<AssetData>;
  businessAssets: Array<BusinessAsset>;
  businessTypeComment?: Maybe<Scalars["String"]>;
  categoryCode?: Maybe<Scalars["String"]>;
  categoryCodeMeta?: Maybe<ValueMeta>;
  description?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  escalated?: Maybe<Scalars["Boolean"]>;
  escalationNote?: Maybe<Scalars["String"]>;
  fibuCategoryComment?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  foreignCurrency?: Maybe<Scalars["String"]>;
  hasIntegrationDocument?: Maybe<Scalars["Boolean"]>;
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  internalNote?: Maybe<Scalars["String"]>;
  invoiceRequestedAt?: Maybe<Scalars["String"]>;
  isSplitCategorized?: Maybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
  merchantCategoryCode?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  personalNote?: Maybe<Scalars["String"]>;
  purpose?: Maybe<Scalars["String"]>;
  recurringPaymentRuleId?: Maybe<Scalars["Float"]>;
  source: Scalars["String"];
  splits: Array<Split>;
  submittedCategoryCode?: Maybe<Scalars["String"]>;
  submittedVatCategoryCode?: Maybe<VatCategoryCode>;
  taxAdvisorProof?: Maybe<TaxAdvisorProof>;
  type: Scalars["String"];
  valutaDate: Scalars["String"];
  vatCategoryCode?: Maybe<Scalars["String"]>;
  vatCategoryCodeMeta?: Maybe<ValueMeta>;
  vatCategoryComment?: Maybe<Scalars["String"]>;
  vatYearPaymentFrequency?: Maybe<PaymentFrequency>;
  verified: Scalars["Boolean"];
  verifiedAt?: Maybe<Scalars["DateTime"]>;
  verifiedBy?: Maybe<Scalars["String"]>;
};

export type KontaxTransactionUpsertInput = {
  categoryCode?: InputMaybe<Scalars["String"]>;
  categoryCodeSuggestionSource?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["Float"]>;
  escalated?: InputMaybe<Scalars["Boolean"]>;
  escalationNote?: InputMaybe<Scalars["String"]>;
  internalNote?: InputMaybe<Scalars["String"]>;
  invoiceRequestedAt?: InputMaybe<Scalars["DateTime"]>;
  kontistTransactionId: Scalars["String"];
  vatCategoryCode?: InputMaybe<Scalars["String"]>;
  verified?: InputMaybe<Scalars["Boolean"]>;
};

export type KontaxTransactionsConnection = {
  __typename?: "KontaxTransactionsConnection";
  edges: Array<KontaxTransactionsConnectionEdge>;
  pageInfo: PageInfo;
};

export type KontaxTransactionsConnectionEdge = {
  __typename?: "KontaxTransactionsConnectionEdge";
  cursor: Scalars["String"];
  node: KontaxTransaction;
};

export type KontaxTransactionsResponse = {
  __typename?: "KontaxTransactionsResponse";
  transactions: Array<KontaxTransaction>;
};

export type KontoabfrageInput = {
  __typename?: "KontoabfrageInput";
  kontoabfrageArt?: Maybe<Array<Scalars["String"]>>;
  steuerart?: Maybe<Array<Steuerart>>;
  steuernummer?: Maybe<Array<Scalars["String"]>>;
  zeitraum?: Maybe<Array<Scalars["String"]>>;
};

export type KontoabfrageOutput = {
  __typename?: "KontoabfrageOutput";
  abfrageErlaeuterung?: Maybe<Array<Scalars["String"]>>;
  abfrageGesamtsumme?: Maybe<Array<Scalars["String"]>>;
  abfrageSteuerart?: Maybe<Array<AbfrageSteuerart>>;
  abfrageSteuernummer?: Maybe<Array<Scalars["String"]>>;
  abfrageTagesdatum?: Maybe<Array<Scalars["String"]>>;
};

export type Municipality = {
  __typename?: "Municipality";
  name: Scalars["String"];
  taxRate: Scalars["Float"];
};

export type Mutation = {
  __typename?: "Mutation";
  addAnnotation: Annotation;
  /** Add business asset to a transaction */
  addBusinessAssetToTransaction: KontaxTransaction;
  /** Approve or reject an anschreiben for an user */
  approveOrRejectAnschreiben: Anschreiben;
  /** Assign taxCase to back office user */
  assignTaxCase: TaxBoardTaxCase;
  /** Create an anschreiben for an user */
  createAnschreiben: Anschreiben;
  createAutocategorizationRule: CreateAutocategorizationRulePayload;
  /** Creates an user's business address */
  createBusinessAddress: BusinessAddress;
  createDeclarationDocument: DeclarationDocument;
  createDeclarationDocumentAsset: CreateAssetResponse;
  /** Create an asset for EUER declaration subform and obtain an upload config */
  createEuerDeclarationSubformAsset: CreateAssetResponse;
  createHomeOfficeDocumentAsset: CreateAssetResponse;
  createHomeOfficeExpenseAsset: CreateAssetResponse;
  createRecurringPayment: AutocategorizationRule;
  createRetroactiveKontaxFee: RetroactiveKontaxFee;
  /** Create user tax number */
  createTaxNumber: TaxNumber;
  createUsageStat: UsageStat;
  deleteAnnotation: MutationResult;
  /** Remove an Asset */
  deleteAsset: MutationResult;
  /** Deletes the autocategorization rule */
  deleteAutocategorizationRule: MutationResult;
  /** Delete an user's business address */
  deleteBusinessAddress: MutationResult;
  /** Delete a business asset */
  deleteBusinessAsset: MutationResult;
  deleteDeclarationDocument: MutationResult;
  /** Deletes document */
  deleteDocument: MutationResult;
  deleteEmailDocument: MutationResult;
  deleteHomeOfficeExpense: MutationResult;
  deleteKontaxNote: MutationResult;
  deleteRecurringPayment: MutationResult;
  /** Delete user tax number */
  deleteTaxNumber: MutationResult;
  deleteTaxService: MutationResult;
  /** Deletes a user's dependent */
  deleteUserDependent: MutationResult;
  /** Export anschreiben */
  exportAnschreiben: MutationResult;
  exportDocumentInputs: MutationResult;
  /** Confirm and validate an Asset upload as completed */
  finalizeAssetUpload: Asset;
  /** Import DATEV data from CSV */
  importDatevData: MutationResult;
  matchEmailDocumentToTransaction: MutationResult;
  /** Remove accounting source for a particular year */
  removeAccountingSource: MutationResult;
  /** Remove anschreiben */
  removeAnschreiben: MutationResult;
  /** Delete some or all DATEV data of a user */
  removeDatevData: DeleteDatevDataResult;
  /** Get EUER draft PDF and Save it */
  saveEuerDraftPdf: EuerProcessingResponse;
  saveTradeTaxDeclarationDraftPdf: TradeTaxDeclarationProcessResult;
  saveVatAnnualDeclarationDraftPdf: VatAnnualDeclarationProcessResult;
  /** Send anschreiben to client */
  sendAnschreiben: MutationResult;
  /** Sets a reason to transactions meta which requires user's action */
  setActionReason: MutationResult;
  /** Submit EUER declaration to ELSTER */
  submitEuer: EuerProcessingResponse;
  submitTradeTaxDeclaration: TradeTaxDeclarationProcessResult;
  submitVatAnnualDeclaration: VatAnnualDeclarationProcessResult;
  /** Switch between account and offsetAccount of a transaction */
  switchDatevDataAccountField: Array<DatevImport>;
  /** Verify DATEV import data by toggling verifiedAt flag on or off */
  toggleDatevDataVerifiedFlag: DatevImport;
  /** Verify multiple DATEV import data by toggling verifiedAt flag on or off */
  toggleMultipleDatevDataVerifiedFlag: Array<DatevImport>;
  /** Unassign taxCase from back office user */
  unassignTaxCase: TaxBoardTaxCase;
  /** Update an anschreiben for an user */
  updateAnschreiben: Anschreiben;
  updateAutocategorizationRule: UpdateAutocategorizationRulePayload;
  /** Updates an user's business address */
  updateBusinessAddress: BusinessAddress;
  /** Update DATEV import data */
  updateDatevData: DatevImport;
  /** Update EUER declaration */
  updateEuerDeclaration: EuerDeclaration;
  /** Update EUER declaration subform */
  updateEuerDeclarationSubform: EuerDeclarationSubform;
  updateIncomeTaxDeclaration: IncomeTaxDeclaration;
  /** Update multiple DATEV import data */
  updateMultipleDatevData: Array<DatevImport>;
  /** Update taxCase */
  updateTaxCase: MutationResult;
  /** Update user tax number */
  updateTaxNumber: TaxNumber;
  updateTradeTaxDeclaration: TradeTaxDeclaration;
  /** Allow tax supervisor to update feedback for a 'Verified By ML' transaction */
  updateTransactionFeedback: KontaxTransactionForAdmin;
  updateVatAnnualDeclaration: VatAnnualDeclaration;
  /** Upsert accounting source for a particular year */
  upsertAccountingSource: KontaxAccountingSource;
  upsertAnnotationTag: AnnotationTag;
  /** Upsert an anschreiben template */
  upsertAnschreibenTemplate: AnschreibenTemplate;
  /** Upserts external transaction */
  upsertExternalTransaction: KontaxTransaction;
  upsertHomeOfficeDocument: HomeOfficeDocument;
  upsertHomeOfficeExpense: HomeOfficeExpense;
  upsertKontaxNote: KontaxNote;
  /** Upsert kontax transactions and receive updated transactions in the response */
  upsertKontaxTransactions: KontaxTransactionsResponse;
  upsertTaxService: TaxService;
};

export type MutationAddAnnotationArgs = {
  payload: AnnotationValueInput;
};

export type MutationAddBusinessAssetToTransactionArgs = {
  businessAssetForm: BusinessAssetForm;
  categoryCode: Scalars["String"];
  transactionId: Scalars["String"];
  vatCategoryCode: Scalars["String"];
};

export type MutationApproveOrRejectAnschreibenArgs = {
  email: Scalars["String"];
  id: Scalars["String"];
  isApproved: Scalars["Boolean"];
};

export type MutationAssignTaxCaseArgs = {
  taxCaseId: Scalars["ID"];
};

export type MutationCreateAnschreibenArgs = {
  email: Scalars["String"];
  payload: CreateAnschreibenInput;
};

export type MutationCreateAutocategorizationRuleArgs = {
  input: CreateAutocategorizationRuleInput;
};

export type MutationCreateBusinessAddressArgs = {
  email: Scalars["String"];
  payload: CreateBusinessAddressInput;
};

export type MutationCreateDeclarationDocumentArgs = {
  declarationId: Scalars["ID"];
  declarationType: TaxDeclarationType;
  documentType: DeclarationDocumentType;
};

export type MutationCreateDeclarationDocumentAssetArgs = {
  declarationDocumentId: Scalars["ID"];
  filetype: Scalars["String"];
  name: Scalars["String"];
};

export type MutationCreateEuerDeclarationSubformAssetArgs = {
  euerDeclarationSubformId: Scalars["ID"];
  filetype: Scalars["String"];
  name: Scalars["String"];
};

export type MutationCreateHomeOfficeDocumentAssetArgs = {
  filetype: Scalars["String"];
  homeOfficeDocumentId: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationCreateHomeOfficeExpenseAssetArgs = {
  filetype: Scalars["String"];
  homeOfficeExpenseId: Scalars["ID"];
  name: Scalars["String"];
};

export type MutationCreateRecurringPaymentArgs = {
  transactionId: Scalars["ID"];
};

export type MutationCreateRetroactiveKontaxFeeArgs = {
  email: Scalars["String"];
  payload: CreateRetroactiveKontaxFeeInput;
};

export type MutationCreateTaxNumberArgs = {
  email: Scalars["String"];
  payload: CreateTaxNumberInput;
};

export type MutationCreateUsageStatArgs = {
  payload: CreateUsageStatInput;
};

export type MutationDeleteAnnotationArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAssetArgs = {
  assetId: Scalars["ID"];
};

export type MutationDeleteAutocategorizationRuleArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteBusinessAddressArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteBusinessAssetArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteDeclarationDocumentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteDocumentArgs = {
  email: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationDeleteEmailDocumentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteHomeOfficeExpenseArgs = {
  homeOfficeExpenseId: Scalars["ID"];
};

export type MutationDeleteKontaxNoteArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteRecurringPaymentArgs = {
  transactionId: Scalars["ID"];
};

export type MutationDeleteTaxNumberArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTaxServiceArgs = {
  taxServiceId: Scalars["String"];
};

export type MutationDeleteUserDependentArgs = {
  id: Scalars["ID"];
};

export type MutationExportAnschreibenArgs = {
  email: Scalars["String"];
  id: Scalars["String"];
};

export type MutationExportDocumentInputsArgs = {
  email: Scalars["String"];
  type: QuestionnaireType;
  year: Scalars["Int"];
};

export type MutationFinalizeAssetUploadArgs = {
  assetId: Scalars["ID"];
};

export type MutationImportDatevDataArgs = {
  payload: ImportDatevDataInput;
};

export type MutationMatchEmailDocumentToTransactionArgs = {
  email: Scalars["String"];
  emailDocumentId: Scalars["ID"];
  transactionId: Scalars["ID"];
};

export type MutationRemoveAccountingSourceArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type MutationRemoveAnschreibenArgs = {
  email: Scalars["String"];
  id: Scalars["String"];
};

export type MutationRemoveDatevDataArgs = {
  email: Scalars["String"];
  ids?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MutationSaveEuerDraftPdfArgs = {
  payload: EuerProcessingRequest;
};

export type MutationSaveTradeTaxDeclarationDraftPdfArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type MutationSaveVatAnnualDeclarationDraftPdfArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type MutationSendAnschreibenArgs = {
  email: Scalars["String"];
  id: Scalars["String"];
};

export type MutationSetActionReasonArgs = {
  reason: ActionReason;
  transactionId: Scalars["String"];
};

export type MutationSubmitEuerArgs = {
  payload: EuerProcessingRequest;
};

export type MutationSubmitTradeTaxDeclarationArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type MutationSubmitVatAnnualDeclarationArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type MutationSwitchDatevDataAccountFieldArgs = {
  ids: Array<Scalars["ID"]>;
};

export type MutationToggleDatevDataVerifiedFlagArgs = {
  id: Scalars["ID"];
};

export type MutationToggleMultipleDatevDataVerifiedFlagArgs = {
  payload: Array<Scalars["ID"]>;
};

export type MutationUnassignTaxCaseArgs = {
  taxCaseId: Scalars["ID"];
};

export type MutationUpdateAnschreibenArgs = {
  email: Scalars["String"];
  payload: UpdateAnschreibenInput;
};

export type MutationUpdateAutocategorizationRuleArgs = {
  input: UpdateAutocategorizationRuleInput;
};

export type MutationUpdateBusinessAddressArgs = {
  payload: UpdateBusinessAddressInput;
};

export type MutationUpdateDatevDataArgs = {
  payload: UpdateDatevDataInput;
};

export type MutationUpdateEuerDeclarationArgs = {
  email: Scalars["String"];
  payload: UpdateEuerDeclarationInput;
  year: Scalars["Int"];
};

export type MutationUpdateEuerDeclarationSubformArgs = {
  email: Scalars["String"];
  payload: UpdateEuerDeclarationSubformInput;
  type: EuerDeclarationSubformType;
  year: Scalars["Int"];
};

export type MutationUpdateIncomeTaxDeclarationArgs = {
  email: Scalars["String"];
  payload: UpdateIncomeTaxDeclarationInput;
  year: Scalars["Int"];
};

export type MutationUpdateMultipleDatevDataArgs = {
  payload: Array<UpdateDatevDataInput>;
};

export type MutationUpdateTaxCaseArgs = {
  payload: UpdateTaxCasePayload;
};

export type MutationUpdateTaxNumberArgs = {
  id: Scalars["ID"];
  payload: UpdateTaxNumberInputWithNoTaxNumber;
};

export type MutationUpdateTradeTaxDeclarationArgs = {
  email: Scalars["String"];
  payload: UpdateTradeTaxDeclarationInput;
  year: Scalars["Int"];
};

export type MutationUpdateTransactionFeedbackArgs = {
  feedback: TransactionFeedback;
  transactionId: Scalars["String"];
};

export type MutationUpdateVatAnnualDeclarationArgs = {
  email: Scalars["String"];
  payload: UpdateVatAnnualDeclarationInput;
  year: Scalars["Int"];
};

export type MutationUpsertAccountingSourceArgs = {
  email: Scalars["String"];
  payload: UpsertAccountingSourceInput;
};

export type MutationUpsertAnnotationTagArgs = {
  payload: AnnotationTagInput;
};

export type MutationUpsertAnschreibenTemplateArgs = {
  payload: UpsertAnschreibenTemplateInput;
};

export type MutationUpsertExternalTransactionArgs = {
  email: Scalars["String"];
  options?: InputMaybe<UpsertExternalTransactionOptions>;
  transaction: ExternalTransactionInput;
};

export type MutationUpsertHomeOfficeDocumentArgs = {
  email: Scalars["String"];
  payload: HomeOfficeDocumentInput;
  year: Scalars["Int"];
};

export type MutationUpsertHomeOfficeExpenseArgs = {
  email: Scalars["String"];
  payload: HomeOfficeExpenseInput;
  year: Scalars["Int"];
};

export type MutationUpsertKontaxNoteArgs = {
  email: Scalars["String"];
  payload: KontaxNoteInput;
};

export type MutationUpsertKontaxTransactionsArgs = {
  transactions: Array<KontaxTransactionUpsertInput>;
};

export type MutationUpsertTaxServiceArgs = {
  email: Scalars["String"];
  payload: UpsertTaxServiceInput;
};

export type MutationResult = {
  __typename?: "MutationResult";
  success: Scalars["Boolean"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor?: Maybe<Scalars["String"]>;
};

export enum PaymentFrequency {
  MONTHLY = "MONTHLY",
  NONE = "NONE",
  NONE_QUARTERLY = "NONE_QUARTERLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
}

export type Query = {
  __typename?: "Query";
  BWAStats: Array<BwaStatsCategory>;
  accountStats: AccountStats;
  annotation?: Maybe<AnnotationOutput>;
  annotationTags: Array<AnnotationTag>;
  /** Query an anschreiben for an user by year */
  anschreiben?: Maybe<Anschreiben>;
  /** Query an anschreiben template by type, language, and year */
  anschreibenTemplate?: Maybe<AnschreibenTemplate>;
  autocategorizationRule?: Maybe<AutocategorizationRule>;
  availableVatAdvanceDeclarationPeriods: Array<Scalars["String"]>;
  businessAddress: BusinessAddress;
  businessAddressMovement?: Maybe<BusinessAddressMovement>;
  businessAddresses?: Maybe<Array<BusinessAddress>>;
  businessAssets: Array<BusinessAssetResponse>;
  changeLogs: Array<ChangeLog>;
  /** Get DATEV data for a user */
  datevData: Array<DatevImport>;
  docuWareDocuments: DocuWareDocumentResponse;
  documentCategories: Array<DocumentCategory>;
  documentCount: DocumentCountResponse;
  documents: Array<Document>;
  emailDocument?: Maybe<EmailDocument>;
  emailDocuments: Array<EmailDocument>;
  euerDeclaration: EuerDeclaration;
  euerDeclarationSubform?: Maybe<EuerDeclarationSubform>;
  euerDeclarationSubforms: Array<EuerDeclarationSubform>;
  fibuFinalCheckTasks?: Maybe<Array<FibuFinalCheckTask>>;
  /** Get EUER draft PDF */
  getEuerDraftPdf: EuerProcessingResponse;
  getNotes?: Maybe<Array<KontaxNote>>;
  getTaxAccount: TaxAccount;
  incomeTaxDeclaration: IncomeTaxDeclaration;
  municipalities: Array<Municipality>;
  questionnaire?: Maybe<Questionnaire>;
  questionnaires: Array<Questionnaire>;
  retroactiveKontaxFees: Array<RetroactiveKontaxFee>;
  systemUsageStats: Array<SystemUsageStat>;
  taxBoardUsers: TaxBoardUserDataAndCount;
  taxCase: TaxBoardTaxCase;
  taxNumbers: Array<TaxNumber>;
  taxServices: Array<TaxServiceDetails>;
  tradeTaxDeclaration: TradeTaxDeclaration;
  /** Retrieve the latest 'Verified By ML' transaction for tax supervisor */
  transactionVerifiedByML?: Maybe<KontaxTransactionForAdmin>;
  transactions: KontaxTransactionsConnection;
  /** Retrieve all 'Verified By ML' transactions */
  transactionsVerifiedByML: TransactionsVerifiedByMl;
  usageStats: UsageStatDetailsAndCount;
  vatAnnualDeclaration: VatAnnualDeclaration;
};

export type QueryBwaStatsArgs = {
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  email: Scalars["String"];
};

export type QueryAccountStatsArgs = {
  accountId: Scalars["Int"];
  email: Scalars["String"];
};

export type QueryAnnotationArgs = {
  transactionId: Scalars["ID"];
};

export type QueryAnschreibenArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryAnschreibenTemplateArgs = {
  language: Scalars["String"];
  type: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryAutocategorizationRuleArgs = {
  id: Scalars["Int"];
};

export type QueryAvailableVatAdvanceDeclarationPeriodsArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryBusinessAddressArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryBusinessAddressMovementArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryBusinessAddressesArgs = {
  email: Scalars["String"];
};

export type QueryBusinessAssetsArgs = {
  email: Scalars["String"];
};

export type QueryChangeLogsArgs = {
  modelName: Scalars["String"];
  recordIds: Array<Scalars["String"]>;
};

export type QueryDatevDataArgs = {
  email: Scalars["String"];
};

export type QueryDocuWareDocumentsArgs = {
  accountId: Scalars["Int"];
};

export type QueryDocumentCategoriesArgs = {
  categoryNames?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryDocumentCountArgs = {
  userId: Scalars["Int"];
  year: Scalars["Int"];
};

export type QueryDocumentsArgs = {
  categoryIds?: InputMaybe<Array<Scalars["String"]>>;
  email: Scalars["String"];
  year?: InputMaybe<Scalars["Int"]>;
};

export type QueryEmailDocumentArgs = {
  email: Scalars["String"];
  id?: InputMaybe<Scalars["String"]>;
  uploadSources?: InputMaybe<Array<DocumentUploadSource>>;
};

export type QueryEmailDocumentsArgs = {
  email: Scalars["String"];
  uploadSources?: InputMaybe<Array<DocumentUploadSource>>;
};

export type QueryEuerDeclarationArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryEuerDeclarationSubformArgs = {
  email: Scalars["String"];
  type: EuerDeclarationSubformType;
  year: Scalars["Int"];
};

export type QueryEuerDeclarationSubformsArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryFibuFinalCheckTasksArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryGetEuerDraftPdfArgs = {
  payload: EuerProcessingRequest;
};

export type QueryGetNotesArgs = {
  email: Scalars["String"];
};

export type QueryGetTaxAccountArgs = {
  requestType: Scalars["String"];
  state: Scalars["String"];
  taxNumber: Scalars["String"];
  taxType: Scalars["String"];
  valueDate?: InputMaybe<Scalars["String"]>;
  valueDateOption?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["Int"]>;
};

export type QueryIncomeTaxDeclarationArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryQuestionnaireArgs = {
  email: Scalars["String"];
  questionnaireId?: InputMaybe<Scalars["ID"]>;
  type: QuestionnaireType;
  year: Scalars["Int"];
};

export type QueryQuestionnairesArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryRetroactiveKontaxFeesArgs = {
  email: Scalars["String"];
};

export type QueryTaxBoardUsersArgs = {
  filter?: InputMaybe<TaxBoardUsersFilterArgs>;
  limit: Scalars["Int"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  orderDirection?: InputMaybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export type QueryTaxCaseArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryTaxNumbersArgs = {
  email: Scalars["String"];
};

export type QueryTaxServicesArgs = {
  filter?: InputMaybe<TaxServicesFilterArgs>;
};

export type QueryTradeTaxDeclarationArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryTransactionsVerifiedByMlArgs = {
  pageSize: Scalars["Int"];
  selectedPage: Scalars["Int"];
};

export type QueryUsageStatsArgs = {
  filter?: InputMaybe<UsageStatsFilterArgs>;
  limit: Scalars["Int"];
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  orderDirection?: InputMaybe<Scalars["String"]>;
};

export type QueryVatAnnualDeclarationArgs = {
  email: Scalars["String"];
  year: Scalars["Int"];
};

export type Questionnaire = {
  __typename?: "Questionnaire";
  answers: Array<QuestionnaireAnswer>;
  assetsArchive: Scalars["String"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  context?: Maybe<Scalars["JSON"]>;
  documents: Array<QuestionnaireDocument>;
  id: Scalars["ID"];
  lastAnswer?: Maybe<QuestionnaireAnswer>;
  nextQuestion?: Maybe<QuestionnaireQuestion>;
  questions: Array<QuestionnaireQuestionWithAnswer>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status: QuestionnaireStatus;
  syncedAt?: Maybe<Scalars["DateTime"]>;
  type: QuestionnaireType;
  year: Scalars["Int"];
};

export type QuestionnaireAnswersArgs = {
  questionNames?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuestionnaireNextQuestionArgs = {
  includePostponed?: InputMaybe<Scalars["Boolean"]>;
};

export type QuestionnaireAnswer = {
  __typename?: "QuestionnaireAnswer";
  documentsStatus: QuestionnaireAnswerDocumentsStatus;
  postponedAt?: Maybe<Scalars["DateTime"]>;
  questionName: Scalars["String"];
  submittedAt?: Maybe<Scalars["DateTime"]>;
  syncedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  value?: Maybe<Scalars["JSON"]>;
};

export enum QuestionnaireAnswerDocumentsStatus {
  DELETED = "DELETED",
  NOT_REQUIRED = "NOT_REQUIRED",
  PENDING = "PENDING",
  UPLOADED = "UPLOADED",
}

export type QuestionnaireDocument = {
  __typename?: "QuestionnaireDocument";
  assets: Array<Asset>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  inputs?: Maybe<Scalars["JSON"]>;
  isLastYearSuggestion: Scalars["Boolean"];
  syncedAt?: Maybe<Scalars["DateTime"]>;
  type: QuestionnaireDocumentType;
  updatedAt: Scalars["DateTime"];
};

export enum QuestionnaireDocumentType {
  BIZ_TAX_CAR_USAGE_LOGBOOK = "BIZ_TAX_CAR_USAGE_LOGBOOK",
  BIZ_TAX_CAR_USAGE_PURCHASE_CONTRACT = "BIZ_TAX_CAR_USAGE_PURCHASE_CONTRACT",
  BIZ_TAX_CAR_USAGE_TRAVELED_KM_WITH_PRIVATE_CAR = "BIZ_TAX_CAR_USAGE_TRAVELED_KM_WITH_PRIVATE_CAR",
  BIZ_TAX_OFFICE_USAGE_ELECTRICITY = "BIZ_TAX_OFFICE_USAGE_ELECTRICITY",
  BIZ_TAX_OFFICE_USAGE_FLOOR_PLAN = "BIZ_TAX_OFFICE_USAGE_FLOOR_PLAN",
  BIZ_TAX_OFFICE_USAGE_HEATING = "BIZ_TAX_OFFICE_USAGE_HEATING",
  BIZ_TAX_OFFICE_USAGE_OTHER = "BIZ_TAX_OFFICE_USAGE_OTHER",
  BIZ_TAX_OFFICE_USAGE_PHONE_OR_INTERNET = "BIZ_TAX_OFFICE_USAGE_PHONE_OR_INTERNET",
  BIZ_TAX_OFFICE_USAGE_RENT_OR_INTEREST = "BIZ_TAX_OFFICE_USAGE_RENT_OR_INTEREST",
  BIZ_TAX_OFFICE_USAGE_UTILITY = "BIZ_TAX_OFFICE_USAGE_UTILITY",
  BIZ_TAX_OFFICE_USAGE_UTILITY_AFTER_PAYMENT = "BIZ_TAX_OFFICE_USAGE_UTILITY_AFTER_PAYMENT",
  BIZ_TAX_TRAVEL_EXPENSES_BUSINESS_TRIPS = "BIZ_TAX_TRAVEL_EXPENSES_BUSINESS_TRIPS",
  EOY_CAR_USAGE_LOGBOOK = "EOY_CAR_USAGE_LOGBOOK",
  EOY_CAR_USAGE_OTHER = "EOY_CAR_USAGE_OTHER",
  EOY_CAR_USAGE_PRIVATELY_PAID_CAR_EXPENSES = "EOY_CAR_USAGE_PRIVATELY_PAID_CAR_EXPENSES",
  EOY_CAR_USAGE_PURCHASE_CONTRACT = "EOY_CAR_USAGE_PURCHASE_CONTRACT",
  EOY_CAR_USAGE_TRAVELED_KM_WITH_PRIVATE_CAR = "EOY_CAR_USAGE_TRAVELED_KM_WITH_PRIVATE_CAR",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_ADDL_SELF_EMPLOYMENT = "EOY_INCOME_TAX_ADDITIONAL_INCOME_ADDL_SELF_EMPLOYMENT",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_CAPITAL_ASSETS_INTL = "EOY_INCOME_TAX_ADDITIONAL_INCOME_CAPITAL_ASSETS_INTL",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_CRYPTO = "EOY_INCOME_TAX_ADDITIONAL_INCOME_CRYPTO",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_EMPLOYED_WORK = "EOY_INCOME_TAX_ADDITIONAL_INCOME_EMPLOYED_WORK",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_EMPLOYMENT_EXPENSES = "EOY_INCOME_TAX_ADDITIONAL_INCOME_EMPLOYMENT_EXPENSES",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_INTERNATIONAL_INCOME = "EOY_INCOME_TAX_ADDITIONAL_INCOME_INTERNATIONAL_INCOME",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_OTHER = "EOY_INCOME_TAX_ADDITIONAL_INCOME_OTHER",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_ADDL_SELF_EMPLOYMENT = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_ADDL_SELF_EMPLOYMENT",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_CAPITAL_ASSETS_INTL = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_CAPITAL_ASSETS_INTL",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_CRYPTO = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_CRYPTO",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_EMPLOYED_WORK = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_EMPLOYED_WORK",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_EMPLOYMENT_EXPENSES = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_EMPLOYMENT_EXPENSES",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_INTERNATIONAL_INCOME = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_INTERNATIONAL_INCOME",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_OTHER = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_OTHER",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_PENSIONS = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_PENSIONS",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_SALE_OF_PROPERTY = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER_SALE_OF_PROPERTY",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PENSIONS = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PENSIONS",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_SALE_OF_PROPERTY = "EOY_INCOME_TAX_ADDITIONAL_INCOME_SALE_OF_PROPERTY",
  EOY_INCOME_TAX_BASIC_DATA_OTHER = "EOY_INCOME_TAX_BASIC_DATA_OTHER",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_OTHER = "EOY_INCOME_TAX_BASIC_DATA_PARTNER_OTHER",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER_PROOF_OF_DISABILITY = "EOY_INCOME_TAX_BASIC_DATA_PARTNER_PROOF_OF_DISABILITY",
  EOY_INCOME_TAX_BASIC_DATA_PROOF_OF_DISABILITY = "EOY_INCOME_TAX_BASIC_DATA_PROOF_OF_DISABILITY",
  EOY_INCOME_TAX_BASIC_DATA_RENTAL_AND_LEASE = "EOY_INCOME_TAX_BASIC_DATA_RENTAL_AND_LEASE",
  EOY_INCOME_TAX_CHILD_ADDITIONAL_HEALTH_INSURANCE = "EOY_INCOME_TAX_CHILD_ADDITIONAL_HEALTH_INSURANCE",
  EOY_INCOME_TAX_CHILD_CHILDCARE = "EOY_INCOME_TAX_CHILD_CHILDCARE",
  EOY_INCOME_TAX_CHILD_DISABILITY_COSTS = "EOY_INCOME_TAX_CHILD_DISABILITY_COSTS",
  EOY_INCOME_TAX_CHILD_EXTENSIVE_MEDICAL_EXPENSES = "EOY_INCOME_TAX_CHILD_EXTENSIVE_MEDICAL_EXPENSES",
  EOY_INCOME_TAX_CHILD_OTHER = "EOY_INCOME_TAX_CHILD_OTHER",
  EOY_INCOME_TAX_CHILD_PROOF_OF_DISABILITY = "EOY_INCOME_TAX_CHILD_PROOF_OF_DISABILITY",
  EOY_INCOME_TAX_CHILD_SCHOOL_FEES = "EOY_INCOME_TAX_CHILD_SCHOOL_FEES",
  EOY_INCOME_TAX_CHILD_UNIVERSITY_FEES = "EOY_INCOME_TAX_CHILD_UNIVERSITY_FEES",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_ACCIDENT_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_ACCIDENT_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_ALIMENTS = "EOY_INCOME_TAX_PRIVATE_EXPENSES_ALIMENTS",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_DISABILITY_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_DISABILITY_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_EXTRAORDINARY_BURDENS = "EOY_INCOME_TAX_PRIVATE_EXPENSES_EXTRAORDINARY_BURDENS",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_HEALTH_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_HEALTH_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_HOUSEHOLD_SERVICES = "EOY_INCOME_TAX_PRIVATE_EXPENSES_HOUSEHOLD_SERVICES",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_LIFE_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_LIFE_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_OTHER = "EOY_INCOME_TAX_PRIVATE_EXPENSES_OTHER",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_ACCIDENT_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_ACCIDENT_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_ALIMENTS = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_ALIMENTS",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_DISABILITY_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_DISABILITY_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_EXTRAORDINARY_BURDENS = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_EXTRAORDINARY_BURDENS",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_HEALTH_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_HEALTH_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_HOUSEHOLD_SERVICES = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_HOUSEHOLD_SERVICES",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_LIFE_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_LIFE_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_OTHER = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_OTHER",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_PENSION_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_PENSION_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_PRIVATE_DONATIONS = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_PRIVATE_DONATIONS",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_REISTER = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_REISTER",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_RURUP = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_RURUP",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_UNEMPLOYMENT_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_UNEMPLOYMENT_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_UNIVERSITY_FEES = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_UNIVERSITY_FEES",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_VEHICLE_LIABILITY = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER_VEHICLE_LIABILITY",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PENSION_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PENSION_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PRIVATE_DONATIONS = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PRIVATE_DONATIONS",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_REISTER = "EOY_INCOME_TAX_PRIVATE_EXPENSES_REISTER",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_RURUP = "EOY_INCOME_TAX_PRIVATE_EXPENSES_RURUP",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_UNEMPLOYMENT_INSURANCE = "EOY_INCOME_TAX_PRIVATE_EXPENSES_UNEMPLOYMENT_INSURANCE",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_UNIVERSITY_FEES = "EOY_INCOME_TAX_PRIVATE_EXPENSES_UNIVERSITY_FEES",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_VEHICLE_LIABILITY = "EOY_INCOME_TAX_PRIVATE_EXPENSES_VEHICLE_LIABILITY",
  EOY_OFFICE_USAGE_ELECTRICITY = "EOY_OFFICE_USAGE_ELECTRICITY",
  EOY_OFFICE_USAGE_FLOOR_PLAN = "EOY_OFFICE_USAGE_FLOOR_PLAN",
  EOY_OFFICE_USAGE_HEATING = "EOY_OFFICE_USAGE_HEATING",
  EOY_OFFICE_USAGE_OTHER = "EOY_OFFICE_USAGE_OTHER",
  EOY_OFFICE_USAGE_PHONE_OR_INTERNET = "EOY_OFFICE_USAGE_PHONE_OR_INTERNET",
  EOY_OFFICE_USAGE_RENT_OR_INTEREST = "EOY_OFFICE_USAGE_RENT_OR_INTEREST",
  EOY_OFFICE_USAGE_UTILITY = "EOY_OFFICE_USAGE_UTILITY",
  EOY_OFFICE_USAGE_UTILITY_AFTER_PAYMENT = "EOY_OFFICE_USAGE_UTILITY_AFTER_PAYMENT",
  EOY_TRAVEL_EXPENSES_BUSINESS_TRIPS = "EOY_TRAVEL_EXPENSES_BUSINESS_TRIPS",
  EOY_TRAVEL_EXPENSES_OTHER = "EOY_TRAVEL_EXPENSES_OTHER",
}

export type QuestionnaireQuestion = {
  __typename?: "QuestionnaireQuestion";
  allowExit?: Maybe<Scalars["Boolean"]>;
  defaultAnswer?: Maybe<Scalars["JSON"]>;
  inputConfig?: Maybe<Scalars["JSONObject"]>;
  lastYearAnswer?: Maybe<QuestionnaireAnswer>;
  name: Scalars["String"];
  postponable?: Maybe<Scalars["Boolean"]>;
  previousQuestionsAnswers?: Maybe<Array<QuestionnaireAnswer>>;
  suggestLastYearAnswer?: Maybe<Scalars["Boolean"]>;
  topic?: Maybe<Scalars["String"]>;
};

export type QuestionnaireQuestionWithAnswer = {
  __typename?: "QuestionnaireQuestionWithAnswer";
  allowExit?: Maybe<Scalars["Boolean"]>;
  answer?: Maybe<QuestionnaireAnswer>;
  defaultAnswer?: Maybe<Scalars["JSON"]>;
  inputConfig?: Maybe<Scalars["JSONObject"]>;
  lastYearAnswer?: Maybe<QuestionnaireAnswer>;
  name: Scalars["String"];
  postponable?: Maybe<Scalars["Boolean"]>;
  previousQuestionsAnswers?: Maybe<Array<QuestionnaireAnswer>>;
  suggestLastYearAnswer?: Maybe<Scalars["Boolean"]>;
  topic?: Maybe<Scalars["String"]>;
};

export enum QuestionnaireStatus {
  COMPLETED = "COMPLETED",
  DOCUMENTS_UPLOADED = "DOCUMENTS_UPLOADED",
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
}

export enum QuestionnaireType {
  BIZ_TAX_BASIC_DATA = "BIZ_TAX_BASIC_DATA",
  BIZ_TAX_CAR_USAGE = "BIZ_TAX_CAR_USAGE",
  BIZ_TAX_OFFICE_USAGE = "BIZ_TAX_OFFICE_USAGE",
  BIZ_TAX_TRAVEL_EXPENSES = "BIZ_TAX_TRAVEL_EXPENSES",
  EOY_BASIC_DATA = "EOY_BASIC_DATA",
  EOY_BOOKKEEPING = "EOY_BOOKKEEPING",
  EOY_CAR_USAGE = "EOY_CAR_USAGE",
  EOY_INCOME_TAX = "EOY_INCOME_TAX",
  EOY_INCOME_TAX_ADDITIONAL_INCOME = "EOY_INCOME_TAX_ADDITIONAL_INCOME",
  EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER = "EOY_INCOME_TAX_ADDITIONAL_INCOME_PARTNER",
  EOY_INCOME_TAX_BASIC_DATA = "EOY_INCOME_TAX_BASIC_DATA",
  EOY_INCOME_TAX_BASIC_DATA_PARTNER = "EOY_INCOME_TAX_BASIC_DATA_PARTNER",
  EOY_INCOME_TAX_CHILD = "EOY_INCOME_TAX_CHILD",
  EOY_INCOME_TAX_PRIVATE_EXPENSES = "EOY_INCOME_TAX_PRIVATE_EXPENSES",
  EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER = "EOY_INCOME_TAX_PRIVATE_EXPENSES_PARTNER",
  EOY_OFFICE_USAGE = "EOY_OFFICE_USAGE",
  EOY_TRAVEL_EXPENSES = "EOY_TRAVEL_EXPENSES",
  START_OF_THE_YEAR = "START_OF_THE_YEAR",
}

export type RetroactiveKontaxFee = {
  __typename?: "RetroactiveKontaxFee";
  description: Scalars["String"];
  dueDate: Scalars["DateTime"];
  endDate: Scalars["DateTime"];
  id: Scalars["ID"];
  invoiceDate?: Maybe<Scalars["DateTime"]>;
  quantity: Scalars["Float"];
  startDate: Scalars["DateTime"];
  status?: Maybe<RetroactiveKontaxFeeStatus>;
  unitAmount: Scalars["Float"];
};

export enum RetroactiveKontaxFeeStatus {
  CANCELED = "CANCELED",
  OPEN = "OPEN",
  PAST_DUE = "PAST_DUE",
  PAYED = "PAYED",
}

export type RuleConditionNumber = {
  __typename?: "RuleConditionNumber";
  op: RuleConditionNumberOp;
  value: Scalars["Float"];
};

export type RuleConditionNumberInput = {
  op: RuleConditionNumberOp;
  value: Scalars["Float"];
};

export enum RuleConditionNumberOp {
  EQ = "EQ",
  GT = "GT",
  LT = "LT",
}

export type RuleConditionString = {
  __typename?: "RuleConditionString";
  op: RuleConditionStringOp;
  value: Scalars["String"];
};

export type RuleConditionStringInput = {
  op: RuleConditionStringOp;
  value: Scalars["String"];
};

export enum RuleConditionStringOp {
  EQ = "EQ",
  LIKE = "LIKE",
}

export enum SkrMode {
  SKR_03 = "SKR_03",
  SKR_04 = "SKR_04",
}

export type Split = {
  __typename?: "Split";
  amount: Scalars["Int"];
  categorizationType?: Maybe<CategorizationType>;
  category?: Maybe<TransactionCategory>;
  categoryCode: Scalars["String"];
  categoryCodeTranslation?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  submittedCategoryCode?: Maybe<Scalars["String"]>;
  submittedVatCategoryCode?: Maybe<VatCategoryCode>;
  userSelectedBookingDate?: Maybe<Scalars["DateTime"]>;
  uuid: Scalars["ID"];
  vatCategoryCode?: Maybe<VatCategoryCode>;
  vatRate?: Maybe<VatRate>;
};

export type Steuerart = {
  __typename?: "Steuerart";
  value?: Maybe<Scalars["String"]>;
};

export type SteuerartTeilbetrag = {
  __typename?: "SteuerartTeilbetrag";
  teilbetragErlaeuterung?: Maybe<Array<Scalars["String"]>>;
  teilbetragFaelligkeit?: Maybe<Array<Scalars["String"]>>;
  teilbetragWert?: Maybe<Array<Scalars["String"]>>;
  teilbetragZeitraum?: Maybe<Array<Scalars["String"]>>;
};

export enum SupportedLanguage {
  DE = "DE",
  EN = "EN",
}

export type SystemUsageStat = {
  __typename?: "SystemUsageStat";
  finishedAt: Scalars["DateTime"];
  startedAt: Scalars["DateTime"];
};

export type TaxAccount = {
  __typename?: "TaxAccount";
  kontoabfrageInput: Array<KontoabfrageInput>;
  kontoabfrageOutput: Array<KontoabfrageOutput>;
};

export enum TaxAdvisorProof {
  NOT_OKAY = "NOT_OKAY",
  OKAY = "OKAY",
  PARTIAL = "PARTIAL",
}

export type TaxBoardKontaxUser = {
  __typename?: "TaxBoardKontaxUser";
  fibuFinalChecks: Array<FibuFinalCheck>;
  taxCases: Array<TaxBoardTaxCase>;
};

export type TaxBoardTaxCase = {
  __typename?: "TaxBoardTaxCase";
  assignee?: Maybe<Scalars["String"]>;
  businessTaxFinalizedAt?: Maybe<Scalars["DateTime"]>;
  deadline: Scalars["DateTime"];
  finalizedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  incomeTaxSkippedAt?: Maybe<Scalars["DateTime"]>;
  notes: Array<KontaxNote>;
  status: TaxCaseStatus;
  taxOfficeDeadline?: Maybe<Scalars["DateTime"]>;
  userFinalizedAt?: Maybe<Scalars["DateTime"]>;
  year: Scalars["Int"];
};

export type TaxBoardTaxNumbers = {
  __typename?: "TaxBoardTaxNumbers";
  taxNumber: Scalars["String"];
};

export type TaxBoardUser = {
  __typename?: "TaxBoardUser";
  accounts: Array<TaxBoardUserAccount>;
  email?: Maybe<Scalars["String"]>;
  euerDeclarations: Array<EuerDeclaration>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  incomeTaxDeclarations: Array<IncomeTaxDeclaration>;
  kontaxUser?: Maybe<TaxBoardKontaxUser>;
  lastName?: Maybe<Scalars["String"]>;
  taxNumbers: Array<TaxBoardTaxNumbers>;
  tradeTaxDeclarations: Array<TradeTaxDeclaration>;
  vatAnnualDeclarations: Array<VatAnnualDeclaration>;
};

export type TaxBoardUserAccount = {
  __typename?: "TaxBoardUserAccount";
  id: Scalars["ID"];
};

export type TaxBoardUserDataAndCount = {
  __typename?: "TaxBoardUserDataAndCount";
  count: Scalars["Int"];
  data: Array<TaxBoardUser>;
};

export type TaxBoardUsersFilterArgs = {
  assignee?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  euerDeclarationStatus?: InputMaybe<Array<EuerDeclarationStatus>>;
  fibuFinalCheckStatus?: InputMaybe<Array<FibuFinalCheckStatus>>;
  idOrTaxNumber?: InputMaybe<Scalars["String"]>;
  incomeTaxDeclarationStatus?: InputMaybe<Array<IncomeTaxDeclarationStatus>>;
  name?: InputMaybe<Scalars["String"]>;
  taxCaseStatus?: InputMaybe<Array<TaxCaseStatus>>;
  tradeTaxDeclarationStatus?: InputMaybe<Array<TradeTaxDeclarationStatus>>;
  vatAnnualDeclarationStatus?: InputMaybe<Array<VatAnnualDeclarationStatus>>;
};

export enum TaxCaseStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export type TaxDeclarationExternalAsset = {
  __typename?: "TaxDeclarationExternalAsset";
  createdAt: Scalars["DateTime"];
  filetype: Scalars["String"];
  url: Scalars["String"];
};

export type TaxDeclarationSavedDraftInfo = {
  __typename?: "TaxDeclarationSavedDraftInfo";
  calculationSheet?: Maybe<Scalars["String"]>;
  calculationSheetUrl?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<Scalars["String"]>;
  externalAssets?: Maybe<Array<TaxDeclarationExternalAsset>>;
  pdf?: Maybe<Scalars["String"]>;
  pdfUrl?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TaxDeclarationSubmission = {
  __typename?: "TaxDeclarationSubmission";
  calculationSheet?: Maybe<Scalars["String"]>;
  calculationSheetUrl?: Maybe<Scalars["String"]>;
  pdf?: Maybe<Scalars["String"]>;
  pdfUrl?: Maybe<Scalars["String"]>;
  submissionAgent?: Maybe<Scalars["String"]>;
  submissionDate?: Maybe<Scalars["DateTime"]>;
};

export type TaxDeclarationSubmissionInfo = {
  __typename?: "TaxDeclarationSubmissionInfo";
  calculationSheet?: Maybe<Scalars["String"]>;
  calculationSheetUrl?: Maybe<Scalars["String"]>;
  externalAssets?: Maybe<Array<TaxDeclarationExternalAsset>>;
  pdf?: Maybe<Scalars["String"]>;
  pdfUrl?: Maybe<Scalars["String"]>;
  submissionAgent?: Maybe<Scalars["String"]>;
  submissionDate?: Maybe<Scalars["DateTime"]>;
};

export enum TaxDeclarationType {
  EUER = "EUER",
  INCOME_TAX = "INCOME_TAX",
  TRADE_TAX = "TRADE_TAX",
  VAT_ANNUAL = "VAT_ANNUAL",
}

/** Tax numbers of users */
export type TaxNumber = {
  __typename?: "TaxNumber";
  changeLogs: Array<ChangeLog>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  id: Scalars["ID"];
  isMainBusinessTaxNumber: Scalars["Boolean"];
  taxNumber: Scalars["String"];
  type: TaxNumberType;
  validFrom?: Maybe<Scalars["DateTime"]>;
};

export enum TaxNumberType {
  BUSINESS = "BUSINESS",
  PERSONAL = "PERSONAL",
}

export type TaxService = {
  __typename?: "TaxService";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["String"];
  department: TaxServiceDepartment;
  externalNote: Scalars["String"];
  hours?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  internalNote: Scalars["String"];
  name: TaxServiceName;
  status: TaxServiceStatus;
  updatedAt: Scalars["DateTime"];
  year?: Maybe<Scalars["Int"]>;
};

export enum TaxServiceDepartment {
  CONSULTING = "CONSULTING",
  OTHER_SERVICES = "OTHER_SERVICES",
  TAX_DECLARATION = "TAX_DECLARATION",
}

export type TaxServiceDetails = {
  __typename?: "TaxServiceDetails";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["String"];
  department: TaxServiceDepartment;
  externalNote: Scalars["String"];
  hours?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  internalNote: Scalars["String"];
  kontaxUser: TaxServiceKontaxUser;
  name: TaxServiceName;
  status: TaxServiceStatus;
  updatedAt: Scalars["DateTime"];
  year?: Maybe<Scalars["Int"]>;
};

export type TaxServiceKontaxUser = {
  __typename?: "TaxServiceKontaxUser";
  kontistUser: TaxServiceUser;
};

export enum TaxServiceName {
  ADJUSTMENTS_INCOME_TAX_PREPAYMENTS = "ADJUSTMENTS_INCOME_TAX_PREPAYMENTS",
  ADJUSTMENTS_TRADE_TAX_PREPAYMENTS = "ADJUSTMENTS_TRADE_TAX_PREPAYMENTS",
  ADVICE_IN_CONNECTION_WITH_SEVERANCE_PAYMENTS = "ADVICE_IN_CONNECTION_WITH_SEVERANCE_PAYMENTS",
  ADVICE_ON_MOVING_OUT_OF_GERMANY = "ADVICE_ON_MOVING_OUT_OF_GERMANY",
  ADVICE_ON_OTHER_TAX_ISSUES = "ADVICE_ON_OTHER_TAX_ISSUES",
  ALIMONY_PAYMENTS = "ALIMONY_PAYMENTS",
  APPLY_FOR_PERMANENT_EXTENSION = "APPLY_FOR_PERMANENT_EXTENSION",
  APPLY_FOR_VAT_ID = "APPLY_FOR_VAT_ID",
  ASSESSMENT_OF_INVESTMENTS_IN_OTHER_COMPANIES = "ASSESSMENT_OF_INVESTMENTS_IN_OTHER_COMPANIES",
  ATTACHMENT = "ATTACHMENT",
  ATTACHMENT_AUS = "ATTACHMENT_AUS",
  ATTACHMENT_AV = "ATTACHMENT_AV",
  ATTACHMENT_EXTRAORDINARY_EXPENSES = "ATTACHMENT_EXTRAORDINARY_EXPENSES",
  ATTACHMENT_G = "ATTACHMENT_G",
  ATTACHMENT_HOUSEHOLD_RELATED_EXPENSES = "ATTACHMENT_HOUSEHOLD_RELATED_EXPENSES",
  ATTACHMENT_JOINT_ASSESSMENT = "ATTACHMENT_JOINT_ASSESSMENT",
  ATTACHMENT_K = "ATTACHMENT_K",
  ATTACHMENT_KAP_CAPITAL_GAINS = "ATTACHMENT_KAP_CAPITAL_GAINS",
  ATTACHMENT_KAP_TRADING_OR_OTHERS = "ATTACHMENT_KAP_TRADING_OR_OTHERS",
  ATTACHMENT_N = "ATTACHMENT_N",
  ATTACHMENT_PENSION_EXPENSE = "ATTACHMENT_PENSION_EXPENSE",
  ATTACHMENT_R = "ATTACHMENT_R",
  ATTACHMENT_S = "ATTACHMENT_S",
  ATTACHMENT_SO = "ATTACHMENT_SO",
  ATTACHMENT_SPECIAL_EXPENSE = "ATTACHMENT_SPECIAL_EXPENSE",
  ATTACHMENT_V_PLUS_V = "ATTACHMENT_V_PLUS_V",
  BUSINESS_ASSETS = "BUSINESS_ASSETS",
  CAR_TAXATION_1_PERCENT_METHOD_LOGBOOK = "CAR_TAXATION_1_PERCENT_METHOD_LOGBOOK",
  CHECK_SMALL_BUSINESS_VS_NORMAL_TAX = "CHECK_SMALL_BUSINESS_VS_NORMAL_TAX",
  CONFIRMATION_SELF_EMPLOYMENT = "CONFIRMATION_SELF_EMPLOYMENT",
  CONSULTATION_ON_THE_PURCHASE_OF_A_CAR = "CONSULTATION_ON_THE_PURCHASE_OF_A_CAR",
  DEFERRAL_REQUESTS = "DEFERRAL_REQUESTS",
  DIVORCE_COSTS = "DIVORCE_COSTS",
  END_OF_YEAR_MEETING = "END_OF_YEAR_MEETING",
  ESOP_VSOP_CONSULTING = "ESOP_VSOP_CONSULTING",
  EUER = "EUER",
  EXPRESS_FEE_FOR_TAX_DECLARATIONS = "EXPRESS_FEE_FOR_TAX_DECLARATIONS",
  FACILITY_FOR_CHILDREN_OVER_18_YEARS = "FACILITY_FOR_CHILDREN_OVER_18_YEARS",
  FEES_FOR_LATE_SUBMISSION_OF_DOCUMENTS = "FEES_FOR_LATE_SUBMISSION_OF_DOCUMENTS",
  FEE_FOR_PHYSICAL_DOCUMENT_RETRIEVAL = "FEE_FOR_PHYSICAL_DOCUMENT_RETRIEVAL",
  HOME_OFFICE = "HOME_OFFICE",
  HOME_OWNERSHIP = "HOME_OWNERSHIP",
  ILLNESS_AND_DISABILITY = "ILLNESS_AND_DISABILITY",
  INITIAL_CONSULTATION = "INITIAL_CONSULTATION",
  INVESTMENT_CONSULTING = "INVESTMENT_CONSULTING",
  INVOICING_ISSUES_INCLUDING_CORRECTION_OF_INVOICES = "INVOICING_ISSUES_INCLUDING_CORRECTION_OF_INVOICES",
  LIST_OF_TOTALS_AND_BALANCES = "LIST_OF_TOTALS_AND_BALANCES",
  MANUAL_BOOKKEEPING = "MANUAL_BOOKKEEPING",
  NATURAL_HAZARD_DAMAGE = "NATURAL_HAZARD_DAMAGE",
  NON_COOPERATION_FEE = "NON_COOPERATION_FEE",
  OBJECTIONS = "OBJECTIONS",
  OPERATIONAL_AUDITS = "OPERATIONAL_AUDITS",
  OTHER_SERVICES = "OTHER_SERVICES",
  OTHER_SERVICE_FLAT_RATE = "OTHER_SERVICE_FLAT_RATE",
  PRIVATE_ANGABEN = "PRIVATE_ANGABEN",
  PROFESSIONAL_TRAINING = "PROFESSIONAL_TRAINING",
  QUESTIONNAIRE_FOR_TAX_REGISTRATION_SECOND_BUSINESS = "QUESTIONNAIRE_FOR_TAX_REGISTRATION_SECOND_BUSINESS",
  REACTIVATION_FEE = "REACTIVATION_FEE",
  REPORT_MOVE_TO_THE_TAX_OFFICE = "REPORT_MOVE_TO_THE_TAX_OFFICE",
  REVIEW_OF_CONTRACTS = "REVIEW_OF_CONTRACTS",
  SEPARATE_AND_UNIFORM_ASSESSMENT = "SEPARATE_AND_UNIFORM_ASSESSMENT",
  SIGNED_BWA = "SIGNED_BWA",
  TAX_BILL_CHECK = "TAX_BILL_CHECK",
  TRADE_TAX_DECLARATIONS = "TRADE_TAX_DECLARATIONS",
  TRANSACTIONAL_CONSULTING = "TRANSACTIONAL_CONSULTING",
  TRAVEL_COST = "TRAVEL_COST",
  VAT_ANNUAL_DECLARATIONS = "VAT_ANNUAL_DECLARATIONS",
}

export enum TaxServiceStatus {
  BLOCKED = "BLOCKED",
  NOT_PAYED_YET = "NOT_PAYED_YET",
  PAYED = "PAYED",
  WILL_BE_BILLED = "WILL_BE_BILLED",
}

export type TaxServiceUser = {
  __typename?: "TaxServiceUser";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type TaxServicesFilterArgs = {
  createdBy?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
};

export type TradeTaxDeclaration = {
  __typename?: "TradeTaxDeclaration";
  autoCalculatedValues: TradeTaxDeclarationAutoCalculatedValues;
  changeLogs: Array<ChangeLog>;
  declarationApproval?: Maybe<DeclarationApproval>;
  declarationDeclines: Array<DeclarationDecline>;
  declarationDocuments: Array<DeclarationDocument>;
  draft: TradeTaxDeclarationProcessResult;
  hasMultipleMunicipalities: Scalars["Boolean"];
  id: Scalars["String"];
  lastStatusChange?: Maybe<TradeTaxDeclarationStatusChange>;
  municipality?: Maybe<Scalars["String"]>;
  municipalityTaxRate?: Maybe<Scalars["Int"]>;
  notes: Array<KontaxNote>;
  savedDraftInfo?: Maybe<TaxDeclarationSavedDraftInfo>;
  status: TradeTaxDeclarationStatus;
  statusUpdatedAt?: Maybe<Scalars["DateTime"]>;
  submissionInfo?: Maybe<TaxDeclarationSubmissionInfo>;
  submissions: Array<TaxDeclarationSubmission>;
  taxOfficeBalance?: Maybe<Scalars["Int"]>;
  year: Scalars["Int"];
};

export type TradeTaxDeclarationAdditions = {
  __typename?: "TradeTaxDeclarationAdditions";
  feesForDebts: Scalars["Int"];
  immovableBusinessAssetsRentOrLeaseInterest: Scalars["Int"];
  movableBusinessAssetsRentOrLeaseInterest: Scalars["Int"];
  softwareAndLicenses: Scalars["Int"];
  totalDeductibleAmount: Scalars["Int"];
  totalNetAmount: Scalars["Int"];
};

export type TradeTaxDeclarationAutoCalculatedValues = {
  __typename?: "TradeTaxDeclarationAutoCalculatedValues";
  additions: TradeTaxDeclarationAdditions;
  calculations: TradeTaxDeclarationCalculations;
  profit: Scalars["Int"];
};

export type TradeTaxDeclarationCalculations = {
  __typename?: "TradeTaxDeclarationCalculations";
  allowance: Scalars["Int"];
  deductedAdditionalAmountsMinus200kAllowance: Scalars["Int"];
  finalResult?: Maybe<Scalars["Int"]>;
  profit: Scalars["Int"];
  taxBaseAmountFederal: Scalars["Int"];
  taxOfficeBalance?: Maybe<Scalars["Int"]>;
  tradeTaxAmount?: Maybe<Scalars["Int"]>;
  tradeTaxAssessmentBasis: Scalars["Int"];
  tradeTaxRateFederal: Scalars["Float"];
  tradeTaxRateLocal?: Maybe<Scalars["Int"]>;
};

export type TradeTaxDeclarationProcessResult = {
  __typename?: "TradeTaxDeclarationProcessResult";
  calculationSheet?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  pdf?: Maybe<Scalars["String"]>;
  processResult: Scalars["String"];
  requestXml: Scalars["String"];
};

export enum TradeTaxDeclarationStatus {
  APPEAL_PROCESS_COMPLETED = "APPEAL_PROCESS_COMPLETED",
  APPEAL_PROCESS_STARTED = "APPEAL_PROCESS_STARTED",
  APPROVED_BY_TAX_CONSULTANT = "APPROVED_BY_TAX_CONSULTANT",
  APPROVED_BY_USER = "APPROVED_BY_USER",
  CLOSED = "CLOSED",
  COMPLETED_BY_DATA = "COMPLETED_BY_DATA",
  COMPLETED_BY_OPS = "COMPLETED_BY_OPS",
  CONSULTATION_DATA = "CONSULTATION_DATA",
  IN_PROGRESS_DATA = "IN_PROGRESS_DATA",
  IN_PROGRESS_OPS = "IN_PROGRESS_OPS",
  IN_PROGRESS_TAX_CONSULTANT = "IN_PROGRESS_TAX_CONSULTANT",
  IN_SUBMISSION_FOR_USER_APPROVAL = "IN_SUBMISSION_FOR_USER_APPROVAL",
  NOT_RELEVANT = "NOT_RELEVANT",
  OBJECTED_BY_FINANZAMT = "OBJECTED_BY_FINANZAMT",
  OBJECTED_BY_TAX_CONSULTANT = "OBJECTED_BY_TAX_CONSULTANT",
  OBJECTED_BY_USER = "OBJECTED_BY_USER",
  OPEN = "OPEN",
  RECEIVED_TAX_BILL = "RECEIVED_TAX_BILL",
  SUBMITTED = "SUBMITTED",
  WAITING_FOR_USER_APPROVAL = "WAITING_FOR_USER_APPROVAL",
}

export type TradeTaxDeclarationStatusChange = {
  __typename?: "TradeTaxDeclarationStatusChange";
  changedAt: Scalars["DateTime"];
  changedBy?: Maybe<Scalars["String"]>;
  status: TradeTaxDeclarationStatus;
};

export type Transaction = {
  __typename?: "Transaction";
  actionReason?: Maybe<ActionReason>;
  /** The amount of the transaction in cents */
  amount: Scalars["Int"];
  /** View a single Asset for a transaction */
  asset?: Maybe<TransactionAsset>;
  /** List of uploaded Asset files for this transaction */
  assets: Array<TransactionAsset>;
  /** The date at which the transaction was booked (created) */
  bookingDate: Scalars["DateTime"];
  /** View a single Business Asset for a transaction */
  businessAsset?: Maybe<BusinessAssetResponse>;
  canBeRecategorized: Scalars["Boolean"];
  categorizationType?: Maybe<CategorizationType>;
  category?: Maybe<TransactionCategory>;
  categoryCode?: Maybe<Scalars["String"]>;
  categoryCodeTranslation?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  directDebitFees: Array<DirectDebitFee>;
  documentDownloadUrl?: Maybe<Scalars["String"]>;
  documentNumber?: Maybe<Scalars["String"]>;
  documentPreviewUrl?: Maybe<Scalars["String"]>;
  documentType?: Maybe<DocumentType>;
  e2eId?: Maybe<Scalars["String"]>;
  fees: Array<TransactionFee>;
  foreignCurrency?: Maybe<Scalars["String"]>;
  hasAssets: Scalars["Boolean"];
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  mandateNumber?: Maybe<Scalars["String"]>;
  merchantCategoryCode?: Maybe<Scalars["String"]>;
  merchantCountryCode?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  originalAmount?: Maybe<Scalars["Float"]>;
  paymentMethod: Scalars["String"];
  personalNote?: Maybe<Scalars["String"]>;
  predictedCategory?: Maybe<TransactionCategory>;
  /** Date predicted for tax/vat payment/refund predicted category */
  predictedUserSelectedBookingDate?: Maybe<Scalars["DateTime"]>;
  purpose?: Maybe<Scalars["String"]>;
  receiptName?: Maybe<Scalars["String"]>;
  recurlyInvoiceNumber?: Maybe<Scalars["String"]>;
  source: TransactionSource;
  /** Metadata of separate pseudo-transactions created when splitting the parent transaction */
  splits: Array<TransactionSplit>;
  /** View a single Asset for a transaction */
  transactionAsset?: Maybe<Asset>;
  /** List Assets for a transaction */
  transactionAssets: Array<Asset>;
  type: TransactionProjectionType;
  /** When a transaction corresponds to a tax or vat payment, the user may specify at which date it should be considered booked */
  userSelectedBookingDate?: Maybe<Scalars["DateTime"]>;
  /** The date at which the transaction was processed and the amount deducted from the user's account */
  valutaDate?: Maybe<Scalars["DateTime"]>;
  vatCategoryCode?: Maybe<Scalars["String"]>;
  vatRate?: Maybe<VatRate>;
  verified?: Maybe<Scalars["Boolean"]>;
};

export type TransactionAssetArgs = {
  assetId: Scalars["ID"];
};

export type TransactionTransactionAssetArgs = {
  assetId: Scalars["ID"];
};

export type TransactionAsset = {
  __typename?: "TransactionAsset";
  assetableId: Scalars["ID"];
  filetype: Scalars["String"];
  fullsize: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  path: Scalars["String"];
  thumbnail: Scalars["String"];
};

export enum TransactionCategory {
  PRIVATE = "PRIVATE",
  REVERSE_CHARGE = "REVERSE_CHARGE",
  TAX_PAYMENT = "TAX_PAYMENT",
  TAX_REFUND = "TAX_REFUND",
  TAX_SAVING = "TAX_SAVING",
  VAT = "VAT",
  VAT_0 = "VAT_0",
  VAT_5 = "VAT_5",
  VAT_7 = "VAT_7",
  VAT_16 = "VAT_16",
  VAT_19 = "VAT_19",
  VAT_PAYMENT = "VAT_PAYMENT",
  VAT_REFUND = "VAT_REFUND",
  VAT_SAVING = "VAT_SAVING",
}

export type TransactionCondition = {
  amount_eq?: InputMaybe<Scalars["Int"]>;
  amount_gt?: InputMaybe<Scalars["Int"]>;
  amount_gte?: InputMaybe<Scalars["Int"]>;
  amount_in?: InputMaybe<Array<Scalars["Int"]>>;
  amount_lt?: InputMaybe<Scalars["Int"]>;
  amount_lte?: InputMaybe<Scalars["Int"]>;
  amount_ne?: InputMaybe<Scalars["Int"]>;
  assets_exist?: InputMaybe<Scalars["Boolean"]>;
  bookingDate_eq?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_gt?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_gte?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_lt?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_lte?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_ne?: InputMaybe<Scalars["DateTime"]>;
  categoryCode_exist?: InputMaybe<Scalars["Boolean"]>;
  category_eq?: InputMaybe<TransactionCategory>;
  category_in?: InputMaybe<Array<TransactionCategory>>;
  iban_eq?: InputMaybe<Scalars["String"]>;
  iban_in?: InputMaybe<Array<Scalars["String"]>>;
  iban_like?: InputMaybe<Scalars["String"]>;
  iban_likeAny?: InputMaybe<Array<Scalars["String"]>>;
  iban_ne?: InputMaybe<Scalars["String"]>;
  name_eq?: InputMaybe<Scalars["String"]>;
  name_in?: InputMaybe<Array<Scalars["String"]>>;
  name_like?: InputMaybe<Scalars["String"]>;
  name_likeAny?: InputMaybe<Array<Scalars["String"]>>;
  name_ne?: InputMaybe<Scalars["String"]>;
  operator?: InputMaybe<BaseOperator>;
  purpose_eq?: InputMaybe<Scalars["String"]>;
  purpose_like?: InputMaybe<Scalars["String"]>;
  purpose_likeAny?: InputMaybe<Array<Scalars["String"]>>;
  purpose_ne?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_in?: InputMaybe<Array<Scalars["String"]>>;
  source_ne?: InputMaybe<Scalars["String"]>;
  splits_exist?: InputMaybe<Scalars["Boolean"]>;
  type_eq?: InputMaybe<TransactionProjectionType>;
  valutaDate_eq?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_gt?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_gte?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_lt?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_lte?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_ne?: InputMaybe<Scalars["DateTime"]>;
  vatAssets_exist?: InputMaybe<Scalars["Boolean"]>;
  vatCategoryCode_exist?: InputMaybe<Scalars["Boolean"]>;
};

export type TransactionFee = {
  __typename?: "TransactionFee";
  status: TransactionFeeStatus;
  type: TransactionFeeType;
  unitAmount?: Maybe<Scalars["Int"]>;
  usedAt?: Maybe<Scalars["DateTime"]>;
};

export enum TransactionFeeStatus {
  CANCELLED = "CANCELLED",
  CHARGED = "CHARGED",
  CREATED = "CREATED",
  REFUNDED = "REFUNDED",
  REFUND_INITIATED = "REFUND_INITIATED",
}

export enum TransactionFeeType {
  ATM = "ATM",
  CARD_REPLACEMENT = "CARD_REPLACEMENT",
  DIRECT_DEBIT_RETURN = "DIRECT_DEBIT_RETURN",
  FOREIGN_TRANSACTION = "FOREIGN_TRANSACTION",
  FREE_KONTIST_TRANSACTION = "FREE_KONTIST_TRANSACTION",
  INSTANT_CREDIT_TRANSFER = "INSTANT_CREDIT_TRANSFER",
  KONTIST_TRANSACTION = "KONTIST_TRANSACTION",
  SECOND_REMINDER_EMAIL = "SECOND_REMINDER_EMAIL",
  TOP_UP = "TOP_UP",
}

export type TransactionFeedback = {
  fibuCategoryComment?: InputMaybe<Scalars["String"]>;
  taxAdvisorProof: TaxAdvisorProof;
  vatCategoryComment?: InputMaybe<Scalars["String"]>;
};

export type TransactionFilter = {
  amount_eq?: InputMaybe<Scalars["Int"]>;
  amount_gt?: InputMaybe<Scalars["Int"]>;
  amount_gte?: InputMaybe<Scalars["Int"]>;
  amount_in?: InputMaybe<Array<Scalars["Int"]>>;
  amount_lt?: InputMaybe<Scalars["Int"]>;
  amount_lte?: InputMaybe<Scalars["Int"]>;
  amount_ne?: InputMaybe<Scalars["Int"]>;
  assets_exist?: InputMaybe<Scalars["Boolean"]>;
  bookingDate_eq?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_gt?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_gte?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_lt?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_lte?: InputMaybe<Scalars["DateTime"]>;
  bookingDate_ne?: InputMaybe<Scalars["DateTime"]>;
  categoryCode_exist?: InputMaybe<Scalars["Boolean"]>;
  category_eq?: InputMaybe<TransactionCategory>;
  category_in?: InputMaybe<Array<TransactionCategory>>;
  conditions?: InputMaybe<Array<TransactionCondition>>;
  iban_eq?: InputMaybe<Scalars["String"]>;
  iban_in?: InputMaybe<Array<Scalars["String"]>>;
  iban_like?: InputMaybe<Scalars["String"]>;
  iban_likeAny?: InputMaybe<Array<Scalars["String"]>>;
  iban_ne?: InputMaybe<Scalars["String"]>;
  name_eq?: InputMaybe<Scalars["String"]>;
  name_in?: InputMaybe<Array<Scalars["String"]>>;
  name_like?: InputMaybe<Scalars["String"]>;
  name_likeAny?: InputMaybe<Array<Scalars["String"]>>;
  name_ne?: InputMaybe<Scalars["String"]>;
  operator?: InputMaybe<BaseOperator>;
  purpose_eq?: InputMaybe<Scalars["String"]>;
  purpose_like?: InputMaybe<Scalars["String"]>;
  purpose_likeAny?: InputMaybe<Array<Scalars["String"]>>;
  purpose_ne?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_in?: InputMaybe<Array<Scalars["String"]>>;
  source_ne?: InputMaybe<Scalars["String"]>;
  splits_exist?: InputMaybe<Scalars["Boolean"]>;
  type_eq?: InputMaybe<TransactionProjectionType>;
  valutaDate_eq?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_gt?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_gte?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_lt?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_lte?: InputMaybe<Scalars["DateTime"]>;
  valutaDate_ne?: InputMaybe<Scalars["DateTime"]>;
  vatAssets_exist?: InputMaybe<Scalars["Boolean"]>;
  vatCategoryCode_exist?: InputMaybe<Scalars["Boolean"]>;
};

export enum TransactionProjectionType {
  ATM = "ATM",
  CANCELLATION_BOOKING = "CANCELLATION_BOOKING",
  CANCELLATION_CARD_TRANSACTION = "CANCELLATION_CARD_TRANSACTION",
  CANCELLATION_CHARGE = "CANCELLATION_CHARGE",
  CANCELLATION_DIRECT_DEBIT = "CANCELLATION_DIRECT_DEBIT",
  CANCELLATION_DOUBLE_BOOKING = "CANCELLATION_DOUBLE_BOOKING",
  CANCELLATION_INTEREST_ACCRUED = "CANCELLATION_INTEREST_ACCRUED",
  CANCELLATION_SEPA_CREDIT_TRANSFER_RETURN = "CANCELLATION_SEPA_CREDIT_TRANSFER_RETURN",
  CANCELLATION_SEPA_DIRECT_DEBIT_RETURN = "CANCELLATION_SEPA_DIRECT_DEBIT_RETURN",
  CANCEL_MANUAL_LOAD = "CANCEL_MANUAL_LOAD",
  CARD_TRANSACTION = "CARD_TRANSACTION",
  CARD_USAGE = "CARD_USAGE",
  CASH_ATM_REVERSAL = "CASH_ATM_REVERSAL",
  CASH_MANUAL = "CASH_MANUAL",
  CASH_MANUAL_REVERSAL = "CASH_MANUAL_REVERSAL",
  CHARGE = "CHARGE",
  COMMISSION_OVERDRAFT = "COMMISSION_OVERDRAFT",
  CREDIT_PRESENTMENT = "CREDIT_PRESENTMENT",
  CREDIT_TRANSFER_CANCELLATION = "CREDIT_TRANSFER_CANCELLATION",
  CURRENCY_TRANSACTION_CANCELLATION = "CURRENCY_TRANSACTION_CANCELLATION",
  CHARGERECALLREQUEST = "ChargeRecallRequest",
  CORRECTIONCARDTRANSACTION = "CorrectionCardTransaction",
  CORRECTIONNOSTRO = "CorrectionNostro",
  CORRECTIONSEPACREDITTRANSFER = "CorrectionSEPACreditTransfer",
  DEBIT_PRESENTMENT = "DEBIT_PRESENTMENT",
  DEPOSIT_FEE = "DEPOSIT_FEE",
  DIRECT_DEBIT = "DIRECT_DEBIT",
  DIRECT_DEBIT_AUTOMATIC_TOPUP = "DIRECT_DEBIT_AUTOMATIC_TOPUP",
  DIRECT_DEBIT_RETURN = "DIRECT_DEBIT_RETURN",
  DISPUTE_CLEARING = "DISPUTE_CLEARING",
  DISPUTE_TRANSACTION = "DISPUTE_TRANSACTION",
  EXTERNAL_TRANSACTION = "EXTERNAL_TRANSACTION",
  EXTERNAL_TRANSACTION_CASH = "EXTERNAL_TRANSACTION_CASH",
  FORCE_POST_TRANSACTION = "FORCE_POST_TRANSACTION",
  FOREIGN_PAYMENT = "FOREIGN_PAYMENT",
  INTEREST_ACCRUED = "INTEREST_ACCRUED",
  INTERNAL_TRANSFER = "INTERNAL_TRANSFER",
  INTERNATIONAL_CREDIT_TRANSFER = "INTERNATIONAL_CREDIT_TRANSFER",
  INTRA_CUSTOMER_TRANSFER = "INTRA_CUSTOMER_TRANSFER",
  INTERESTEXCESSDEPOSIT = "InterestExcessDeposit",
  INTERESTOVERDRAFT = "InterestOverdraft",
  INTERESTOVERDRAFTEXCEEDED = "InterestOverdraftExceeded",
  MANUAL_LOAD = "MANUAL_LOAD",
  OCT = "OCT",
  OTHER = "OTHER",
  PURCHASE_REVERSAL = "PURCHASE_REVERSAL",
  REBOOKING = "REBOOKING",
  REBOOKEDSEPACREDITTRANSFERRETURN = "RebookedSEPACreditTransferReturn",
  REBOOKEDSEPADIRECTDEBITCORERETURN = "RebookedSEPADirectDebitCoreReturn",
  REIMBURSEMENTCUSTOMER = "ReimbursementCustomer",
  SEPAINSTANTCREDITTRANSFER = "SEPAInstantCreditTransfer",
  SEPA_CREDIT_TRANSFER = "SEPA_CREDIT_TRANSFER",
  SEPA_CREDIT_TRANSFER_RETURN = "SEPA_CREDIT_TRANSFER_RETURN",
  SEPA_DIRECT_DEBIT = "SEPA_DIRECT_DEBIT",
  SEPA_DIRECT_DEBIT_RETURN = "SEPA_DIRECT_DEBIT_RETURN",
  TRANSFER = "TRANSFER",
  TRANSFER_TO_BANK_ACCOUNT = "TRANSFER_TO_BANK_ACCOUNT",
  TARGET2CREDITTRANSFER1 = "Target2CreditTransfer1",
  TARGET2CREDITTRANSFER2 = "Target2CreditTransfer2",
  TOPUPCARD = "TopUpCard",
  TOPUPCARDCHARGEBACK = "TopUpCardChargeback",
  TOPUPCARDREFUND = "TopUpCardRefund",
  VERIFICATION_CODE = "VERIFICATION_CODE",
  WIRE_TRANSFER_TOPUP = "WIRE_TRANSFER_TOPUP",
}

export enum TransactionSource {
  BACKOFFICE_MANUAL = "BACKOFFICE_MANUAL",
  SOLARIS = "SOLARIS",
  USER = "USER",
}

export type TransactionSplit = {
  __typename?: "TransactionSplit";
  amount: Scalars["Int"];
  categorizationType?: Maybe<CategorizationType>;
  category?: Maybe<TransactionCategory>;
  categoryCode?: Maybe<Scalars["String"]>;
  categoryCodeTranslation?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  userSelectedBookingDate?: Maybe<Scalars["DateTime"]>;
  uuid: Scalars["ID"];
  vatCategoryCode?: Maybe<VatCategoryCode>;
  vatRate?: Maybe<VatRate>;
};

export type TransactionSplitInput = {
  amount: Scalars["Int"];
  category?: InputMaybe<TransactionCategory>;
  categoryCode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  userSelectedBookingDate?: InputMaybe<Scalars["DateTime"]>;
  vatCategoryCode?: InputMaybe<Scalars["String"]>;
  vatRate?: InputMaybe<VatRate>;
};

export type TransactionsVerifiedByMl = {
  __typename?: "TransactionsVerifiedByML";
  count: Scalars["Int"];
  transactions: Array<KontaxTransactionForAdmin>;
};

export type UpdateAnschreibenInput = {
  content: Scalars["String"];
  id: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
};

export type UpdateAutocategorizationRuleInput = {
  categoryCode?: InputMaybe<CategoryCode>;
  conditions?: InputMaybe<AutocategorizationRuleConditionsInput>;
  description?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  priority?: InputMaybe<Scalars["Int"]>;
  vatCategoryCode?: InputMaybe<VatCategoryCode>;
  verify?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateAutocategorizationRulePayload = {
  __typename?: "UpdateAutocategorizationRulePayload";
  autocategorizationRule: AutocategorizationRule;
};

export type UpdateBusinessAddressInput = {
  city: Scalars["String"];
  id: Scalars["ID"];
  movingDate: Scalars["DateTime"];
  postCode: Scalars["String"];
  street: Scalars["String"];
};

export type UpdateDatevDataInput = {
  direction: Direction;
  id: Scalars["ID"];
  kkr: Scalars["String"];
  vatCategoryCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateEuerDeclarationInput = {
  fields: Scalars["JSONObject"];
  status: EuerDeclarationStatus;
};

export type UpdateEuerDeclarationSubformInput = {
  calculationMethod: EuerDeclarationSubformCalculationMethod;
  calculationResults: Scalars["JSONObject"];
  inputs: Scalars["JSONObject"];
  status: EuerDeclarationSubformStatus;
};

export type UpdateIncomeTaxDeclarationInput = {
  status: IncomeTaxDeclarationStatus;
};

export type UpdateTaxCasePayload = {
  deadline: Scalars["DateTime"];
  id: Scalars["ID"];
  taxOfficeDeadline: Scalars["DateTime"];
};

export type UpdateTaxNumberInputWithNoTaxNumber = {
  description: Scalars["String"];
  isMainBusinessTaxNumber: Scalars["Boolean"];
  type: TaxNumberType;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateTradeTaxDeclarationInput = {
  hasMultipleMunicipalities: Scalars["Boolean"];
  municipality?: InputMaybe<Scalars["String"]>;
  municipalityTaxRate?: InputMaybe<Scalars["Float"]>;
  status: TradeTaxDeclarationStatus;
  taxOfficeBalance?: InputMaybe<Scalars["Int"]>;
};

export type UpdateVatAnnualDeclarationInput = {
  currentYearIncomeAdjustment?: InputMaybe<Scalars["Int"]>;
  lastYearIncomeAdjustment?: InputMaybe<Scalars["Int"]>;
  status: VatAnnualDeclarationStatus;
  taxOfficeBalance?: InputMaybe<Scalars["Int"]>;
};

export type UpsertAccountingSourceInput = {
  type: KontaxAccountingSourceType;
  year: Scalars["Int"];
};

export type UpsertAnschreibenTemplateInput = {
  content: Scalars["String"];
  language: SupportedLanguage;
  note?: InputMaybe<Scalars["String"]>;
  type: AnschreibenType;
  year: Scalars["Float"];
};

export type UpsertExternalTransactionOptions = {
  emailDocumentId?: InputMaybe<Scalars["String"]>;
};

export type UpsertTaxServiceInput = {
  department: TaxServiceDepartment;
  externalNote: Scalars["String"];
  flatRate?: InputMaybe<Scalars["Float"]>;
  hours?: InputMaybe<Scalars["Float"]>;
  id?: InputMaybe<Scalars["ID"]>;
  internalNote: Scalars["String"];
  name: TaxServiceName;
  status: TaxServiceStatus;
  year?: InputMaybe<Scalars["Int"]>;
};

export type UsageStat = {
  __typename?: "UsageStat";
  activity: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  finishedAt: Scalars["DateTime"];
  id: Scalars["ID"];
  startedAt: Scalars["DateTime"];
};

export type UsageStatDetails = {
  __typename?: "UsageStatDetails";
  activity: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
  finishedAt: Scalars["DateTime"];
  id: Scalars["ID"];
  kontistUser?: Maybe<UsageStatUser>;
  startedAt: Scalars["DateTime"];
};

export type UsageStatDetailsAndCount = {
  __typename?: "UsageStatDetailsAndCount";
  count: Scalars["Int"];
  data: Array<UsageStatDetails>;
};

export type UsageStatUser = {
  __typename?: "UsageStatUser";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
};

export type UsageStatsFilterArgs = {
  date?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
};

export type ValueMeta = {
  __typename?: "ValueMeta";
  categorizationType?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  suggestionSource?: Maybe<Scalars["String"]>;
};

export type VatAnnualDeclaration = {
  __typename?: "VatAnnualDeclaration";
  autoCalculatedValues: VatAnnualDeclarationAutoCalculatedValues;
  changeLogs: Array<ChangeLog>;
  currentYearIncomeAdjustment?: Maybe<Scalars["Int"]>;
  declarationApproval?: Maybe<DeclarationApproval>;
  declarationDeclines: Array<DeclarationDecline>;
  declarationDocuments: Array<DeclarationDocument>;
  draft: VatAnnualDeclarationProcessResult;
  id: Scalars["String"];
  lastStatusChange?: Maybe<VatAnnualDeclarationStatusChange>;
  lastYearIncomeAdjustment?: Maybe<Scalars["Int"]>;
  notes: Array<KontaxNote>;
  savedDraftInfo?: Maybe<TaxDeclarationSavedDraftInfo>;
  smallBusinessValues: VatAnnualDeclarationSmallBusinessValues;
  status: VatAnnualDeclarationStatus;
  statusUpdatedAt?: Maybe<Scalars["DateTime"]>;
  submissionInfo?: Maybe<TaxDeclarationSubmissionInfo>;
  submissions: Array<TaxDeclarationSubmission>;
  taxOfficeBalance?: Maybe<Scalars["Int"]>;
  year: Scalars["Int"];
};

export type VatAnnualDeclarationAutoCalculatedValuesArgs = {
  period?: InputMaybe<Scalars["String"]>;
};

export type VatAnnualDeclarationAutoCalculatedValues = {
  __typename?: "VatAnnualDeclarationAutoCalculatedValues";
  additionalInformation: VatAnnualDeclarationSection;
  deductibleInputTax: VatAnnualDeclarationSection;
  salesTax: VatAnnualDeclarationSection;
  total: Scalars["Int"];
};

export type VatAnnualDeclarationField = {
  __typename?: "VatAnnualDeclarationField";
  advanceNetAmount?: Maybe<Scalars["Int"]>;
  advanceVatAmount?: Maybe<Scalars["Int"]>;
  netAmount?: Maybe<Scalars["Int"]>;
  transactionFilters: VatAnnualDeclarationFieldTransactionFilters;
  type: VatAnnualDeclarationFieldType;
  vatAmount?: Maybe<Scalars["Int"]>;
};

export type VatAnnualDeclarationFieldTransactionFilters = {
  __typename?: "VatAnnualDeclarationFieldTransactionFilters";
  categoryCodes?: Maybe<Array<Scalars["String"]>>;
  vatCategoryCodes?: Maybe<Array<Scalars["String"]>>;
};

export enum VatAnnualDeclarationFieldType {
  CAR_USAGE = "CAR_USAGE",
  DIT_5 = "DIT_5",
  DIT_7 = "DIT_7",
  DIT_16 = "DIT_16",
  DIT_19 = "DIT_19",
  ELECTRONIC_SERVICE_EU_B2C = "ELECTRONIC_SERVICE_EU_B2C",
  EXPORT_DELIVERY = "EXPORT_DELIVERY",
  FREE_BENEFITS = "FREE_BENEFITS",
  FREE_VALUE_DELIVERY_7 = "FREE_VALUE_DELIVERY_7",
  FREE_VALUE_DELIVERY_19 = "FREE_VALUE_DELIVERY_19",
  FREE_VALUE_SERVICE_7 = "FREE_VALUE_SERVICE_7",
  HOME_OFFICE_DIT = "HOME_OFFICE_DIT",
  IMPORT_VAT = "IMPORT_VAT",
  INCOME_0_ITD = "INCOME_0_ITD",
  INCOME_5 = "INCOME_5",
  INCOME_7 = "INCOME_7",
  INCOME_13B5_USTG = "INCOME_13B5_USTG",
  INCOME_16 = "INCOME_16",
  INCOME_19 = "INCOME_19",
  INCOME_EU_B2B = "INCOME_EU_B2B",
  INCOME_EU_B2C = "INCOME_EU_B2C",
  INCOME_EU_INTRA_B2B = "INCOME_EU_INTRA_B2B",
  INCOME_EU_INTRA_B2C = "INCOME_EU_INTRA_B2C",
  INTRA_ACQUISITION_IT = "INTRA_ACQUISITION_IT",
  INTRA_ACQUISITION_ST = "INTRA_ACQUISITION_ST",
  KKR_1850 = "KKR_1850",
  NON_TAXABLE = "NON_TAXABLE",
  OTHER_USAGE_AND_SERVICE_WITHDRAWALS = "OTHER_USAGE_AND_SERVICE_WITHDRAWALS",
  REVERSE_CHARGE = "REVERSE_CHARGE",
  REVERSE_CHARGE_IT = "REVERSE_CHARGE_IT",
  REVERSE_CHARGE_ST = "REVERSE_CHARGE_ST",
  TOTAL_DIT = "TOTAL_DIT",
  TOTAL_INCOME_7 = "TOTAL_INCOME_7",
  TOTAL_INCOME_19 = "TOTAL_INCOME_19",
  TOTAL_INCOME_REDUCED_RATES = "TOTAL_INCOME_REDUCED_RATES",
  VAT_EXEMPTION_WITHOUT_ITD = "VAT_EXEMPTION_WITHOUT_ITD",
  VAT_EXEMPTION_WITH_ITD = "VAT_EXEMPTION_WITH_ITD",
}

export type VatAnnualDeclarationProcessResult = {
  __typename?: "VatAnnualDeclarationProcessResult";
  message: Scalars["String"];
  pdf?: Maybe<Scalars["String"]>;
  processResult: Scalars["String"];
};

export type VatAnnualDeclarationSection = {
  __typename?: "VatAnnualDeclarationSection";
  fields: Array<VatAnnualDeclarationField>;
  total?: Maybe<Scalars["Int"]>;
};

export type VatAnnualDeclarationSmallBusinessValues = {
  __typename?: "VatAnnualDeclarationSmallBusinessValues";
  additionalInformation: VatAnnualDeclarationSection;
  currentYearIncome: Scalars["Int"];
  lastYearIncome: Scalars["Int"];
  salesTax: VatAnnualDeclarationSection;
};

export enum VatAnnualDeclarationStatus {
  APPEAL_PROCESS_COMPLETED = "APPEAL_PROCESS_COMPLETED",
  APPEAL_PROCESS_STARTED = "APPEAL_PROCESS_STARTED",
  APPROVED_BY_TAX_CONSULTANT = "APPROVED_BY_TAX_CONSULTANT",
  APPROVED_BY_USER = "APPROVED_BY_USER",
  CLOSED = "CLOSED",
  COMPLETED_BY_DATA = "COMPLETED_BY_DATA",
  COMPLETED_BY_OPS = "COMPLETED_BY_OPS",
  CONSULTATION_DATA = "CONSULTATION_DATA",
  IN_PROGRESS_DATA = "IN_PROGRESS_DATA",
  IN_PROGRESS_OPS = "IN_PROGRESS_OPS",
  IN_PROGRESS_TAX_CONSULTANT = "IN_PROGRESS_TAX_CONSULTANT",
  IN_SUBMISSION_FOR_USER_APPROVAL = "IN_SUBMISSION_FOR_USER_APPROVAL",
  NOT_RELEVANT = "NOT_RELEVANT",
  OBJECTED_BY_FINANZAMT = "OBJECTED_BY_FINANZAMT",
  OBJECTED_BY_TAX_CONSULTANT = "OBJECTED_BY_TAX_CONSULTANT",
  OBJECTED_BY_USER = "OBJECTED_BY_USER",
  OPEN = "OPEN",
  RECEIVED_TAX_BILL = "RECEIVED_TAX_BILL",
  SUBMITTED = "SUBMITTED",
  WAITING_FOR_USER_APPROVAL = "WAITING_FOR_USER_APPROVAL",
}

export type VatAnnualDeclarationStatusChange = {
  __typename?: "VatAnnualDeclarationStatusChange";
  changedAt: Scalars["DateTime"];
  changedBy?: Maybe<Scalars["String"]>;
  status: VatAnnualDeclarationStatus;
};

export enum VatCategoryCode {
  DIT_5 = "DIT_5",
  DIT_7 = "DIT_7",
  DIT_16 = "DIT_16",
  DIT_19 = "DIT_19",
  EXPORT_DELIVERY = "EXPORT_DELIVERY",
  INCOME_0 = "INCOME_0",
  INCOME_0_ITD = "INCOME_0_ITD",
  INCOME_5 = "INCOME_5",
  INCOME_7 = "INCOME_7",
  INCOME_13B5_USTG = "INCOME_13B5_USTG",
  INCOME_16 = "INCOME_16",
  INCOME_19 = "INCOME_19",
  INCOME_EU_B2B = "INCOME_EU_B2B",
  INCOME_EU_B2C_5 = "INCOME_EU_B2C_5",
  INCOME_EU_B2C_7 = "INCOME_EU_B2C_7",
  INCOME_EU_B2C_16 = "INCOME_EU_B2C_16",
  INCOME_EU_B2C_19 = "INCOME_EU_B2C_19",
  INCOME_EU_INTRA_B2B = "INCOME_EU_INTRA_B2B",
  INCOME_EU_INTRA_B2C_5 = "INCOME_EU_INTRA_B2C_5",
  INCOME_EU_INTRA_B2C_7 = "INCOME_EU_INTRA_B2C_7",
  INCOME_EU_INTRA_B2C_16 = "INCOME_EU_INTRA_B2C_16",
  INCOME_EU_INTRA_B2C_19 = "INCOME_EU_INTRA_B2C_19",
  INTRA_ACQUISITION_IT = "INTRA_ACQUISITION_IT",
  NON_TAXABLE = "NON_TAXABLE",
  NO_ITD = "NO_ITD",
  NO_VAT = "NO_VAT",
  REVERSE_CHARGE = "REVERSE_CHARGE",
  REVERSE_CHARGE_IT = "REVERSE_CHARGE_IT",
}

export enum VatRate {
  REVERSE_CHARGE = "REVERSE_CHARGE",
  VAT_0 = "VAT_0",
  VAT_5 = "VAT_5",
  VAT_7 = "VAT_7",
  VAT_16 = "VAT_16",
  VAT_19 = "VAT_19",
}
