import {
  BusinessAssetMetaData,
  BusinessAssetType,
  DepreciableCategory,
  ITransaction,
  TransactionSplit,
} from "../../types";
import { CUSTOM_ASSET_CLASS, DEPRECIABLE_CATEGORY_CODES } from "./constants";

export const isImmovableAsset = (assetType?: BusinessAssetType): boolean =>
  assetType === BusinessAssetType.IMMOVABLE;

export const isIntangibleAsset = (assetType?: BusinessAssetType): boolean =>
  assetType === BusinessAssetType.INTANGIBLE;

export const isCustomAssetClass = (assetClass?: string): boolean =>
  assetClass === CUSTOM_ASSET_CLASS;

export const isDepreciableCategoryCode = (
  categoryCode?: string | null
): categoryCode is DepreciableCategory =>
  !!categoryCode && DEPRECIABLE_CATEGORY_CODES.includes(categoryCode);

type TransactionWithBusinessAssets<
  T extends Pick<BusinessAssetMetaData, "businessAssetableId">
> = Pick<ITransaction, "id" | "categoryCode"> & {
  businessAssets?: T[];
  splits?: Partial<Pick<TransactionSplit, "uuid" | "categoryCode">>[];
};

export const getToBeDeletedBusinessAssets = <
  T extends Pick<BusinessAssetMetaData, "businessAssetableId">
>(
  transaction: TransactionWithBusinessAssets<T>
): T[] => {
  if (!transaction.businessAssets?.length) {
    return [];
  }

  const isDepreciableTransaction = isDepreciableCategoryCode(
    transaction.categoryCode
  );

  return transaction.businessAssets.filter((businessAsset) => {
    if (
      isDepreciableTransaction &&
      businessAsset.businessAssetableId === transaction.id
    ) {
      return false;
    }

    return !transaction.splits?.some(
      (split) =>
        split.uuid === businessAsset.businessAssetableId &&
        isDepreciableCategoryCode(split.categoryCode)
    );
  });
};
