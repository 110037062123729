import moment, { Moment } from "moment-timezone";
import mime from "mime/lite";
import isEmpty from "lodash/isEmpty";
import { notification } from "antd";

import { BusinessAssetForm, TransactionSplit } from "../../types";

import { DEFAULT_LOCALE } from "../../constants";

// @TODO: replace this by getLocalUrlForFile
export const getAsset = (data: any, createObjectURL = URL.createObjectURL) => {
  const type = data?.type || "application/pdf";
  const file = new Blob([data], { type });
  const fileURL = createObjectURL(file);
  return { fullsize: fileURL, filetype: type.split("/").pop() };
};

export const getLocalUrlForFile = ({
  name,
  file,
  filetype,
}: {
  name: string;
  file: File;
  filetype: string;
}): { name: string; fullsize: string; filetype: string } => {
  const blob = new Blob([file], {
    type: mime.getType(filetype) as string,
  });

  const fileURL = URL.createObjectURL(blob);

  return {
    name,
    fullsize: fileURL,
    filetype,
  };
};

export const BERLIN_TIMEZONE = "Europe/Berlin";

/**
 * Returns the point in time a given day starts in the Europe/Berlin timezone
 * @param  {string} day The denoted day in YYYY-MM-DD[...] format
 * @return {string} Localized ISO 8601 representation of the day's starting point
 */
export const startOfBerlinDay = (day?: string): string =>
  moment.tz(day, BERLIN_TIMEZONE).startOf("day").format();

/**
 * Returns the point in time a given day ends in the Europe/Berlin timezone
 * @param  {string} day The denoted day in YYYY-MM-DD[...] format
 * @return {string} Localized ISO 8601 representation of the day's ending point
 */
export const endOfBerlinDay = (day?: string): string =>
  moment.tz(day, BERLIN_TIMEZONE).endOf("day").format();

/**
 * Returns the point in time a given day starts in the Europe/Berlin timezone
 * @param  {Moment} day
 * @return {string} Localized ISO 8601 representation of the day's starting point
 */
export const startOfBerlinDayMoment = (date: Moment): string =>
  date.tz(BERLIN_TIMEZONE).startOf("day").format();

/**
 * Returns the point in time a given day ends in the Europe/Berlin timezone
 * @param  {Moment} day
 * @return {string} Localized ISO 8601 representation of the day's starting point
 */
export const endOfBerlinDayMoment = (date: Moment): string =>
  date.tz(BERLIN_TIMEZONE).endOf("day").format();

/**
 * Returns a berlin's moment timezone based on a date.
 */
export const getBerlinMomentTimezone = (date?: Date | string) =>
  moment.tz(date, BERLIN_TIMEZONE);

const isSplitBusinessAssetValid = ({
  assetClass,
  assetClassCustom,
  depreciationYears,
}: BusinessAssetForm): boolean => {
  return !((!assetClassCustom && !assetClass) || !depreciationYears);
};

export const isSplitValid = (
  splits: Array<Partial<TransactionSplit>>,
  transactionAmount?: number
) => {
  if (splits.length < 2) {
    return false;
  }

  if (
    transactionAmount &&
    transactionAmount !==
      splits.reduce((result, split) => (result += split?.amount || 0), 0)
  ) {
    return false;
  }

  return splits.every(
    ({
      amount,
      categoryCode: kontaxCategoryCode,
      vatCategoryCode,
      businessAssetForm,
    }) => {
      const isSplitDataValid = amount && kontaxCategoryCode && vatCategoryCode;

      if (businessAssetForm && !isEmpty(businessAssetForm)) {
        return isSplitDataValid && isSplitBusinessAssetValid(businessAssetForm);
      }

      return isSplitDataValid;
    }
  );
};

export const generateFinanzamtLink = (taxNumber: string | undefined) => {
  if (!taxNumber) return;
  const url =
    "https://www.bzst.de/DE/Service/Behoerdenwegweiser/Finanzamtsuche/GemFa/finanzamtsuche_Formular.html?suche=";
  return `${url}${taxNumber.substr(0, 4)}`;
};

export const numberParser = (val: any) => {
  try {
    if (typeof val === "string" && !val.length) {
      val = "0.0";
    }
    const group = new Intl.NumberFormat(DEFAULT_LOCALE)
      .format(1111)
      .replace(/1/g, "");
    const decimal = new Intl.NumberFormat(DEFAULT_LOCALE)
      .format(1.1)
      .replace(/1/g, "");
    let reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    reversedVal = reversedVal.replace(/[^0-9.]/g, "");
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;
    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }
    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    notification.error({
      message: `An error occurred: Could not parse input (${error})`,
    });
    return 0;
  }
};

/**
 * Returns a Blob object of the input data string
 * @param  {string} data
 * @return {Blob} Blob, a file-like object of immutable, raw data
 */
export const base64toBlob = (data: string): Blob => {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: "application/pdf" });
};

/**
 * Returns singular or plural of the input word depending on count
 * works only with regular nouns provided in singular
 * @param  {number} count
 * @param  {string} word
 * @return {string} the word in singular or plural
 */
export const getSingularOrPlural = (count: number, word: string): string => {
  const lastDigit = (count + "").slice(-1);
  return lastDigit === "1" ? word : word + "s";
};
