import React from "react";

import VatCategorySelect from "../../TransactionView/VatCategorySelect";
import {
  KontaxTransactionMeta,
  ValueCategorizationType,
} from "../../../../types";
import { TransactionRow } from "./types";

export const TransactionsVatCategory = ({
  record,
  updateTransactionsMeta,
}: {
  record: TransactionRow;
  updateTransactionsMeta: (
    payload: Array<KontaxTransactionMeta>
  ) => Promise<boolean>;
}) => {
  return record.splits?.length ? (
    <i data-test="splitNoticeForVatCategory">
      Split transaction. Please expand + for more details
    </i>
  ) : (
    <VatCategorySelect
      value={record.vatCategoryCode}
      meta={record.vatCategoryCodeMeta}
      disabled={record.verified}
      kontaxCategory={record.categoryCode}
      vatYearPaymentFrequency={record.vatYearPaymentFrequency}
      updateValueOnCategoryChange={true}
      onChangeHandler={(vatCategoryCode: string | null) => {
        updateTransactionsMeta([
          {
            kontistTransactionId: record.id,
            vatCategoryCode,
            vatCategoryCodeMeta: {
              label: undefined,
              suggestionSource: undefined,
              categorizationType: ValueCategorizationType.TAX_OPS,
            },
          },
        ]);
      }}
    />
  );
};
