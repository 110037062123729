import React, { useEffect, useCallback } from "react";

import BusinessAsset from "../../../pages/TransactionView/BusinessAsset";
import {
  BusinessAssetFormField,
  BusinessAssetType,
  DepreciableCategory,
  BusinessAssetForm,
  CategoryCodeType,
} from "../../../../types";
import { LATEST_POSSIBLE_PURCHASE_DATE } from "../../../BusinessAsset/constants";
import { getDepreciationYears } from "./utils";

const ExternalBusinessAsset = ({
  isSubmitClicked,
  onChange,
  businessAssetForm,
  categoryCode,
}: {
  isSubmitClicked: boolean;
  onChange: (businessAssetForm: BusinessAssetForm) => void;
  businessAssetForm: BusinessAssetForm;
  categoryCode?: CategoryCodeType;
}) => {
  const onAssetTypeChangeHandler = useCallback(
    (value: string) => {
      onChange({
        ...businessAssetForm,
        assetType: value as BusinessAssetType,
        assetClass: undefined,
        // On every asset type change,
        // reset assetClassCustom and depreciationYears values
        assetClassCustom: "",
        depreciationYears: "",
      });
    },
    [businessAssetForm, onChange]
  );

  const onFormFieldChangeHandler = (
    field: BusinessAssetFormField,
    value: string
  ) =>
    onChange({
      ...businessAssetForm,
      [field]: value,
    });

  const onAssetClassChangeHandler = (value: string) => {
    onChange({
      ...businessAssetForm,
      assetClass: value,
      assetClassCustom: "",
      depreciationYears: getDepreciationYears({
        categoryCode: categoryCode?.valueOf(),
        assetClass: value,
        assetType: businessAssetForm.assetType,
      }),
    });
  };

  /**
   * Updates the form data based on the provided field name and value (on the event).
   */
  const onBusinessAssetFormDataChange = (
    field: BusinessAssetFormField,
    value: string
  ) => {
    switch (field) {
      case BusinessAssetFormField.ASSET_CLASS:
        onAssetClassChangeHandler(value);
        break;
      case BusinessAssetFormField.ASSET_TYPE:
        onAssetTypeChangeHandler(value);
        break;
      // For assetClass, purchaseDate & depreciationYears handling is more generic.
      case BusinessAssetFormField.ASSET_CLASS_CUSTOM:
      case BusinessAssetFormField.PURCHASE_DATE:
      case BusinessAssetFormField.DEPRECIATION_YEARS:
        onFormFieldChangeHandler(field, value);
        break;
    }
  };

  useEffect(() => {
    if (!businessAssetForm.assetType) {
      onAssetTypeChangeHandler(BusinessAssetType.MOVABLE_OTHERS);
    }
  }, [onAssetTypeChangeHandler, businessAssetForm.assetType]);

  return (
    <BusinessAsset
      businessAssetFormData={{
        assetType:
          businessAssetForm.assetType || BusinessAssetType.MOVABLE_OTHERS,
        assetClass: businessAssetForm.assetClass || "",
        assetClassCustom: businessAssetForm.assetClassCustom || "",
        purchaseDate: businessAssetForm.purchaseDate,
        purchaseDateMax: LATEST_POSSIBLE_PURCHASE_DATE,
        depreciationYears: businessAssetForm.depreciationYears || "",
        depreciationYearsEditable:
          businessAssetForm?.depreciationYearsEditable ||
          categoryCode?.valueOf() === DepreciableCategory.OVER_800,
      }}
      onFieldChange={onBusinessAssetFormDataChange}
      isSubmitClicked={isSubmitClicked}
    />
  );
};

export default ExternalBusinessAsset;
