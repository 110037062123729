import {
  CalculatorOutlined,
  PieChartOutlined,
  TeamOutlined,
  ToolOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { ComponentType } from "react";

import { PermissionsScope } from "../../../types";

import TaxYearSelect from "../../pages/TaxDeclaration/components/TaxYearSelect";
import { LanguageToggle } from "./LanguageToggle";

import { NavigationLink } from "./LeftNavItem";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../constants";

const logout = () => {
  const auth2 = window.gapi.auth2.getAuthInstance();
  auth2.signOut();
};

export type NavigationItem =
  | NavigationLink
  | {
      component: ComponentType;
      label: string;
      icon: ComponentType;
      requiredScope?: PermissionsScope[];
      featureFlag?: string;
    };

export type NavigationConfig = Array<NavigationSection>;

export interface NavigationSection {
  icon?: ComponentType;
  label: string;
  children: NavigationItem[];
}

export const navigationItems: NavigationConfig = [
  {
    icon: TeamOutlined,
    label: "Mandanten",
    children: [
      {
        path: "/",
        label: "Alle Mandanten",
        requiredScope: [
          ...TAX_ADVISORY_PERMISSION_SCOPES,
          PermissionsScope.DIY,
        ],
        preserveQueryParams: true,
      },
      {
        path: "/onboarding",
        label: "Onboarding",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/tax-account-view",
        label: "Steuerkontoabfrage",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/tax-service-view",
        label: "serviceTracker",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/activity-tracking",
        label: "Activity Tracking",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
        featureFlag: "ACTIVITY_TRACKING_FEATURE",
      },
    ],
  },
  {
    icon: CalculatorOutlined,
    label: "Accounting",
    children: [
      {
        path: "/transaction-view",
        label: "Transaction",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/belege-view",
        label: "Belege",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/verification-view",
        label: "Verification",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/vat-view",
        label: "VAT",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/bwa-view",
        label: "BWA",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/rules-view",
        label: "Rules",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/feedback-view",
        label: "Feedback",
        requiredScope: [PermissionsScope.TAX_SUPERVISOR],
      },
      {
        path: "/annotation-tag-view",
        label: "Annotation Tag",
        requiredScope: [PermissionsScope.TAX_SUPERVISOR],
      },
    ],
  },
  {
    icon: PieChartOutlined,
    label: "Steuer",
    children: [
      {
        component: TaxYearSelect,
        label: "Year",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
      },
      {
        path: "/tax-declaration/tax-home",
        label: "Steuer Home",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/tax-declaration/tax-preparation",
        label: "Vorbereitung",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/tax-declaration/euer-declaration",
        label: "Anlage EÜR inkl. AV",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/tax-declaration/vat-annual-declaration",
        label: "Umsatzsteuererkl.",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/tax-declaration/trade-tax-declaration",
        label: "Gewerbesteuererkl.",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
      {
        path: "/tax-declaration/income-tax-declaration",
        label: "Einkommensteuererkl.",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
        preserveQueryParams: true,
      },
    ],
  },
  {
    icon: SnippetsOutlined,
    label: "Templates",
    children: [
      {
        path: "/anschreiben-template",
        label: "Anschreiben",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
      },
    ],
  },
  {
    icon: ToolOutlined,
    label: "Settings",
    children: [
      {
        path: "/tax-ops-permission-view",
        label: "Permission",
        requiredScope: TAX_ADVISORY_PERMISSION_SCOPES,
      },
      { onClick: logout, label: "Logout" },
      { component: LanguageToggle, label: "Language" },
    ],
  },
];
