import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { PaymentFrequency, KontaxPlan } from "./IUser.type";
import {
  BusinessAssetType,
  ICreateSplitBusinessAssetPayload,
} from "./IBusinessAsset.type";
import { BWAStatsQueryVariables } from "../api/graphql";

export enum VatRate {
  VAT_0 = "0",
  VAT_5 = "5",
  VAT_7 = "7",
  VAT_16 = "16",
  VAT_19 = "19",
}

export enum Direction {
  INCOMING = 1,
  OUTGOING = -1,
}

export enum FilterSource {
  BANK = "BANK",
  EXTERNAL = "EXTERNAL",
}

export enum TransactionSource {
  SOLARIS = "SOLARIS",
  BACKOFFICE_MANUAL = "BACKOFFICE_MANUAL",
  DATEV = "DATEV",
  LEXOFFICE = "LEXOFFICE",
  DEBITOOR = "DEBITOOR",
  SEVDESK = "SEVDESK",
  FREE_TEXT = "FREE_TEXT",
  USER = "USER",
}

export enum ExternalTransactionType {
  "EXTERNAL_TRANSACTION" = "EXTERNAL_TRANSACTION",
  "EXTERNAL_TRANSACTION_CASH" = "EXTERNAL_TRANSACTION_CASH",
}

export type KontaxTransactionFilterOptions = {
  categorized?: boolean;
  escalated?: boolean;
  actionReason?: boolean;
  email?: string;
  direction?: Direction;
  dateFrom?: string;
  dateTo?: string;
  assets?: boolean;
  vatPaymentFrequency?: PaymentFrequency;
  plans?: Array<KontaxPlan>;
  categories?: CheckboxValueType[];
  vatCategoryCodes?: CheckboxValueType[];
  source?: FilterSource;
  verified?: boolean;
  includeAll?: boolean;
};

export interface BWAFilterOptions extends BWAStatsQueryVariables {}

export interface Asset {
  id?: string;
  fullsize: string;
  filetype: string;
  rotationAngle?: number;
}

export enum ValueCategorizationType {
  AUTOMATIC_KONTAX_RULE = "AUTOMATIC_KONTAX_RULE",
  TAX_OPS = "TAX_OPS",
  USER_OVERWRITE = "USER_OVERWRITE",
  STANDING_ORDER = "STANDING_ORDER",
  BOOKED_BY_ML = "BOOKED_BY_ML",
  INVOICING = "INVOICING",
  RECURRING_PAYMENT = "RECURRING_PAYMENT",
  SCRIPT = "SCRIPT",
  SCRIPT_UNKNOWN = "SCRIPT_UNKNOWN",
  DATEV = "DATEV",
  PROPAGATED = "PROPAGATED",
}

export enum ValueSuggestionSource {
  KONTIST_ML = "Kontist",
  USER = "User",
  KONTAX_ML = "ML",
  KONTAX_OCR = "OCR",
  BOOKKEEPING_PARTNER = "Bookkeeping Partner",
  INVOICING = "Kontist Invoicing",
}

export interface ValueMeta {
  label?: string;
  description?: string;
  categorizationType?: ValueCategorizationType;
  suggestionSource?: ValueSuggestionSource;
}

export enum KontistTransactionCategory {
  PRIVATE = "private",
  VAT = "vat",
  VAT_0 = "vat0",
  VAT_5 = "vat5",
  VAT_7 = "vat7",
  VAT_16 = "vat16",
  VAT_19 = "vat19",
  TAX_PAYMENT = "taxPayment",
  VAT_PAYMENT = "vatPayment",
  TAX_REFUND = "taxRefund",
  VAT_REFUND = "vatRefund",
  VAT_SAVING = "vatSaving",
  TAX_SAVING = "taxSaving",
}

export enum KontistCategorizationType {
  USER,
  KONTAX,
}

export type BusinessAssetForm = {
  assetType?: BusinessAssetType;
  assetClass?: string;
  assetClassCustom?: string;
  purchaseDate?: string;
  depreciationYears?: string;
  depreciationPeriodYears?: number;
  depreciationYearsEditable?: boolean;
};

export type BusinessAssetMetaData = {
  businessAssetableId: string;
  businessAssetableType: string;
  assetClass: string;
  purchaseDate: string;
  amount: number;
};

export type TransactionSplit = {
  id: number;
  uuid: string;
  amount: number;
  userSelectedBookingDate?: Date;
  transactionId: string;
  kontaxCategoryId?: number;
  categoryCode?: string;
  vatCategoryCode: string | null;
  businessAssetForm?: BusinessAssetForm;
  businessAsset?: ICreateSplitBusinessAssetPayload;
};

export interface Transaction {
  id: string;
  name: string | null;
  type?: string;
  valutaDate: string;
  categoryCode?: string | null;
  categoryCodeMeta?: ValueMeta;
  vatCategoryCode?: string | null;
  vatCategoryCodeMeta?: ValueMeta;
  escalated?: boolean;
  assets?: Array<Asset>;
  source?: TransactionSource;
  description?: string | null;
  iban?: string | null;
  amount: number;
  verified?: boolean;
  verifiedBy?: string;
  foreignCurrency?: string;
  internalNote?: string;
  hasIntegrationDocument?: boolean;
  splits?: Array<TransactionSplit>;
  isSplitCategorized?: boolean;
  businessAssets?: Array<BusinessAssetMetaData>;
  submittedCategoryCode?: string | null;
  submittedVatCategoryCode?: string | null;
}

export type ITransaction = Transaction & {
  email: string;
  firstName: string;
  lastName: string;
  businessTypeComment?: string | null;
  personalNote?: string | null;
  invoiceRequestedAt?: string;
  vatYearPaymentFrequency?: PaymentFrequency;
  recurringPaymentRuleId?: number;
};

export type KontaxTransactionMeta = Partial<ITransaction> & {
  kontistTransactionId?: string;
};

export type UpdateTransactionState = {
  businessAssets?: BusinessAssetMetaData[];
  oldCategoryCode?: string | null;
  newCategoryCode?: string | null;
  kontistTransactionId: string;
  skipConfirmationPopup?: boolean;
  verified?: boolean;
};
