import React, { MouseEventHandler } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AddExternalTransactionButton = ({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <>
      <Button
        data-test="addVirtualTransactionButton"
        icon={<PlusOutlined />}
        size="middle"
        style={{ display: "flex", alignItems: "center" }}
        onClick={onClick}
      >
        Add transaction
      </Button>
    </>
  );
};

export default AddExternalTransactionButton;
