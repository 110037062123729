import React from "react";

import { UpdateTransactionState } from "../../../../types";
import CategorySelect from "../../TransactionView/CategorySelect";
import { TransactionRow } from "./types";
import {
  BUSINESS_ASSET_CATEGORIES,
  EXTERNAL_TRANSACTION_CATEGORIES,
} from "../../../../utils";

export const TransactionsTableCategory = ({
  record,
  handleUpdateTransaction,
}: {
  record: TransactionRow;
  handleUpdateTransaction: (payload: UpdateTransactionState) => Promise<void>;
}) => {
  return record.splits?.length ? (
    <i data-test="splitNoticeForCategory">
      Split transaction. Please expand + for more details
    </i>
  ) : (
    <CategorySelect
      value={record.categoryCode}
      source={record.source}
      type={record.type}
      meta={record.categoryCodeMeta}
      transactionAmount={record.amount}
      disabledOptions={[
        ...EXTERNAL_TRANSACTION_CATEGORIES,
        ...BUSINESS_ASSET_CATEGORIES,
      ]}
      disabled={record.verified}
      onChangeHandler={(newCategoryCode) =>
        handleUpdateTransaction({
          businessAssets: record.businessAssets,
          oldCategoryCode: record.categoryCode,
          newCategoryCode,
          kontistTransactionId: record.id,
        })
      }
    />
  );
};
