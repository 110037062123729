import React, { useCallback } from "react";
import { Button } from "antd";
import styled from "styled-components";

import { ITransaction, KontaxTransactionMeta } from "../../../../types";
import { ButtonsWrapper } from "../../../common/styledComponents";
import AddExternalTransactionButton from "./AddExternalTransactionButton";
import { findTransactionsToConfirm } from "./helpers";
import colors from "../../../../themes/colors";

const Container = styled.div`
  position: sticky;
  top: 17px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  z-index: 5;
  background-color: ${colors.white};
  padding: 15px 0;
`;

type Props = {
  addExternalTransactionClick: Function;
  transactions: ITransaction[];
  updateTransactionsMeta: (
    payload: Array<KontaxTransactionMeta>
  ) => Promise<boolean>;
  updateTransactionsState: Function;
  hasSelectedTransactions: boolean;
  hasSelectedVerifiedTransactionsOnly?: boolean;
  verifySelectedTransactions: () => void;
  toggleShowCategorizeBatch: () => void;
  isNested?: boolean;
};

export const TransactionsTableButtons = ({
  addExternalTransactionClick,
  transactions,
  updateTransactionsMeta,
  hasSelectedTransactions,
  hasSelectedVerifiedTransactionsOnly,
  verifySelectedTransactions,
  toggleShowCategorizeBatch,
  isNested,
}: Props) => {
  const confirmAllSuggested = useCallback(() => {
    if (!transactions) {
      return;
    }
    const transactionsToUpdate = findTransactionsToConfirm(transactions);
    if (transactionsToUpdate.length) {
      updateTransactionsMeta(transactionsToUpdate);
    }
  }, [transactions, updateTransactionsMeta]);

  return (
    <Container>
      <div>
        <Button
          disabled={!hasSelectedTransactions}
          onClick={verifySelectedTransactions}
          size="middle"
          name="verify-selected-button"
          data-test={
            hasSelectedVerifiedTransactionsOnly
              ? "unVerifySelectedButton"
              : "verifySelectedButton"
          }
        >
          {hasSelectedVerifiedTransactionsOnly
            ? "Unverify Selected"
            : "Verify Selected"}
        </Button>
        <Button
          data-test="categorizeSelectedButton"
          disabled={!hasSelectedTransactions}
          onClick={toggleShowCategorizeBatch}
          size="middle"
          name="categorize-selected-button"
          style={{ marginLeft: "8px" }}
        >
          Categorize Selected
        </Button>
      </div>
      {!isNested && (
        <ButtonsWrapper>
          <AddExternalTransactionButton
            onClick={() => {
              addExternalTransactionClick(true);
            }}
          />
          {!!transactions.length && (
            <>
              <Button
                data-test="confirmedButton"
                disabled={!findTransactionsToConfirm(transactions).length}
                onClick={confirmAllSuggested}
                size="middle"
                name="confrim-button"
              >
                Confirm all
              </Button>
            </>
          )}
        </ButtonsWrapper>
      )}
    </Container>
  );
};
