import React, { ChangeEvent } from "react";
import isEmpty from "lodash/isEmpty";

import { InputWrapper, Label } from "../../../../../common/styledComponents";
import FormField from "../../../../../common/FormField";
import BusinessAssetTypeSelect from "../../../../../BusinessAsset/BusinessAssetTypeSelect";
import BusinessAssetClassSelect from "../../../../../BusinessAsset/BusinessAssetClassSelect";
import {
  BusinessAssetFormField,
  IBusinessAssetForm,
} from "../../../../../../types";
import movableAssets from "../../../../../BusinessAsset/movableAssets.json";
import immovableAssets, {
  LAND_ASSET_CLASS,
} from "../../../../../BusinessAsset/immovableAssets";
import {
  isImmovableAsset,
  isIntangibleAsset,
  isCustomAssetClass,
} from "../../../../../BusinessAsset/utils";

/**
 * The Update form for business asset.
 * It allows us to modify "assetType", "assetClass", and "depreciationYears".
 */
const BusinessAssetUpdateForm = ({
  businessAssetFormData,
  onFieldChange,
}: IBusinessAssetForm) => {
  const {
    assetType,
    assetClass,
    assetClassCustom,
    depreciationYears,
    depreciationYearsEditable,
  } = businessAssetFormData;
  const isAssetTypeImmovable = isImmovableAsset(assetType);
  const isAssetTypeIntangible = isIntangibleAsset(assetType);
  const isAssetClassCustom = isCustomAssetClass(assetClass);

  const getOnChangeHandler =
    (field: BusinessAssetFormField) =>
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      onFieldChange(field, event.target.value);

  const shouldRenderEditableDepreciationYearsField =
    isAssetTypeImmovable || isAssetTypeIntangible || isAssetClassCustom;

  return (
    <div className="mb-3">
      <InputWrapper>
        <Label htmlFor="assetType">AfA-Klasse</Label>
        <BusinessAssetTypeSelect
          id="assetType"
          value={assetType}
          onChangeHandler={getOnChangeHandler(
            BusinessAssetFormField.ASSET_TYPE
          )}
        />
      </InputWrapper>

      {!isAssetTypeIntangible && (
        <InputWrapper>
          <Label htmlFor="assetClass">Anlage-Typ</Label>
          <BusinessAssetClassSelect
            id="assetClass"
            value={assetClass}
            options={isAssetTypeImmovable ? immovableAssets : movableAssets}
            required
            onChange={(value) =>
              onFieldChange(BusinessAssetFormField.ASSET_CLASS, value)
            }
          />
        </InputWrapper>
      )}

      {(isAssetTypeIntangible || isAssetClassCustom) && (
        <FormField
          id="assetClassCustom"
          value={assetClassCustom}
          label={isAssetTypeIntangible ? "Anlage-Typ" : "Beschreibung"}
          required
          onChange={getOnChangeHandler(
            BusinessAssetFormField.ASSET_CLASS_CUSTOM
          )}
        />
      )}

      {
        // land doesn't depreciate
        assetClass !== LAND_ASSET_CLASS &&
          (depreciationYearsEditable &&
          shouldRenderEditableDepreciationYearsField ? (
            <FormField
              id="depreciationYearsEditable"
              value={depreciationYears}
              type="number"
              label="Abschreibungsdauer"
              textAfter="Jahre"
              required
              onChange={getOnChangeHandler(
                BusinessAssetFormField.DEPRECIATION_YEARS
              )}
            />
          ) : (
            <FormField
              disabled
              className="no-background"
              id="depreciationYears"
              value={
                isEmpty(depreciationYears) ? "" : `${depreciationYears} Jahre`
              }
              label="Abschreibungsdauer"
            />
          ))
      }
    </div>
  );
};

export default BusinessAssetUpdateForm;
