import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Row, Col, Spin, notification } from "antd";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";

import {
  EuerDeclarationOfficeUsageSubformCalculationResults,
  EuerDeclarationOfficeUsageSubformFullCostOwnHomeInputs,
} from "@kontist/euer-declaration";

import {
  RowProps,
  IUser,
  IFormattedBusinessAsset,
  IExitBusinessAssetPayload,
  IUpdateBusinessAssetPayload,
  ICreateBusinessAssetPayload,
} from "../../../../../../types";
import ReactTable from "../../../../../common/ReactTable";
import {
  Container,
  SubHeader,
  RowGroup,
  Col as StyledCol,
  SubComponent,
  SpinnerContainer,
  YearFilterSelect,
} from "./styledComponents";
import colors from "../../../../../../themes/colors";
import { SpinnerBasic } from "../../../../../common/Spinner";
import { Header } from "../../../../../common/styledComponents";
import api from "../../../../../../api";
import { uploadFile } from "../../../../../../api/modules/Common";
import BusinessAssetConfirmationModal from "../../../../../BusinessAsset/BusinessAssetConfirmationModal";
import {
  getFormattedBusinessAssets,
  getUTCYear,
  getYearsList,
  translateExitReason,
} from "./utils";
import ReceiptsModal from "./ReceiptsModal";
import InfoBlock from "./InfoBlock";
import BusinessAssetCreateForm from "./BusinessAssetCreateForm";
import EditBusinessAsset from "./EditBusinessAsset";
import BusinessAssetsStatusChangeModal from "./BusinessAssetsStatusChangeModal";
import {
  EuerDeclarationFields,
  useBusinessAssetsQuery,
  useUpdateEuerDeclarationSubformMutation,
} from "../../../../../../api/graphql";
import {
  EuerDeclarationSubformType,
  EuerDeclarationSubformStatus,
  KontaxNoteType,
  ExitReason,
} from "../../../../../../api/graphql/schema.generated";
import useTaxYearParam, {
  getTaxYearOptions,
} from "../../../../TaxDeclaration/hooks/useTaxYearParam";
import { LAND_ASSET_CLASS } from "../../../../../BusinessAsset/immovableAssets";
import {
  destroyMessage,
  showErrorMessage,
  showSuccessMessage,
  showLoadingMessage,
} from "../../../../../../utils";
import { useDeleteBusinessAssetMutation } from "../../../../../../api/graphql";
import DeclarationStatus from "../../../../TaxDeclaration/components/DeclarationStatus";
import { SUBFORM_STATUS_MAPPINGS } from "../../../../TaxDeclaration/pages/EuerDeclaration/constants";
import ActionLogDrawerAsync from "../../../../../common/ActionLogDrawerAsync";
import { useEuerDeclarationSubformQuery } from "../../../../../../api/graphql/queries/euerDeclarationSubform.generated";
import { ActionsContainer } from "../../../../TaxDeclaration/styles";
import NotesDrawer from "../../../../../common/NotesDrawer";
import { TAX_ADVISORY_PERMISSION_SCOPES } from "../../../../../../constants";
import { getPermissionScope } from "../../../../../../gapi";

const LOADING_MESSAGE_KEY = "saving-business-assets-status";

type BusinessAssetRowProps = RowProps<IFormattedBusinessAsset>;

type ProcessingAsset = {
  url: string;
  assetId: string;
  formData: Array<{ key: string; value: string }>;
  name?: string;
};

const getPriceText = ({
  exitReason,
  isExitedWithVat,
}: {
  exitReason?: ExitReason;
  isExitedWithVat?: boolean;
}) => {
  if (exitReason === ExitReason.PRIVATE_USE) {
    return isExitedWithVat
      ? "Entnahmewert zzgl. USt"
      : "Entnahmewert ohne zzgl. USt";
  }

  if (exitReason === ExitReason.SOLD) {
    return isExitedWithVat
      ? "Verkaufspreis mit USt"
      : "Verkaufspreis ohne zzgl. USt";
  }

  return "Verkaufspreis";
};

const BusinessAssetList = ({
  user,
  setBusinessAssetsCount,
}: {
  user: IUser;
  setBusinessAssetsCount: Function;
}) => {
  const { email } = user;
  const currentYear = getUTCYear();
  const hasTaxAdvisoryPermissionScope = TAX_ADVISORY_PERMISSION_SCOPES.includes(
    getPermissionScope()
  );
  const {
    loading: isLoadingBusinessAssets,
    error: businessAssetsError,
    data: businessAssetsData,
    refetch: refetchBusinessAssets,
  } = useBusinessAssetsQuery({
    variables: { email },
  });

  const yearsList = useMemo(() => {
    const businessAssetDepreciationYears = getYearsList(
      businessAssetsData?.businessAssets || []
    );
    // Include tax years in the list to be able to change status for the EUER declaration business assets subform
    const taxYears = getTaxYearOptions();
    return sortBy(
      uniq([...businessAssetDepreciationYears, ...taxYears]),
      (year) => year
    );
  }, [businessAssetsData]);

  const [taxYear] = useTaxYearParam(currentYear);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [snapshotYear, setSnapshotYear] = useState<number>(taxYear);
  const [formattedBusinessAssets, setFormattedBusinessAssets] = useState<
    IFormattedBusinessAsset[]
  >([]);
  const [selectedBusinessAsset, setSelectedBusinessAsset] = useState<
    IFormattedBusinessAsset | undefined
  >();
  const [businessAssetIds, setBusinessAssetIds] = useState<string[]>([]);
  const [businessAssetSubTexts, setBusinessAssetSubTexts] = useState<
    Record<string, string>
  >({});

  const [isStatusChangeModalVisible, setIsStatusChangeModalVisible] =
    useState(false);

  const showStatusChangeModal = useCallback(() => {
    setIsStatusChangeModalVisible(true);
  }, []);

  const handleStatusChangeModalClose = useCallback(() => {
    setIsStatusChangeModalVisible(false);
  }, []);

  useEffect(() => {
    const fetchedBusinessAssets = businessAssetsData?.businessAssets || [];
    setBusinessAssetsCount(fetchedBusinessAssets.length);
  }, [businessAssetsData, setBusinessAssetsCount]);

  useEffect(() => {
    if (businessAssetsError) {
      notification.error({
        message: `An error occurred while fetching the business assets for the user ${email}`,
      });
    }
  }, [businessAssetsError, email]);

  const { data: subformData, refetch: refetchSubform } =
    useEuerDeclarationSubformQuery({
      variables: {
        email,
        year: snapshotYear,
        type: EuerDeclarationSubformType.BUSINESS_ASSETS,
      },
      skip: !hasTaxAdvisoryPermissionScope,
    });
  const subform = subformData?.euerDeclarationSubform;

  const refetchBusinessAssetsAndSubform = useCallback(
    () => Promise.all([refetchBusinessAssets(), refetchSubform()]),
    [refetchBusinessAssets, refetchSubform]
  );

  const [updateEuerDeclarationSubform] =
    useUpdateEuerDeclarationSubformMutation();

  const saveStatus = useCallback(
    async (status: EuerDeclarationSubformStatus) => {
      if (!subform) {
        return;
      }

      showLoadingMessage(LOADING_MESSAGE_KEY);

      try {
        const inputs =
          subform.inputs as unknown as EuerDeclarationOfficeUsageSubformFullCostOwnHomeInputs;
        const calculationResults =
          subform.calculationResults as unknown as EuerDeclarationOfficeUsageSubformCalculationResults;
        const calculationMethod = subform.calculationMethod;
        await updateEuerDeclarationSubform({
          variables: {
            email,
            year: snapshotYear,
            type: EuerDeclarationSubformType.BUSINESS_ASSETS,
            payload: {
              calculationMethod,
              calculationResults,
              inputs,
              status,
            },
          },
        });
        await refetchSubform();
        showSuccessMessage("Successfully saved");
      } catch (error) {
        showErrorMessage("There was a problem updating the status");
      } finally {
        destroyMessage(LOADING_MESSAGE_KEY);
      }
    },
    [email, refetchSubform, snapshotYear, subform, updateEuerDeclarationSubform]
  );

  const createBusinessAsset = useCallback(
    async (data: ICreateBusinessAssetPayload) => {
      const response = await api.kontax.createBusinessAsset({
        email,
        businessAsset: data,
      });
      const processingAssets: ProcessingAsset[] =
        response.data?.processingAssets;

      // Upload the file to s3 with the required data (formData, file)
      if (processingAssets) {
        try {
          await Promise.all(
            processingAssets.map(({ url, name, formData }: ProcessingAsset) => {
              const { file } =
                data.assets!.find(
                  (asset: { name: string }) => asset.name === name
                ) || {};

              if (!file) {
                return undefined;
              }

              // Prepare the form.
              const form = new FormData();
              formData.forEach(({ key, value }) => form.append(key, value));
              form.append("file", file);

              return uploadFile({ url, form });
            })
          );

          // After all assets are uploaded, we set "uploaded: true".
          const {
            data: { results },
          } = await api.kontax.acknowledgeAssetsUploaded(
            processingAssets.map(({ assetId }) => assetId)
          );

          // If some assets fail to upload, we trigger the error toast.
          if (results.some(({ error }: { error: any }) => error)) {
            throw new Error("Some receipt(s) are failed to upload.");
          }
        } catch (e) {
          notification.error({
            message:
              "Fail to upload receipt(s). Please remove and create the business assets again.",
          });
        }
      }

      setIsAdding(false);
      setShowConfirmationModal(true);
      await refetchBusinessAssetsAndSubform();
    },
    [email, refetchBusinessAssetsAndSubform]
  );

  const updateBusinessAsset = useCallback(
    async (data: IExitBusinessAssetPayload | IUpdateBusinessAssetPayload) => {
      setIsSaving(true);
      const updatedBusinessAsset = await api.kontax.updateBusinessAsset(data);

      if (updatedBusinessAsset) {
        await refetchBusinessAssetsAndSubform();
      }

      setIsSaving(false);
    },
    [refetchBusinessAssetsAndSubform]
  );

  const [deleteBusinessAssetHandler] = useDeleteBusinessAssetMutation();

  const deleteBusinessAsset = useCallback(
    async (id: string) => {
      setIsSaving(true);
      await deleteBusinessAssetHandler({
        variables: { id },
      });

      setSelectedBusinessAsset(undefined);
      await refetchBusinessAssetsAndSubform();
      setIsSaving(false);
    },
    [refetchBusinessAssetsAndSubform, deleteBusinessAssetHandler]
  );

  useEffect(() => {
    const formattedAssets = getFormattedBusinessAssets(
      businessAssetsData?.businessAssets || [],
      snapshotYear
    );
    setBusinessAssetsCount(formattedAssets.length);
    setFormattedBusinessAssets(formattedAssets);
    setBusinessAssetIds(formattedAssets.map(({ id }) => id));
    setBusinessAssetSubTexts(
      formattedAssets.reduce(
        (all, { id, purchaseDate, amount }) => ({
          ...all,
          [id]: `Purchase date: ${purchaseDate}, amount: ${amount}`,
        }),
        {}
      )
    );
  }, [setBusinessAssetsCount, businessAssetsData, snapshotYear]);

  const getRowProps = useCallback(
    (row: BusinessAssetRowProps) => {
      const asset: IFormattedBusinessAsset | undefined =
        formattedBusinessAssets.find(({ id }) => id === row.original.id);

      return {
        onClick: () => {
          row.toggleRowExpanded();
        },
        style: {
          color: asset?.exitReason
            ? colors.darkGrey
            : colors.veryDarkGreyishBlue,
        },
      };
    },
    [formattedBusinessAssets]
  );

  const renderRowSubComponent = useCallback(
    ({ row }: { row: BusinessAssetRowProps }) => {
      const businessAsset: IFormattedBusinessAsset | undefined =
        formattedBusinessAssets.find(({ id }) => id === row.original.id);

      if (!businessAsset) {
        return null;
      }

      const {
        assetClass,
        receipts,
        depreciationPeriodYears,
        exitReason,
        exitDate,
        exitAmount,
        bookValueOnExit,
        naturallyDepreciated,
        isExitedWithVat,
      } = businessAsset;

      const priceText = getPriceText({
        exitReason,
        isExitedWithVat,
      });

      const isEditable =
        /* Allow editing when:
          - it hasn't been naturally depreciated
          - it was not exited
          - assets view displays the current year
          Otherwise, only allow to delete the business asset.
        */
        !naturallyDepreciated && !exitReason && snapshotYear === currentYear;
      const isDepreciated =
        naturallyDepreciated && exitReason === ExitReason.DEPRECIATED;
      return (
        <SubComponent>
          <SubHeader>{assetClass} bearbeiten</SubHeader>
          <RowGroup>
            <StyledCol>
              <span onClick={() => setSelectedBusinessAsset(businessAsset)}>
                Beleg{" "}
                <span role="img" aria-label="Receipt">
                  📄
                </span>
              </span>
              <div>{`${receipts?.length} Seite(n)`}</div>
            </StyledCol>
            <InfoBlock
              show={!exitReason && assetClass !== LAND_ASSET_CLASS}
              title="Abschreibungsdauer"
              value={depreciationPeriodYears}
            />
            <InfoBlock
              show={!!exitReason}
              title="Ausscheidungsgrund"
              value={translateExitReason(exitReason as ExitReason)}
            />
            <InfoBlock
              show={!!exitDate}
              title="Ausscheidedatum"
              value={exitDate}
            />
            <InfoBlock
              show={!!exitAmount}
              title={priceText}
              value={exitAmount}
            />
            <InfoBlock
              show={!!bookValueOnExit}
              title="Restbuchwert"
              value={bookValueOnExit}
            />
          </RowGroup>
          <EditBusinessAsset
            user={user}
            businessAsset={businessAsset}
            onUpdate={updateBusinessAsset}
            onDelete={deleteBusinessAsset}
            onCancel={row.toggleRowExpanded}
            isSaving={isSaving}
            isEditable={isEditable}
            isDepreciated={isDepreciated}
            // Refresh Edit form after business asset is updated.
            key={isSaving.toString()}
          />
        </SubComponent>
      );
    },
    [
      formattedBusinessAssets,
      snapshotYear,
      currentYear,
      user,
      updateBusinessAsset,
      deleteBusinessAsset,
      isSaving,
    ]
  );

  if (isLoadingBusinessAssets) {
    return (
      <SpinnerContainer>
        <SpinnerBasic />
      </SpinnerContainer>
    );
  }

  const showReceiptsModal = !!selectedBusinessAsset?.receipts.length;
  const handleYearFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSnapshotYear(parseInt(event.target.value));
  };

  return (
    <Container>
      <Row align="middle">
        <Col flex="1">
          <Header style={{ gap: 15, display: "flex", alignItems: "baseline" }}>
            <span style={{ height: "fit-content" }}>Vermögensgegenstände</span>
            <ActionsContainer
              data-test="VermogensgegenstandeActionsContainer"
              noTopMargin
              style={{ alignItems: "center" }}
            >
              <ActionLogDrawerAsync
                withIcon
                title="Action log"
                modelName="business_asset"
                recordIds={businessAssetIds}
                subTexts={businessAssetSubTexts}
              />
              {subform && (
                <NotesDrawer
                  notes={subform.notes}
                  title="Vermögensgegenstände"
                  type={KontaxNoteType.EUER_DECLARATION_SUBFORM}
                  recordId={subform?.id}
                  email={email}
                />
              )}
            </ActionsContainer>
            {/* Year filter */}
            <div>
              <YearFilterSelect
                id="snapshot-year"
                name="snapshot-year"
                onChange={handleYearFilterChange}
                value={snapshotYear}
              >
                {yearsList.map((year) => (
                  <option value={year} key={year}>
                    {year !== currentYear ? year : `${year} (current)`}
                  </option>
                ))}
              </YearFilterSelect>
            </div>
          </Header>
        </Col>

        {subform ? (
          <Col>
            <DeclarationStatus
              mapping={SUBFORM_STATUS_MAPPINGS}
              status={subform.status}
              statusUpdatedAt={subform.statusUpdatedAt}
              changedBy={subform.lastStatusChange?.changedBy}
              onClick={showStatusChangeModal}
            />
          </Col>
        ) : hasTaxAdvisoryPermissionScope ? (
          <Spin className="mr-5" />
        ) : null}
      </Row>
      <ReactTable
        columns={[
          {
            Header: () => null,
            id: "expander",
            Cell: ({ row }: { row: BusinessAssetRowProps }) => {
              return (
                <span {...row.getToggleRowExpandedProps()} onClick={() => {}}>
                  {row.isExpanded ? "▼" : "►"}
                </span>
              );
            },
          },
          { Header: "Gegenstand", accessor: "assetClass" },
          { Header: "Anschaffungswert (netto)", accessor: "amount" },
          {
            Header: "Anschaffungsdatum",
            accessor: "purchaseDate",
          },
          {
            Header: "Abschreibungsdauer",
            accessor: "depreciationPeriodYears",
            Cell: ({ row }: { row: BusinessAssetRowProps }) => {
              const depreciationPeriodYears =
                row.original.depreciationPeriodYears;

              return depreciationPeriodYears === 0
                ? "sofort"
                : depreciationPeriodYears === 1
                ? `${depreciationPeriodYears} Jahr`
                : `${depreciationPeriodYears} Jahre`;
            },
          },
          {
            Header: "AfA (Absetzung für Abnutzung)",
            accessor: "exitReason",
            Cell: ({ row }: { row: BusinessAssetRowProps }) => {
              if (row.original.exitReason)
                return translateExitReason(
                  row.original.exitReason as ExitReason
                );
              return row.original.assetClass === LAND_ASSET_CLASS
                ? ""
                : row.original.depreciationAmount;
            },
          },
          {
            Header: "Buchwert Ende des Jahres",
            accessor: "endAmount",
          },
        ]}
        data={formattedBusinessAssets}
        getRowProps={getRowProps}
        renderRowSubComponent={renderRowSubComponent}
        isRowClickable
        tableName="businessAssetsTable"
      />
      {showReceiptsModal && (
        <ReceiptsModal
          show={showReceiptsModal}
          asset={selectedBusinessAsset!}
          onHide={() => setSelectedBusinessAsset(undefined)}
          onAssetDelete={() => {
            setSelectedBusinessAsset(undefined);
            refetchBusinessAssets();
          }}
        />
      )}
      {isAdding && (
        <>
          <Header>Neuen Gegenstand anlegen</Header>
          <BusinessAssetCreateForm
            onCreate={createBusinessAsset}
            onCancel={() => setIsAdding(false)}
          />
        </>
      )}
      <BusinessAssetConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      />

      {hasTaxAdvisoryPermissionScope && (
        <Row justify="space-between">
          <Col>
            <Button
              data-test="changeAnlagevermogenStatusButton"
              onClick={showStatusChangeModal}
            >
              Status ändern
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => setIsAdding(true)}
              disabled={isAdding}
              data-test="addAssetButton"
            >
              Hinzufügen
            </Button>
          </Col>
        </Row>
      )}

      {subform && isStatusChangeModalVisible && (
        <BusinessAssetsStatusChangeModal
          year={snapshotYear}
          user={user}
          fields={subform.autoCalculatedValues as EuerDeclarationFields}
          status={subform.status}
          onSave={saveStatus}
          onClose={handleStatusChangeModalClose}
        />
      )}
    </Container>
  );
};

export default BusinessAssetList;
