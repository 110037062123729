import { ExitReason } from "../api/graphql/schema.generated";
import { Asset } from "./transactions";

export enum BusinessAssetType {
  // Bewegliche - Kraftfahrzeuge
  MOVABLE_MOTOR_VEHICLES = "movable-motor-vehicles",
  // Bewegliche - Büroausstattung
  MOVABLE_OFFICE_EQUIPMENT = "movable-office-equipment",
  // Bewegliche - Andere
  MOVABLE_OTHERS = "movable-others",
  // Unbewegliche
  IMMOVABLE = "immovable",
  // Immaterielle
  INTANGIBLE = "intangible",
}

export enum DepreciableCategory {
  OVER_250 = "1320",
  OVER_800 = "1370",
}

export enum BEExitReason {
  SOLD = "sold",
  LOST = "lost",
  PRIVATE_USE = "private use",
  DEPRECIATED = "depreciated",
}

export interface IDepreciation {
  year: number;
  startAmount: number;
  depreciationAmount: number;
  depreciationMonths: number;
}

export interface IMetaData {
  amount: number;
  transactionName: string;
  transactionValutaDate: Date;
  transactionDescription: string;
}

// The business asset fetched from backend.
export interface IBusinessAsset {
  id: string;
  assetClass: string;
  assetType: keyof typeof BusinessAssetType;
  amount: number;
  purchaseDate: string;
  depreciationPeriodYears: number;
  receipts: Asset[];
  depreciations: IDepreciation[];
  naturallyDepreciated: boolean;
  categoryCode: string;
  endAmount?: number;
  exitReason?: ExitReason;
  isExitedWithVat?: boolean;
  exitDate?: string;
  exitAmount?: number;
  bookValueOnExit?: number;
  metaData?: IMetaData;
}

// After being fetched from backend, business asset is formatted to display in UI.
export interface IFormattedBusinessAsset
  extends Omit<
    IBusinessAsset,
    | "amount"
    | "assetType"
    | "endAmount"
    | "exitReason"
    | "exitAmount"
    | "bookValueOnExit"
  > {
  amount: string;
  assetType: BusinessAssetType;
  endAmount?: string;
  exitReason?: ExitReason;
  exitAmount?: string;
  bookValueOnExit?: string;
  depreciationAmount?: string;
  note?: string;
}

export enum BusinessAssetFormField {
  ASSET_TYPE = "assetType",
  ASSET_CLASS = "assetClass",
  ASSET_CLASS_CUSTOM = "assetClassCustom",
  PURCHASE_DATE = "purchaseDate",
  DEPRECIATION_YEARS = "depreciationYears",
}

// Type use in creating/updating business asset.
export interface IBusinessAssetFormData {
  assetType: BusinessAssetType;
  assetClass: string;
  assetClassCustom: string;
  purchaseDate?: string;
  purchaseDateMax?: string;
  depreciationYears: string;
  depreciationYearsEditable: boolean;
}

export interface IBusinessAssetForm {
  businessAssetFormData: IBusinessAssetFormData;
  onFieldChange: (field: BusinessAssetFormField, value: string) => void;
}

// Assets that are ready to be uploaded.
export interface ILocalAsset {
  name: string;
  filetype: string;
  file: File;
}

/**
 * Payloads
 */

export interface ICreateBusinessAssetPayload {
  amount: number;
  categoryCode: string;
  assetType: BusinessAssetType;
  assetClass: string;
  purchaseDate: string;
  depreciationPeriodYears: number | null;
  note?: string;
  assets?: ILocalAsset[];
}

export interface ICreateBusinessAssetFromTransactionPayload
  extends Omit<ICreateBusinessAssetPayload, "amount" | "note"> {}

export interface ICreateSplitBusinessAssetPayload {
  assetType?: BusinessAssetType;
  assetClass?: string;
  purchaseDate?: string;
  depreciationPeriodYears: number;
}

export interface IExitBusinessAssetPayload {
  id: string;
  exitReason: ExitReason;
  exitDate: string;
  exitAmount?: number;
}

export interface IUpdateBusinessAssetPayload {
  id: string;
  categoryCode: string;
  assetType: BusinessAssetType;
  assetClass: string;
  depreciationPeriodYears: number;
}
