import React from "react";
import { Button } from "antd";
import { ForkOutlined } from "@ant-design/icons";

import colors from "../../themes/colors";

const TransactionSplitButton = ({
  onClick,
  small = false,
}: {
  onClick: () => void;
  small?: boolean;
}) => {
  const text = small ? "Split" : "Split transaction";
  const styles = {
    display: "flex",
    alignItems: "center",
    ...(small ? { border: "none" } : {}),
  };
  return (
    <Button
      onClick={onClick}
      icon={<ForkOutlined style={{ color: colors.purple }} />}
      size="large"
      style={styles}
      data-test="splitButton"
    >
      {text}
    </Button>
  );
};

export default TransactionSplitButton;
