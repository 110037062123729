import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { ITransaction } from "../../../../types/transactions";

export interface KontaxTransactionUpsertInput {
  kontistTransactionId: string;
  categoryCode?: string;
  vatCategoryCode?: string;
  escalated?: boolean;
  invoiceRequestedAt?: Date;
  categoryId?: number;
  escalationNote?: string;
  internalNote?: string;
  verified?: boolean;
}

export interface UpsertTransactionsMutationVariables {
  transactions: KontaxTransactionUpsertInput[];
}

export interface UpsertTransactionsMutationResult {
  upsertKontaxTransactions: { transactions: ITransaction[] };
}

export const UPSERT_KONTAX_TRANSACTIONS_MUTATION = gql`
  mutation upsertKontaxTransactions(
    $transactions: [KontaxTransactionUpsertInput!]!
  ) {
    upsertKontaxTransactions(transactions: $transactions) {
      transactions {
        id
        amount
        valutaDate
        verified
        name
        iban
        type
        source
        description
        merchantCategoryCode
        foreignCurrency
        escalated
        escalationNote
        internalNote
        businessTypeComment
        personalNote
        invoiceRequestedAt
        email
        firstName
        lastName
        categoryCode
        categoryCodeMeta {
          label
          description
          categorizationType
          suggestionSource
        }
        vatCategoryCode
        vatCategoryCodeMeta {
          label
          description
          categorizationType
          suggestionSource
        }
        assets {
          id
          fullsize
          filetype
        }
        hasIntegrationDocument
        splits {
          categoryCode
          id
          uuid
          amount
          category
          userSelectedBookingDate
          categorizationType
        }
        isSplitCategorized
        vatYearPaymentFrequency
        businessAssets {
          businessAssetableId
          businessAssetableType
          assetClass
          purchaseDate
          amount
        }
        source
      }
    }
  }
`;

export const useUpsertTransactionsMutation = (
  options?: MutationHookOptions<
    UpsertTransactionsMutationResult,
    UpsertTransactionsMutationVariables
  >
) =>
  useMutation<
    UpsertTransactionsMutationResult,
    UpsertTransactionsMutationVariables
  >(UPSERT_KONTAX_TRANSACTIONS_MUTATION, options);
