import { gql, MutationHookOptions, useMutation } from "@apollo/client";

import { ITransaction } from "../../../../types/transactions";
import { BusinessAssetForm } from "../../schema.generated";

export interface UpsertExternalTransactionInput {
  id?: string;
  amount: number;
  paymentDate: Date;
  categoryCode: string;
  vatCategoryCode: string;
  name?: string;
  iban?: string;
  description?: string;
  businessAssetForm?: BusinessAssetForm;
}

export interface UpsertExternalTransactionOptions {
  emailDocumentId?: string;
}

export interface UpsertExternalTransactionMutationVariables {
  email: string;
  transaction: UpsertExternalTransactionInput;
  options?: UpsertExternalTransactionOptions;
}

export interface UpsertExternalTransactionMutationResult {
  upsertExternalTransaction: ITransaction;
}

export const UPSERT_EXTERNAL_TRANSACTION_MUTATION = gql`
  mutation (
    $email: String!
    $transaction: ExternalTransactionInput!
    $options: UpsertExternalTransactionOptions
  ) {
    upsertExternalTransaction(
      email: $email
      transaction: $transaction
      options: $options
    ) {
      id
      amount
      valutaDate
      verified
      name
      iban
      description
      merchantCategoryCode
      foreignCurrency
      escalated
      escalationNote
      internalNote
      businessTypeComment
      personalNote
      invoiceRequestedAt
      email
      firstName
      lastName
      categoryCode
      categoryCodeMeta {
        label
        description
        categorizationType
        suggestionSource
      }
      vatCategoryCode
      vatCategoryCodeMeta {
        label
        description
        categorizationType
        suggestionSource
      }

      assets {
        id
        fullsize
        filetype
      }
      hasIntegrationDocument
      splits {
        categoryCode
        id
        uuid
        amount
        category
        userSelectedBookingDate
        categorizationType
      }
      isSplitCategorized
      vatYearPaymentFrequency
      businessAssets {
        businessAssetableId
        businessAssetableType
        assetClass
        purchaseDate
        amount
      }
      source
    }
  }
`;

export const useUpsertExternalTransactionMutation = (
  options?: MutationHookOptions<
    UpsertExternalTransactionMutationResult,
    UpsertExternalTransactionMutationVariables
  >
) =>
  useMutation<
    UpsertExternalTransactionMutationResult,
    UpsertExternalTransactionMutationVariables
  >(UPSERT_EXTERNAL_TRANSACTION_MUTATION, options);
