import { isNull } from "lodash";

import {
  QuestionnaireAnswer,
  QuestionnaireAnswerDocumentsStatus,
  QuestionnaireInputConfig,
  QuestionnaireInputConfigForm,
  QuestionnaireInputType,
  QuestionnaireQuestion,
} from "../../../../../../api/graphql";
import { formatDate } from "../../../utils";
import { TaxPreparationAnswer } from "./constants";
import {
  QuestionnaireAnswerByQuestionName,
  QuestionnaireAnswersText,
} from "./EndOfTheYearQuestionnaire/constants";
import {
  QuestionnaireInfo,
  QUESTIONNAIRES_INFO,
} from "./TaxPreparationOverview/constants";
import { currencyFormatter } from "../../../../../../utils";

type GetListValueParams = {
  answer: QuestionnaireAnswer;
  answers: QuestionnaireAnswersText;
  questionName: string;
  isMultiple?: boolean;
};

type ListOptionValue =
  | string
  | number
  | { value: string | number; note: string };

export const isAnswerSubmitted = (answer: QuestionnaireAnswer) => {
  return !!answer?.submittedAt;
};
export const isAnswerPostponed = (answer: QuestionnaireAnswer) => {
  return !!answer?.postponedAt;
};
export const getQuestionnaireInfo = (
  questionnaireType: any
): QuestionnaireInfo | undefined => {
  return QUESTIONNAIRES_INFO.find((info) => info.type === questionnaireType);
};

const getBooleanValue = (answer: QuestionnaireAnswer) => {
  return answer.value ? TaxPreparationAnswer.YES : TaxPreparationAnswer.NO;
};

const getListOptionName = (
  value: string | number,
  answers: QuestionnaireAnswersText,
  questionName: string
) => {
  switch (typeof answers[value]) {
    case "object":
      return (answers[value] as QuestionnaireAnswerByQuestionName)[
        questionName
      ];
    case "string":
      return answers[value];
    default:
      return value;
  }
};

const getListOption = (
  value: ListOptionValue,
  answers: QuestionnaireAnswersText,
  questionName: string
) => {
  if (typeof value === "object") {
    return `${getListOptionName(value.value, answers, questionName)} [Kunde: ${
      value.note
    }]`;
  }

  return getListOptionName(value, answers, questionName);
};

const getListValue = ({
  answer,
  answers,
  isMultiple,
  questionName,
}: GetListValueParams) => {
  if (isMultiple) {
    if (answer.value.length === 0) return "-";
    return answer.value
      .map((item: ListOptionValue) =>
        getListOption(item, answers, questionName)
      )
      .join(", ");
  }
  return getListOption(answer.value, answers, questionName);
};

const getDropDownValue = (
  answer: QuestionnaireAnswer,
  answers: QuestionnaireAnswersText,
  questionName: string
) => {
  switch (typeof answers[answer.value]) {
    case "object":
      return (answers[answer.value] as QuestionnaireAnswerByQuestionName)[
        questionName
      ];
    case "string":
      return answers[answer.value];
    default:
      return answer.value;
  }
};

const getAddressValue = (answer: QuestionnaireAnswer) => {
  const { street, postCode, city } = answer.value;
  return [street, postCode, city].join(", ");
};

const getCurrencyValue = (answer: QuestionnaireAnswer) =>
  currencyFormatter.format(answer.value);

export const getFormattedAnswerValue = (
  inputConfig: QuestionnaireInputConfig | undefined,
  answer: QuestionnaireAnswer,
  answers: QuestionnaireAnswersText,
  question: QuestionnaireQuestion
) => {
  switch (inputConfig?.type) {
    case QuestionnaireInputType.BOOLEAN: {
      return getBooleanValue(answer);
    }
    case QuestionnaireInputType.LIST: {
      return getListValue({
        answer,
        answers,
        isMultiple: inputConfig.multiple,
        questionName: question.name,
      });
    }
    case QuestionnaireInputType.DROPDOWN: {
      return getDropDownValue(answer, answers, question.name);
    }
    case QuestionnaireInputType.DATE: {
      return formatDate(answer.value);
    }
    case QuestionnaireInputType.ADDRESS: {
      return getAddressValue(answer);
    }
    case QuestionnaireInputType.CURRENCY: {
      return getCurrencyValue(answer);
    }
    default: {
      return answer.value;
    }
  }
};

const getFormDataValues = (
  inputConfig: QuestionnaireInputConfigForm,
  answer: QuestionnaireAnswer,
  answers: QuestionnaireAnswersText,
  question: QuestionnaireQuestion
) => {
  const answerValues = Array.isArray(answer.value)
    ? answer.value
    : [answer.value];

  return answerValues
    .flatMap((record) =>
      inputConfig.inputs
        .map(({ name, value: config }) =>
          name !== "id"
            ? getFormattedAnswerValue(
                config,
                {
                  value: record[name],
                  documentsStatus:
                    QuestionnaireAnswerDocumentsStatus.NOT_REQUIRED,
                },
                answers,
                question
              )
            : null
        )
        .filter((value) => !!value)
    )
    .join(", ");
};

export const getQuestionnaireAnswerValue = (
  question: QuestionnaireQuestion,
  answers: QuestionnaireAnswersText
) => {
  const { answer, inputConfig } = question;
  if (answer) {
    if (isAnswerSubmitted(answer)) {
      if (!isNull(answer.value)) {
        if (inputConfig?.type === QuestionnaireInputType.FORM) {
          return getFormDataValues(inputConfig, answer, answers, question);
        } else {
          return getFormattedAnswerValue(
            inputConfig,
            answer,
            answers,
            question
          );
        }
      } else {
        return TaxPreparationAnswer.NEXT;
      }
    } else if (isAnswerPostponed(answer)) {
      return TaxPreparationAnswer.ANSWER_LATER;
    }
  }
  return TaxPreparationAnswer.EMPTY;
};

export const getQuestionnaireUpdatedAtValue = (answer: QuestionnaireAnswer) => {
  if (isAnswerSubmitted(answer)) {
    return formatDate(answer.submittedAt);
  } else if (isAnswerPostponed(answer)) {
    return formatDate(answer.postponedAt);
  } else {
    return "-";
  }
};

export const getSupportedQuestionnairesInfoForYear = (taxYear: number) =>
  QUESTIONNAIRES_INFO.filter(
    ({ firstSupportedYear, lastSupportedYear }) =>
      (!firstSupportedYear || taxYear >= firstSupportedYear) &&
      (!lastSupportedYear || taxYear <= lastSupportedYear)
  );
